import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  CaseStudyContentDynamicZoneInput: { input: any; output: any };
  CookiePolicyCookiePolicyDynamicZoneInput: { input: any; output: any };
  DateTime: { input: any; output: any };
  JSON: { input: any; output: any };
  LegalNoticeLegalNoticeDynamicZoneInput: { input: any; output: any };
  MlBattProtEuProjectContentsDynamicZoneInput: { input: any; output: any };
  PrivacyPolicyPoliciesDynamicZoneInput: { input: any; output: any };
  SupplierPortalPoliciesDynamicZoneInput: { input: any; output: any };
  Upload: { input: any; output: any };
  WhistleblowingPolicyPoliciesDynamicZoneInput: { input: any; output: any };
};

export type Battery = {
  __typename?: 'Battery';
  cardsSection: ComponentSharedSectionCards;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heroVideoSection: ComponentUiHeroVideoSection;
  partnersBlogSection?: Maybe<ComponentSharedPartnersBlogSection>;
  productImageBlock?: Maybe<ComponentSharedProductImageBlock>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  twoImagesGrid?: Maybe<ComponentSharedTwoImagesGrid>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  videoSection?: Maybe<ComponentSharedVideoSection>;
};

export type BatteryEntity = {
  __typename?: 'BatteryEntity';
  attributes?: Maybe<Battery>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type BatteryEntityResponse = {
  __typename?: 'BatteryEntityResponse';
  data?: Maybe<BatteryEntity>;
};

export type BatteryInput = {
  cardsSection?: InputMaybe<ComponentSharedSectionCardsInput>;
  heroVideoSection?: InputMaybe<ComponentUiHeroVideoSectionInput>;
  partnersBlogSection?: InputMaybe<ComponentSharedPartnersBlogSectionInput>;
  productImageBlock?: InputMaybe<ComponentSharedProductImageBlockInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  twoImagesGrid?: InputMaybe<ComponentSharedTwoImagesGridInput>;
  videoSection?: InputMaybe<ComponentSharedVideoSectionInput>;
};

export type Bms = {
  __typename?: 'Bms';
  cardsSection?: Maybe<ComponentSharedSectionCards>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heroVideoSection?: Maybe<ComponentUiHeroVideoSection>;
  largeImageSection?: Maybe<ComponentSharedLargeImageSection>;
  partnersBlogSection?: Maybe<ComponentSharedPartnersBlogSection>;
  productImageBlock?: Maybe<ComponentSharedProductImageBlock>;
  productSpecsBlock?: Maybe<ComponentSharedProductSpecsBlocks>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  twoImagesGrid?: Maybe<Array<Maybe<ComponentSharedTwoImagesGrid>>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BmsTwoImagesGridArgs = {
  filters?: InputMaybe<ComponentSharedTwoImagesGridFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type BmsEntity = {
  __typename?: 'BmsEntity';
  attributes?: Maybe<Bms>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type BmsEntityResponse = {
  __typename?: 'BmsEntityResponse';
  data?: Maybe<BmsEntity>;
};

export type BmsInput = {
  cardsSection?: InputMaybe<ComponentSharedSectionCardsInput>;
  heroVideoSection?: InputMaybe<ComponentUiHeroVideoSectionInput>;
  largeImageSection?: InputMaybe<ComponentSharedLargeImageSectionInput>;
  partnersBlogSection?: InputMaybe<ComponentSharedPartnersBlogSectionInput>;
  productImageBlock?: InputMaybe<ComponentSharedProductImageBlockInput>;
  productSpecsBlock?: InputMaybe<ComponentSharedProductSpecsBlocksInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  twoImagesGrid?: InputMaybe<
    Array<InputMaybe<ComponentSharedTwoImagesGridInput>>
  >;
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  contains?: InputMaybe<Scalars['Boolean']['input']>;
  containsi?: InputMaybe<Scalars['Boolean']['input']>;
  endsWith?: InputMaybe<Scalars['Boolean']['input']>;
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  eqi?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Boolean']['input']>;
  gte?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  lt?: InputMaybe<Scalars['Boolean']['input']>;
  lte?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
  nei?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<BooleanFilterInput>;
  notContains?: InputMaybe<Scalars['Boolean']['input']>;
  notContainsi?: InputMaybe<Scalars['Boolean']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  startsWith?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CaseStudy = {
  __typename?: 'CaseStudy';
  content?: Maybe<Array<Maybe<CaseStudyContentDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  hero?: Maybe<ComponentUiHeroImageSection>;
  partnersBlogSection?: Maybe<ComponentSharedPartnersBlogSection>;
  preview?: Maybe<ComponentCaseStudyArticlePreview>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CaseStudyContentDynamicZone =
  | ComponentCaseStudyGallery
  | ComponentCaseStudyTextContent
  | Error;

export type CaseStudyEntity = {
  __typename?: 'CaseStudyEntity';
  attributes?: Maybe<CaseStudy>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CaseStudyEntityResponse = {
  __typename?: 'CaseStudyEntityResponse';
  data?: Maybe<CaseStudyEntity>;
};

export type CaseStudyEntityResponseCollection = {
  __typename?: 'CaseStudyEntityResponseCollection';
  data: Array<CaseStudyEntity>;
  meta: ResponseCollectionMeta;
};

export type CaseStudyFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CaseStudyFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  hero?: InputMaybe<ComponentUiHeroImageSectionFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<CaseStudyFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CaseStudyFiltersInput>>>;
  partnersBlogSection?: InputMaybe<ComponentSharedPartnersBlogSectionFiltersInput>;
  preview?: InputMaybe<ComponentCaseStudyArticlePreviewFiltersInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  seo?: InputMaybe<ComponentSharedSeoFiltersInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CaseStudyInput = {
  content?: InputMaybe<
    Array<Scalars['CaseStudyContentDynamicZoneInput']['input']>
  >;
  hero?: InputMaybe<ComponentUiHeroImageSectionInput>;
  partnersBlogSection?: InputMaybe<ComponentSharedPartnersBlogSectionInput>;
  preview?: InputMaybe<ComponentCaseStudyArticlePreviewInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type CaseStudyRelationResponseCollection = {
  __typename?: 'CaseStudyRelationResponseCollection';
  data: Array<CaseStudyEntity>;
};

export type Company = {
  __typename?: 'Company';
  Campus?: Maybe<Array<Maybe<ComponentUiCampus>>>;
  CampusAnchor?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heroVideoSection?: Maybe<ComponentUiHeroVideoSection>;
  largeImageSection?: Maybe<ComponentSharedLargeImageSection>;
  mapSection?: Maybe<ComponentSharedMapSection>;
  newsroom?: Maybe<ComponentSharedNewsroom>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  sliderImageSection?: Maybe<ComponentSharedSliderImageSection>;
  slug?: Maybe<Scalars['String']['output']>;
  teamCards?: Maybe<ComponentSharedTeamCardsSection>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  values?: Maybe<ComponentSharedValues>;
};

export type CompanyCampusArgs = {
  filters?: InputMaybe<ComponentUiCampusFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyEntity = {
  __typename?: 'CompanyEntity';
  attributes?: Maybe<Company>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CompanyEntityResponse = {
  __typename?: 'CompanyEntityResponse';
  data?: Maybe<CompanyEntity>;
};

export type CompanyInput = {
  Campus?: InputMaybe<Array<InputMaybe<ComponentUiCampusInput>>>;
  CampusAnchor?: InputMaybe<Scalars['String']['input']>;
  heroVideoSection?: InputMaybe<ComponentUiHeroVideoSectionInput>;
  largeImageSection?: InputMaybe<ComponentSharedLargeImageSectionInput>;
  mapSection?: InputMaybe<ComponentSharedMapSectionInput>;
  newsroom?: InputMaybe<ComponentSharedNewsroomInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  sliderImageSection?: InputMaybe<ComponentSharedSliderImageSectionInput>;
  slug?: InputMaybe<Scalars['String']['input']>;
  teamCards?: InputMaybe<ComponentSharedTeamCardsSectionInput>;
  values?: InputMaybe<ComponentSharedValuesInput>;
};

export type ComponentCaseStudyArticleHero = {
  __typename?: 'ComponentCaseStudyArticleHero';
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentCaseStudyArticlePreview = {
  __typename?: 'ComponentCaseStudyArticlePreview';
  alt: Scalars['String']['output'];
  date?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  img_desktop: UploadFileEntityResponse;
  img_mobile?: Maybe<UploadFileEntityResponse>;
  tag?: Maybe<Scalars['String']['output']>;
  tagColor?: Maybe<Enum_Componentcasestudyarticlepreview_Tagcolor>;
  title: Scalars['String']['output'];
};

export type ComponentCaseStudyArticlePreviewFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<
    Array<InputMaybe<ComponentCaseStudyArticlePreviewFiltersInput>>
  >;
  date?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentCaseStudyArticlePreviewFiltersInput>;
  or?: InputMaybe<
    Array<InputMaybe<ComponentCaseStudyArticlePreviewFiltersInput>>
  >;
  tag?: InputMaybe<StringFilterInput>;
  tagColor?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentCaseStudyArticlePreviewInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  img_desktop?: InputMaybe<Scalars['ID']['input']>;
  img_mobile?: InputMaybe<Scalars['ID']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  tagColor?: InputMaybe<Enum_Componentcasestudyarticlepreview_Tagcolor>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentCaseStudyGallery = {
  __typename?: 'ComponentCaseStudyGallery';
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<ComponentUiImage>>>;
};

export type ComponentCaseStudyGalleryImagesArgs = {
  filters?: InputMaybe<ComponentUiImageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentCaseStudyTextContent = {
  __typename?: 'ComponentCaseStudyTextContent';
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['JSON']['output']>;
};

export type ComponentFooterColumn = {
  __typename?: 'ComponentFooterColumn';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Array<Maybe<ComponentFooterLink>>>;
};

export type ComponentFooterColumnLinkArgs = {
  filters?: InputMaybe<ComponentFooterLinkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentFooterColumnFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentFooterColumnFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  link?: InputMaybe<ComponentFooterLinkFiltersInput>;
  not?: InputMaybe<ComponentFooterColumnFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentFooterColumnFiltersInput>>>;
};

export type ComponentFooterColumnInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Array<InputMaybe<ComponentFooterLinkInput>>>;
};

export type ComponentFooterContact = {
  __typename?: 'ComponentFooterContact';
  address?: Maybe<ComponentFooterLink>;
  email?: Maybe<ComponentFooterLink>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentFooterContactInput = {
  address?: InputMaybe<ComponentFooterLinkInput>;
  email?: InputMaybe<ComponentFooterLinkInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentFooterLink = {
  __typename?: 'ComponentFooterLink';
  file?: Maybe<UploadFileEntityResponse>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  openInNewTab?: Maybe<Scalars['Boolean']['output']>;
  path?: Maybe<Scalars['String']['output']>;
};

export type ComponentFooterLinkFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentFooterLinkFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentFooterLinkFiltersInput>;
  openInNewTab?: InputMaybe<BooleanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentFooterLinkFiltersInput>>>;
  path?: InputMaybe<StringFilterInput>;
};

export type ComponentFooterLinkInput = {
  file?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentFooterPolicies = {
  __typename?: 'ComponentFooterPolicies';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Array<Maybe<ComponentFooterLink>>>;
};

export type ComponentFooterPoliciesLinkArgs = {
  filters?: InputMaybe<ComponentFooterLinkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentFooterPoliciesInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Array<InputMaybe<ComponentFooterLinkInput>>>;
};

export type ComponentFooterSocials = {
  __typename?: 'ComponentFooterSocials';
  id: Scalars['ID']['output'];
  instagram?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  linkedin?: Maybe<Scalars['String']['output']>;
  tiktok?: Maybe<Scalars['String']['output']>;
  youtube?: Maybe<Scalars['String']['output']>;
};

export type ComponentFooterSocialsInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  linkedin?: InputMaybe<Scalars['String']['input']>;
  tiktok?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentHomepageImageCardsWIthLink = {
  __typename?: 'ComponentHomepageImageCardsWIthLink';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageCardLink?: Maybe<Array<Maybe<ComponentUiImageCardLink>>>;
};

export type ComponentHomepageImageCardsWIthLinkImageCardLinkArgs = {
  filters?: InputMaybe<ComponentUiImageCardLinkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentHomepageImageCardsWIthLinkInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageCardLink?: InputMaybe<Array<InputMaybe<ComponentUiImageCardLinkInput>>>;
};

export type ComponentNavigationContactInfo = {
  __typename?: 'ComponentNavigationContactInfo';
  address?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type ComponentNavigationContactInfoInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentNavigationLink = {
  __typename?: 'ComponentNavigationLink';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  openInNewTab?: Maybe<Scalars['Boolean']['output']>;
  path: Scalars['String']['output'];
};

export type ComponentNavigationLinkFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentNavigationLinkFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentNavigationLinkFiltersInput>;
  openInNewTab?: InputMaybe<BooleanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentNavigationLinkFiltersInput>>>;
  path?: InputMaybe<StringFilterInput>;
};

export type ComponentNavigationLinkInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentNavigationOpenMenuColumn = {
  __typename?: 'ComponentNavigationOpenMenuColumn';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Array<Maybe<ComponentUiButton>>>;
  socials?: Maybe<ComponentNavigationSocials>;
};

export type ComponentNavigationOpenMenuColumnLinkArgs = {
  filters?: InputMaybe<ComponentUiButtonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentNavigationOpenMenuColumnFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentNavigationOpenMenuColumnFiltersInput>>
  >;
  label?: InputMaybe<StringFilterInput>;
  link?: InputMaybe<ComponentUiButtonFiltersInput>;
  not?: InputMaybe<ComponentNavigationOpenMenuColumnFiltersInput>;
  or?: InputMaybe<
    Array<InputMaybe<ComponentNavigationOpenMenuColumnFiltersInput>>
  >;
  socials?: InputMaybe<ComponentNavigationSocialsFiltersInput>;
};

export type ComponentNavigationOpenMenuColumnInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Array<InputMaybe<ComponentUiButtonInput>>>;
  socials?: InputMaybe<ComponentNavigationSocialsInput>;
};

export type ComponentNavigationSocials = {
  __typename?: 'ComponentNavigationSocials';
  id: Scalars['ID']['output'];
  instagram?: Maybe<Scalars['String']['output']>;
  linkedIn?: Maybe<Scalars['String']['output']>;
  tiktok?: Maybe<Scalars['String']['output']>;
  youtube?: Maybe<Scalars['String']['output']>;
};

export type ComponentNavigationSocialsFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentNavigationSocialsFiltersInput>>>;
  instagram?: InputMaybe<StringFilterInput>;
  linkedIn?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentNavigationSocialsFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentNavigationSocialsFiltersInput>>>;
  tiktok?: InputMaybe<StringFilterInput>;
  youtube?: InputMaybe<StringFilterInput>;
};

export type ComponentNavigationSocialsInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  linkedIn?: InputMaybe<Scalars['String']['input']>;
  tiktok?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentPoliciesBullets = {
  __typename?: 'ComponentPoliciesBullets';
  bullet?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isIndented: Scalars['Boolean']['output'];
};

export type ComponentPoliciesDevider = {
  __typename?: 'ComponentPoliciesDevider';
  Devider?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
};

export type ComponentPoliciesDownloadLink = {
  __typename?: 'ComponentPoliciesDownloadLink';
  file?: Maybe<UploadFileEntityResponse>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
};

export type ComponentPoliciesMainHeading = {
  __typename?: 'ComponentPoliciesMainHeading';
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['JSON']['output']>;
};

export type ComponentPoliciesParagpraph = {
  __typename?: 'ComponentPoliciesParagpraph';
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
};

export type ComponentPoliciesRowItem = {
  __typename?: 'ComponentPoliciesRowItem';
  id: Scalars['ID']['output'];
  item?: Maybe<Scalars['String']['output']>;
};

export type ComponentPoliciesRowItemFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentPoliciesRowItemFiltersInput>>>;
  item?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentPoliciesRowItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentPoliciesRowItemFiltersInput>>>;
};

export type ComponentPoliciesRowItemInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  item?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentPoliciesTableHeading = {
  __typename?: 'ComponentPoliciesTableHeading';
  headingItem?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type ComponentPoliciesTableHeadingFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentPoliciesTableHeadingFiltersInput>>
  >;
  headingItem?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentPoliciesTableHeadingFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentPoliciesTableHeadingFiltersInput>>>;
};

export type ComponentPoliciesTableHeadingInput = {
  headingItem?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentPoliciesTableRow = {
  __typename?: 'ComponentPoliciesTableRow';
  id: Scalars['ID']['output'];
  table_rows?: Maybe<TableRowRelationResponseCollection>;
};

export type ComponentPoliciesTableRowTable_RowsArgs = {
  filters?: InputMaybe<TableRowFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentPoliciesTableRowInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  table_rows?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type ComponentPoliciesTableTextContent = {
  __typename?: 'ComponentPoliciesTableTextContent';
  id: Scalars['ID']['output'];
  richText?: Maybe<Scalars['JSON']['output']>;
  tableHead?: Maybe<Array<Maybe<ComponentPoliciesTableHeading>>>;
  tableRow?: Maybe<ComponentPoliciesTableRow>;
};

export type ComponentPoliciesTableTextContentTableHeadArgs = {
  filters?: InputMaybe<ComponentPoliciesTableHeadingFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentPoliciesTableTextContentInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  richText?: InputMaybe<Scalars['JSON']['input']>;
  tableHead?: InputMaybe<Array<InputMaybe<ComponentPoliciesTableHeadingInput>>>;
  tableRow?: InputMaybe<ComponentPoliciesTableRowInput>;
};

export type ComponentPoliciesTitleTextContent = {
  __typename?: 'ComponentPoliciesTitleTextContent';
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['JSON']['output']>;
};

export type ComponentPowertrainTechnologies = {
  __typename?: 'ComponentPowertrainTechnologies';
  anchor?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  paragraphs?: Maybe<Array<Maybe<ComponentUiParagraph>>>;
  specTables?: Maybe<SpecTableRelationResponseCollection>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentPowertrainTechnologiesParagraphsArgs = {
  filters?: InputMaybe<ComponentUiParagraphFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentPowertrainTechnologiesSpecTablesArgs = {
  filters?: InputMaybe<SpecTableFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentPowertrainTechnologiesInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<ComponentUiImageInput>;
  paragraphs?: InputMaybe<Array<InputMaybe<ComponentUiParagraphInput>>>;
  specTables?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedDenseSpec = {
  __typename?: 'ComponentSharedDenseSpec';
  gapBellow?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedDenseSpecFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedDenseSpecFiltersInput>>>;
  gapBellow?: InputMaybe<BooleanFilterInput>;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentSharedDenseSpecFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedDenseSpecFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
  value?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedDenseSpecInput = {
  gapBellow?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedLargeImageSection = {
  __typename?: 'ComponentSharedLargeImageSection';
  anchor?: Maybe<Scalars['String']['output']>;
  button?: Maybe<ComponentUiButton>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedLargeImageSectionInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  button?: InputMaybe<ComponentUiButtonInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<ComponentUiImageInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedMapSection = {
  __typename?: 'ComponentSharedMapSection';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  leftMapImage: UploadFileEntityResponse;
  leftMapImageMobile?: Maybe<UploadFileEntityResponse>;
  leftMapTitle: Scalars['String']['output'];
  rightMapImage: UploadFileEntityResponse;
  rightMapImageMobile: UploadFileEntityResponse;
  rightMapTitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ComponentSharedMapSectionInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  leftMapImage?: InputMaybe<Scalars['ID']['input']>;
  leftMapImageMobile?: InputMaybe<Scalars['ID']['input']>;
  leftMapTitle?: InputMaybe<Scalars['String']['input']>;
  rightMapImage?: InputMaybe<Scalars['ID']['input']>;
  rightMapImageMobile?: InputMaybe<Scalars['ID']['input']>;
  rightMapTitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedMetaSocial = {
  __typename?: 'ComponentSharedMetaSocial';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
  title: Scalars['String']['output'];
};

export type ComponentSharedMetaSocialFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialFiltersInput>>>;
  socialNetwork?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedMetaSocialInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  socialNetwork?: InputMaybe<Enum_Componentsharedmetasocial_Socialnetwork>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedNewsroom = {
  __typename?: 'ComponentSharedNewsroom';
  anchor?: Maybe<Scalars['String']['output']>;
  button?: Maybe<ComponentUiButton>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedNewsroomInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  button?: InputMaybe<ComponentUiButtonInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedPartnersBlogSection = {
  __typename?: 'ComponentSharedPartnersBlogSection';
  anchor?: Maybe<Scalars['String']['output']>;
  button?: Maybe<ComponentUiButton>;
  caseStudies?: Maybe<CaseStudyRelationResponseCollection>;
  id: Scalars['ID']['output'];
  logos?: Maybe<Array<Maybe<ComponentUiIcon>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedPartnersBlogSectionCaseStudiesArgs = {
  filters?: InputMaybe<CaseStudyFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedPartnersBlogSectionLogosArgs = {
  filters?: InputMaybe<ComponentUiIconFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedPartnersBlogSectionFiltersInput = {
  anchor?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<
    Array<InputMaybe<ComponentSharedPartnersBlogSectionFiltersInput>>
  >;
  button?: InputMaybe<ComponentUiButtonFiltersInput>;
  caseStudies?: InputMaybe<CaseStudyFiltersInput>;
  logos?: InputMaybe<ComponentUiIconFiltersInput>;
  not?: InputMaybe<ComponentSharedPartnersBlogSectionFiltersInput>;
  or?: InputMaybe<
    Array<InputMaybe<ComponentSharedPartnersBlogSectionFiltersInput>>
  >;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedPartnersBlogSectionInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  button?: InputMaybe<ComponentUiButtonInput>;
  caseStudies?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  logos?: InputMaybe<Array<InputMaybe<ComponentUiIconInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedProductImageBlock = {
  __typename?: 'ComponentSharedProductImageBlock';
  anchor?: Maybe<Scalars['String']['output']>;
  button?: Maybe<ComponentUiButton>;
  denseSpecs?: Maybe<Array<Maybe<ComponentSharedDenseSpec>>>;
  gallery?: Maybe<Array<Maybe<ComponentUiImage>>>;
  galleryExtraText?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  largeSpec?: Maybe<Array<Maybe<ComponentUiSpec>>>;
  specTable?: Maybe<SpecTableEntityResponse>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedProductImageBlockDenseSpecsArgs = {
  filters?: InputMaybe<ComponentSharedDenseSpecFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedProductImageBlockGalleryArgs = {
  filters?: InputMaybe<ComponentUiImageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedProductImageBlockLargeSpecArgs = {
  filters?: InputMaybe<ComponentUiSpecFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedProductImageBlockFiltersInput = {
  anchor?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<
    Array<InputMaybe<ComponentSharedProductImageBlockFiltersInput>>
  >;
  button?: InputMaybe<ComponentUiButtonFiltersInput>;
  denseSpecs?: InputMaybe<ComponentSharedDenseSpecFiltersInput>;
  gallery?: InputMaybe<ComponentUiImageFiltersInput>;
  galleryExtraText?: InputMaybe<StringFilterInput>;
  largeSpec?: InputMaybe<ComponentUiSpecFiltersInput>;
  not?: InputMaybe<ComponentSharedProductImageBlockFiltersInput>;
  or?: InputMaybe<
    Array<InputMaybe<ComponentSharedProductImageBlockFiltersInput>>
  >;
  specTable?: InputMaybe<SpecTableFiltersInput>;
  subtitle?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedProductImageBlockInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  button?: InputMaybe<ComponentUiButtonInput>;
  denseSpecs?: InputMaybe<Array<InputMaybe<ComponentSharedDenseSpecInput>>>;
  gallery?: InputMaybe<Array<InputMaybe<ComponentUiImageInput>>>;
  galleryExtraText?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  largeSpec?: InputMaybe<Array<InputMaybe<ComponentUiSpecInput>>>;
  specTable?: InputMaybe<Scalars['ID']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedProductSpecsBlocks = {
  __typename?: 'ComponentSharedProductSpecsBlocks';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  specs_options?: Maybe<SpecsOptionRelationResponseCollection>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedProductSpecsBlocksSpecs_OptionsArgs = {
  filters?: InputMaybe<SpecsOptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedProductSpecsBlocksFiltersInput = {
  anchor?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<
    Array<InputMaybe<ComponentSharedProductSpecsBlocksFiltersInput>>
  >;
  not?: InputMaybe<ComponentSharedProductSpecsBlocksFiltersInput>;
  or?: InputMaybe<
    Array<InputMaybe<ComponentSharedProductSpecsBlocksFiltersInput>>
  >;
  specs_options?: InputMaybe<SpecsOptionFiltersInput>;
  subtitle?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedProductSpecsBlocksInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  specs_options?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedSectionCards = {
  __typename?: 'ComponentSharedSectionCards';
  anchor?: Maybe<Scalars['String']['output']>;
  iconCard?: Maybe<Array<Maybe<ComponentUiIconCard>>>;
  id: Scalars['ID']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ComponentSharedSectionCardsIconCardArgs = {
  filters?: InputMaybe<ComponentUiIconCardFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedSectionCardsInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  iconCard?: InputMaybe<Array<InputMaybe<ComponentUiIconCardInput>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedSeo = {
  __typename?: 'ComponentSharedSeo';
  canonicalURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  keywords?: Maybe<Scalars['String']['output']>;
  metaDescription: Scalars['String']['output'];
  metaImage?: Maybe<UploadFileEntityResponse>;
  metaRobots?: Maybe<Scalars['String']['output']>;
  metaSocial?: Maybe<Array<Maybe<ComponentSharedMetaSocial>>>;
  metaTitle: Scalars['String']['output'];
  metaViewport?: Maybe<Scalars['String']['output']>;
  structuredData?: Maybe<Scalars['JSON']['output']>;
};

export type ComponentSharedSeoMetaSocialArgs = {
  filters?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedSeoFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedSeoFiltersInput>>>;
  canonicalURL?: InputMaybe<StringFilterInput>;
  keywords?: InputMaybe<StringFilterInput>;
  metaDescription?: InputMaybe<StringFilterInput>;
  metaRobots?: InputMaybe<StringFilterInput>;
  metaSocial?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
  metaTitle?: InputMaybe<StringFilterInput>;
  metaViewport?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentSharedSeoFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedSeoFiltersInput>>>;
  structuredData?: InputMaybe<JsonFilterInput>;
};

export type ComponentSharedSeoInput = {
  canonicalURL?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  metaImage?: InputMaybe<Scalars['ID']['input']>;
  metaRobots?: InputMaybe<Scalars['String']['input']>;
  metaSocial?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialInput>>>;
  metaTitle?: InputMaybe<Scalars['String']['input']>;
  metaViewport?: InputMaybe<Scalars['String']['input']>;
  structuredData?: InputMaybe<Scalars['JSON']['input']>;
};

export type ComponentSharedSliderImageSection = {
  __typename?: 'ComponentSharedSliderImageSection';
  anchor?: Maybe<Scalars['String']['output']>;
  button?: Maybe<ComponentUiButton>;
  id: Scalars['ID']['output'];
  imageCardLink?: Maybe<Array<Maybe<ComponentUiImageCardLink>>>;
  title?: Maybe<Scalars['String']['output']>;
  titleMobile?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedSliderImageSectionImageCardLinkArgs = {
  filters?: InputMaybe<ComponentUiImageCardLinkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedSliderImageSectionInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  button?: InputMaybe<ComponentUiButtonInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageCardLink?: InputMaybe<Array<InputMaybe<ComponentUiImageCardLinkInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleMobile?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedTeamCardsSection = {
  __typename?: 'ComponentSharedTeamCardsSection';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  teammates?: Maybe<Array<Maybe<ComponentUiTeamMate>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedTeamCardsSectionTeammatesArgs = {
  filters?: InputMaybe<ComponentUiTeamMateFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedTeamCardsSectionInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  teammates?: InputMaybe<Array<InputMaybe<ComponentUiTeamMateInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedTwoImagesGrid = {
  __typename?: 'ComponentSharedTwoImagesGrid';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imagesGridItem?: Maybe<Array<Maybe<ComponentUiCertification>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedTwoImagesGridImagesGridItemArgs = {
  filters?: InputMaybe<ComponentUiCertificationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedTwoImagesGridFiltersInput = {
  anchor?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentSharedTwoImagesGridFiltersInput>>>;
  imagesGridItem?: InputMaybe<ComponentUiCertificationFiltersInput>;
  not?: InputMaybe<ComponentSharedTwoImagesGridFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedTwoImagesGridFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedTwoImagesGridInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imagesGridItem?: InputMaybe<Array<InputMaybe<ComponentUiCertificationInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedValues = {
  __typename?: 'ComponentSharedValues';
  anchor?: Maybe<Scalars['String']['output']>;
  cards?: Maybe<Array<Maybe<ComponentUiIconCard>>>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedValuesCardsArgs = {
  filters?: InputMaybe<ComponentUiIconCardFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedValuesInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  cards?: InputMaybe<Array<InputMaybe<ComponentUiIconCardInput>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedVideoSection = {
  __typename?: 'ComponentSharedVideoSection';
  anchor?: Maybe<Scalars['String']['output']>;
  button?: Maybe<ComponentUiButton>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
  video?: Maybe<ComponentUiVideo>;
};

export type ComponentSharedVideoSectionInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  button?: InputMaybe<ComponentUiButtonInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<ComponentUiVideoInput>;
};

export type ComponentUiButton = {
  __typename?: 'ComponentUiButton';
  file?: Maybe<UploadFileEntityResponse>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  openInNewTab?: Maybe<Scalars['Boolean']['output']>;
  path?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiButtonFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiButtonFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiButtonFiltersInput>;
  openInNewTab?: InputMaybe<BooleanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiButtonFiltersInput>>>;
  path?: InputMaybe<StringFilterInput>;
};

export type ComponentUiButtonInput = {
  file?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiCampus = {
  __typename?: 'ComponentUiCampus';
  Text?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  spec?: Maybe<Array<Maybe<ComponentUiSpec>>>;
};

export type ComponentUiCampusSpecArgs = {
  filters?: InputMaybe<ComponentUiSpecFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiCampusFiltersInput = {
  Text?: InputMaybe<StringFilterInput>;
  Title?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiCampusFiltersInput>>>;
  image?: InputMaybe<ComponentUiImageFiltersInput>;
  not?: InputMaybe<ComponentUiCampusFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiCampusFiltersInput>>>;
  spec?: InputMaybe<ComponentUiSpecFiltersInput>;
};

export type ComponentUiCampusInput = {
  Text?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<ComponentUiImageInput>;
  spec?: InputMaybe<Array<InputMaybe<ComponentUiSpecInput>>>;
};

export type ComponentUiCarInfoItems = {
  __typename?: 'ComponentUiCarInfoItems';
  icon?: Maybe<UploadFileEntityResponse>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiCarInfoItemsFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiCarInfoItemsFiltersInput>>>;
  not?: InputMaybe<ComponentUiCarInfoItemsFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiCarInfoItemsFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentUiCarInfoItemsInput = {
  icon?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiCertification = {
  __typename?: 'ComponentUiCertification';
  alt: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  img_desktop: UploadFileEntityResponse;
  img_mobile?: Maybe<UploadFileEntityResponse>;
  tag?: Maybe<Scalars['String']['output']>;
  tagColor?: Maybe<Enum_Componentuicertification_Tagcolor>;
  title: Scalars['String']['output'];
};

export type ComponentUiCertificationFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiCertificationFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiCertificationFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiCertificationFiltersInput>>>;
  tag?: InputMaybe<StringFilterInput>;
  tagColor?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentUiCertificationInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  img_desktop?: InputMaybe<Scalars['ID']['input']>;
  img_mobile?: InputMaybe<Scalars['ID']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  tagColor?: InputMaybe<Enum_Componentuicertification_Tagcolor>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiGraph = {
  __typename?: 'ComponentUiGraph';
  alt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image: UploadFileEntityResponse;
  imageMobile?: Maybe<UploadFileEntityResponse>;
  label?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiGraphFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiGraphFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiGraphFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiGraphFiltersInput>>>;
};

export type ComponentUiGraphInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  imageMobile?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiHeading = {
  __typename?: 'ComponentUiHeading';
  id: Scalars['ID']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiHeadingFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiHeadingFiltersInput>>>;
  not?: InputMaybe<ComponentUiHeadingFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiHeadingFiltersInput>>>;
  subtitle?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentUiHeadingInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiHeroImageSection = {
  __typename?: 'ComponentUiHeroImageSection';
  button?: Maybe<ComponentUiButton>;
  heroImage?: Maybe<ComponentUiImage>;
  id: Scalars['ID']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ComponentUiHeroImageSectionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiHeroImageSectionFiltersInput>>>;
  button?: InputMaybe<ComponentUiButtonFiltersInput>;
  heroImage?: InputMaybe<ComponentUiImageFiltersInput>;
  not?: InputMaybe<ComponentUiHeroImageSectionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiHeroImageSectionFiltersInput>>>;
  subtitle?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentUiHeroImageSectionInput = {
  button?: InputMaybe<ComponentUiButtonInput>;
  heroImage?: InputMaybe<ComponentUiImageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiHeroVideoSection = {
  __typename?: 'ComponentUiHeroVideoSection';
  button?: Maybe<ComponentUiButton>;
  heroVideo?: Maybe<ComponentUiVideo>;
  id: Scalars['ID']['output'];
  previewVideo?: Maybe<ComponentUiVideo>;
  previewVideoLength?: Maybe<Scalars['String']['output']>;
  showPreviewVideo?: Maybe<Scalars['Boolean']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ComponentUiHeroVideoSectionInput = {
  button?: InputMaybe<ComponentUiButtonInput>;
  heroVideo?: InputMaybe<ComponentUiVideoInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  previewVideo?: InputMaybe<ComponentUiVideoInput>;
  previewVideoLength?: InputMaybe<Scalars['String']['input']>;
  showPreviewVideo?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiIcon = {
  __typename?: 'ComponentUiIcon';
  alt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  svg: UploadFileEntityResponse;
};

export type ComponentUiIconCard = {
  __typename?: 'ComponentUiIconCard';
  description: Scalars['String']['output'];
  icon: UploadFileEntityResponse;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type ComponentUiIconCardFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiIconCardFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiIconCardFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiIconCardFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentUiIconCardInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiIconFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiIconFiltersInput>>>;
  not?: InputMaybe<ComponentUiIconFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiIconFiltersInput>>>;
};

export type ComponentUiIconInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  svg?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentUiImage = {
  __typename?: 'ComponentUiImage';
  alt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  img_desktop: UploadFileEntityResponse;
  img_mobile?: Maybe<UploadFileEntityResponse>;
};

export type ComponentUiImageCardLink = {
  __typename?: 'ComponentUiImageCardLink';
  alt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  img_desktop: UploadFileEntityResponse;
  img_mobil?: Maybe<UploadFileEntityResponse>;
  link_label?: Maybe<Scalars['String']['output']>;
  link_path?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiImageCardLinkFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiImageCardLinkFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  link_label?: InputMaybe<StringFilterInput>;
  link_path?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiImageCardLinkFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiImageCardLinkFiltersInput>>>;
  tag?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentUiImageCardLinkInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  img_desktop?: InputMaybe<Scalars['ID']['input']>;
  img_mobil?: InputMaybe<Scalars['ID']['input']>;
  link_label?: InputMaybe<Scalars['String']['input']>;
  link_path?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiImageFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiImageFiltersInput>>>;
  not?: InputMaybe<ComponentUiImageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiImageFiltersInput>>>;
};

export type ComponentUiImageInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  img_desktop?: InputMaybe<Scalars['ID']['input']>;
  img_mobile?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentUiParagraph = {
  __typename?: 'ComponentUiParagraph';
  id: Scalars['ID']['output'];
  paragraphs?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiParagraphFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiParagraphFiltersInput>>>;
  not?: InputMaybe<ComponentUiParagraphFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiParagraphFiltersInput>>>;
  paragraphs?: InputMaybe<StringFilterInput>;
};

export type ComponentUiParagraphInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  paragraphs?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiSpec = {
  __typename?: 'ComponentUiSpec';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiSpecFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiSpecFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiSpecFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiSpecFiltersInput>>>;
  value?: InputMaybe<StringFilterInput>;
};

export type ComponentUiSpecInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiTeamMate = {
  __typename?: 'ComponentUiTeamMate';
  alt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  img_desktop: UploadFileEntityResponse;
  img_mobile?: Maybe<UploadFileEntityResponse>;
  name: Scalars['String']['output'];
  position?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiTeamMateFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiTeamMateFiltersInput>>>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiTeamMateFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiTeamMateFiltersInput>>>;
  position?: InputMaybe<StringFilterInput>;
};

export type ComponentUiTeamMateInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  img_desktop?: InputMaybe<Scalars['ID']['input']>;
  img_mobile?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiVehicleInfoContent = {
  __typename?: 'ComponentUiVehicleInfoContent';
  carInfoItems?: Maybe<Array<Maybe<ComponentUiCarInfoItems>>>;
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiVehicleInfoContentCarInfoItemsArgs = {
  filters?: InputMaybe<ComponentUiCarInfoItemsFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiVehicleInfoContentFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentUiVehicleInfoContentFiltersInput>>
  >;
  carInfoItems?: InputMaybe<ComponentUiCarInfoItemsFiltersInput>;
  not?: InputMaybe<ComponentUiVehicleInfoContentFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiVehicleInfoContentFiltersInput>>>;
  text?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentUiVehicleInfoContentInput = {
  carInfoItems?: InputMaybe<Array<InputMaybe<ComponentUiCarInfoItemsInput>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiVideo = {
  __typename?: 'ComponentUiVideo';
  id: Scalars['ID']['output'];
  mobilePoster?: Maybe<UploadFileEntityResponse>;
  poster: UploadFileEntityResponse;
  poster_alt?: Maybe<Scalars['String']['output']>;
  video?: Maybe<UploadFileEntityResponse>;
};

export type ComponentUiVideoInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  mobilePoster?: InputMaybe<Scalars['ID']['input']>;
  poster?: InputMaybe<Scalars['ID']['input']>;
  poster_alt?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<Scalars['ID']['input']>;
};

export type ContactFomResponse = {
  __typename?: 'ContactFomResponse';
  error?: Maybe<ErrorResult>;
  id?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ContactFormDataInput = {
  companyName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  terms: Scalars['Boolean']['input'];
};

export type CookiePolicy = {
  __typename?: 'CookiePolicy';
  cookiePolicy?: Maybe<Array<Maybe<CookiePolicyCookiePolicyDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CookiePolicyCookiePolicyDynamicZone =
  | ComponentPoliciesDevider
  | ComponentPoliciesDownloadLink
  | ComponentPoliciesMainHeading
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | Error;

export type CookiePolicyEntity = {
  __typename?: 'CookiePolicyEntity';
  attributes?: Maybe<CookiePolicy>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CookiePolicyEntityResponse = {
  __typename?: 'CookiePolicyEntityResponse';
  data?: Maybe<CookiePolicyEntity>;
};

export type CookiePolicyInput = {
  cookiePolicy?: InputMaybe<
    Array<Scalars['CookiePolicyCookiePolicyDynamicZoneInput']['input']>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type CookieSetting = {
  __typename?: 'CookieSetting';
  actionButtonLabel?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  necessaryCookiesHeading?: Maybe<Scalars['String']['output']>;
  necessaryCookiesTable?: Maybe<ComponentPoliciesTableTextContent>;
  optionalCookiesHeading?: Maybe<Scalars['String']['output']>;
  optionalCookiesTale?: Maybe<ComponentPoliciesTableTextContent>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  redirectButton?: Maybe<ComponentUiButton>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CookieSettingEntity = {
  __typename?: 'CookieSettingEntity';
  attributes?: Maybe<CookieSetting>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CookieSettingEntityResponse = {
  __typename?: 'CookieSettingEntityResponse';
  data?: Maybe<CookieSettingEntity>;
};

export type CookieSettingInput = {
  actionButtonLabel?: InputMaybe<Scalars['String']['input']>;
  necessaryCookiesHeading?: InputMaybe<Scalars['String']['input']>;
  necessaryCookiesTable?: InputMaybe<ComponentPoliciesTableTextContentInput>;
  optionalCookiesHeading?: InputMaybe<Scalars['String']['input']>;
  optionalCookiesTale?: InputMaybe<ComponentPoliciesTableTextContentInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  redirectButton?: InputMaybe<ComponentUiButtonInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CookiesBanner = {
  __typename?: 'CookiesBanner';
  acceptButtonLabel?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  declineButtonLabel?: Maybe<Scalars['String']['output']>;
  editButtonLabel?: Maybe<Scalars['String']['output']>;
  extraLinks?: Maybe<Array<Maybe<ComponentUiButton>>>;
  heading?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  text?: Maybe<Scalars['JSON']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CookiesBannerExtraLinksArgs = {
  filters?: InputMaybe<ComponentUiButtonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CookiesBannerEntity = {
  __typename?: 'CookiesBannerEntity';
  attributes?: Maybe<CookiesBanner>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CookiesBannerEntityResponse = {
  __typename?: 'CookiesBannerEntityResponse';
  data?: Maybe<CookiesBannerEntity>;
};

export type CookiesBannerInput = {
  acceptButtonLabel?: InputMaybe<Scalars['String']['input']>;
  declineButtonLabel?: InputMaybe<Scalars['String']['input']>;
  editButtonLabel?: InputMaybe<Scalars['String']['input']>;
  extraLinks?: InputMaybe<Array<InputMaybe<ComponentUiButtonInput>>>;
  heading?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['JSON']['input']>;
};

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  contains?: InputMaybe<Scalars['DateTime']['input']>;
  containsi?: InputMaybe<Scalars['DateTime']['input']>;
  endsWith?: InputMaybe<Scalars['DateTime']['input']>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  eqi?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  ne?: InputMaybe<Scalars['DateTime']['input']>;
  nei?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<DateTimeFilterInput>;
  notContains?: InputMaybe<Scalars['DateTime']['input']>;
  notContainsi?: InputMaybe<Scalars['DateTime']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  startsWith?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum Enum_Componentcasestudyarticlepreview_Tagcolor {
  Dark = 'dark',
  Default = 'default',
  Light = 'light',
}

export enum Enum_Componentsharedmetasocial_Socialnetwork {
  Facebook = 'Facebook',
  Twitter = 'Twitter',
}

export enum Enum_Componentuicertification_Tagcolor {
  Dark = 'dark',
  Default = 'default',
  Light = 'light',
}

export enum Enum_Sitemapsitemap_Type {
  DefaultHreflang = 'default_hreflang',
  Index = 'index',
}

export type Ecus = {
  __typename?: 'Ecus';
  cardsSection?: Maybe<ComponentSharedSectionCards>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heroVideoSection: ComponentUiHeroVideoSection;
  partnersBlogSection?: Maybe<ComponentSharedPartnersBlogSection>;
  productImageBlock?: Maybe<Array<Maybe<ComponentSharedProductImageBlock>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  sliderImageSection?: Maybe<ComponentSharedSliderImageSection>;
  slug?: Maybe<Scalars['String']['output']>;
  twoImagesGrid?: Maybe<ComponentSharedTwoImagesGrid>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EcusProductImageBlockArgs = {
  filters?: InputMaybe<ComponentSharedProductImageBlockFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type EcusEntity = {
  __typename?: 'EcusEntity';
  attributes?: Maybe<Ecus>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type EcusEntityResponse = {
  __typename?: 'EcusEntityResponse';
  data?: Maybe<EcusEntity>;
};

export type EcusInput = {
  cardsSection?: InputMaybe<ComponentSharedSectionCardsInput>;
  heroVideoSection?: InputMaybe<ComponentUiHeroVideoSectionInput>;
  partnersBlogSection?: InputMaybe<ComponentSharedPartnersBlogSectionInput>;
  productImageBlock?: InputMaybe<
    Array<InputMaybe<ComponentSharedProductImageBlockInput>>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  sliderImageSection?: InputMaybe<ComponentSharedSliderImageSectionInput>;
  slug?: InputMaybe<Scalars['String']['input']>;
  twoImagesGrid?: InputMaybe<ComponentSharedTwoImagesGridInput>;
};

export type Error = {
  __typename?: 'Error';
  code: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type ErrorResult = {
  __typename?: 'ErrorResult';
  fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  message?: Maybe<Scalars['String']['output']>;
  statusCode?: Maybe<Scalars['String']['output']>;
};

export type FileInfoInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  contains?: InputMaybe<Scalars['Float']['input']>;
  containsi?: InputMaybe<Scalars['Float']['input']>;
  endsWith?: InputMaybe<Scalars['Float']['input']>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  eqi?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
  nei?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<FloatFilterInput>;
  notContains?: InputMaybe<Scalars['Float']['input']>;
  notContainsi?: InputMaybe<Scalars['Float']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  startsWith?: InputMaybe<Scalars['Float']['input']>;
};

export type Footer = {
  __typename?: 'Footer';
  column?: Maybe<Array<Maybe<ComponentFooterColumn>>>;
  contact?: Maybe<ComponentFooterContact>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  policies?: Maybe<ComponentFooterPolicies>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  socials?: Maybe<ComponentFooterSocials>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FooterColumnArgs = {
  filters?: InputMaybe<ComponentFooterColumnFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FooterEntity = {
  __typename?: 'FooterEntity';
  attributes?: Maybe<Footer>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type FooterEntityResponse = {
  __typename?: 'FooterEntityResponse';
  data?: Maybe<FooterEntity>;
};

export type FooterInput = {
  column?: InputMaybe<Array<InputMaybe<ComponentFooterColumnInput>>>;
  contact?: InputMaybe<ComponentFooterContactInput>;
  policies?: InputMaybe<ComponentFooterPoliciesInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  socials?: InputMaybe<ComponentFooterSocialsInput>;
};

export type GenericMorph =
  | Battery
  | Bms
  | CaseStudy
  | Company
  | ComponentCaseStudyArticleHero
  | ComponentCaseStudyArticlePreview
  | ComponentCaseStudyGallery
  | ComponentCaseStudyTextContent
  | ComponentFooterColumn
  | ComponentFooterContact
  | ComponentFooterLink
  | ComponentFooterPolicies
  | ComponentFooterSocials
  | ComponentHomepageImageCardsWIthLink
  | ComponentNavigationContactInfo
  | ComponentNavigationLink
  | ComponentNavigationOpenMenuColumn
  | ComponentNavigationSocials
  | ComponentPoliciesBullets
  | ComponentPoliciesDevider
  | ComponentPoliciesDownloadLink
  | ComponentPoliciesMainHeading
  | ComponentPoliciesParagpraph
  | ComponentPoliciesRowItem
  | ComponentPoliciesTableHeading
  | ComponentPoliciesTableRow
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | ComponentPowertrainTechnologies
  | ComponentSharedDenseSpec
  | ComponentSharedLargeImageSection
  | ComponentSharedMapSection
  | ComponentSharedMetaSocial
  | ComponentSharedNewsroom
  | ComponentSharedPartnersBlogSection
  | ComponentSharedProductImageBlock
  | ComponentSharedProductSpecsBlocks
  | ComponentSharedSectionCards
  | ComponentSharedSeo
  | ComponentSharedSliderImageSection
  | ComponentSharedTeamCardsSection
  | ComponentSharedTwoImagesGrid
  | ComponentSharedValues
  | ComponentSharedVideoSection
  | ComponentUiButton
  | ComponentUiCampus
  | ComponentUiCarInfoItems
  | ComponentUiCertification
  | ComponentUiGraph
  | ComponentUiHeading
  | ComponentUiHeroImageSection
  | ComponentUiHeroVideoSection
  | ComponentUiIcon
  | ComponentUiIconCard
  | ComponentUiImage
  | ComponentUiImageCardLink
  | ComponentUiParagraph
  | ComponentUiSpec
  | ComponentUiTeamMate
  | ComponentUiVehicleInfoContent
  | ComponentUiVideo
  | CookiePolicy
  | CookieSetting
  | CookiesBanner
  | Ecus
  | Footer
  | GetInTouch
  | Home
  | I18NLocale
  | Impressum
  | LegalNotice
  | MlBattProtEuProject
  | Navigation
  | Powertrain
  | PrivacyPolicy
  | SitemapSitemap
  | SitemapSitemapCache
  | Software
  | SpecTable
  | SpecsOption
  | SupplierPortal
  | TableRow
  | UploadFile
  | UploadFolder
  | UsersPermissionsPermission
  | UsersPermissionsRole
  | UsersPermissionsUser
  | WhistleblowingPolicy;

export type GetInTouch = {
  __typename?: 'GetInTouch';
  anchor?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  first_line?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Array<Maybe<ComponentUiSpec>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  second_line?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type GetInTouchOptionsArgs = {
  filters?: InputMaybe<ComponentUiSpecFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GetInTouchEntity = {
  __typename?: 'GetInTouchEntity';
  attributes?: Maybe<GetInTouch>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type GetInTouchEntityResponse = {
  __typename?: 'GetInTouchEntityResponse';
  data?: Maybe<GetInTouchEntity>;
};

export type GetInTouchInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  first_line?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<InputMaybe<ComponentUiSpecInput>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  second_line?: InputMaybe<Scalars['String']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type Home = {
  __typename?: 'Home';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heroVideoSection: ComponentUiHeroVideoSection;
  imageCardsWithLink?: Maybe<ComponentHomepageImageCardsWIthLink>;
  largeImageSection?: Maybe<ComponentSharedLargeImageSection>;
  partnersBlogSection?: Maybe<ComponentSharedPartnersBlogSection>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type HomeEntity = {
  __typename?: 'HomeEntity';
  attributes?: Maybe<Home>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type HomeEntityResponse = {
  __typename?: 'HomeEntityResponse';
  data?: Maybe<HomeEntity>;
};

export type HomeInput = {
  heroVideoSection?: InputMaybe<ComponentUiHeroVideoSectionInput>;
  imageCardsWithLink?: InputMaybe<ComponentHomepageImageCardsWIthLinkInput>;
  largeImageSection?: InputMaybe<ComponentSharedLargeImageSectionInput>;
  partnersBlogSection?: InputMaybe<ComponentSharedPartnersBlogSectionInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
};

export type I18NLocale = {
  __typename?: 'I18NLocale';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity';
  attributes?: Maybe<I18NLocale>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<I18NLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type IdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contains?: InputMaybe<Scalars['ID']['input']>;
  containsi?: InputMaybe<Scalars['ID']['input']>;
  endsWith?: InputMaybe<Scalars['ID']['input']>;
  eq?: InputMaybe<Scalars['ID']['input']>;
  eqi?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  ne?: InputMaybe<Scalars['ID']['input']>;
  nei?: InputMaybe<Scalars['ID']['input']>;
  not?: InputMaybe<IdFilterInput>;
  notContains?: InputMaybe<Scalars['ID']['input']>;
  notContainsi?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  startsWith?: InputMaybe<Scalars['ID']['input']>;
};

export type Impressum = {
  __typename?: 'Impressum';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  lastUpdatedText?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  text?: Maybe<Scalars['JSON']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ImpressumEntity = {
  __typename?: 'ImpressumEntity';
  attributes?: Maybe<Impressum>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ImpressumEntityResponse = {
  __typename?: 'ImpressumEntityResponse';
  data?: Maybe<ImpressumEntity>;
};

export type ImpressumInput = {
  lastUpdatedText?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  contains?: InputMaybe<Scalars['Int']['input']>;
  containsi?: InputMaybe<Scalars['Int']['input']>;
  endsWith?: InputMaybe<Scalars['Int']['input']>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  eqi?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
  nei?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<IntFilterInput>;
  notContains?: InputMaybe<Scalars['Int']['input']>;
  notContainsi?: InputMaybe<Scalars['Int']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  startsWith?: InputMaybe<Scalars['Int']['input']>;
};

export type JsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  contains?: InputMaybe<Scalars['JSON']['input']>;
  containsi?: InputMaybe<Scalars['JSON']['input']>;
  endsWith?: InputMaybe<Scalars['JSON']['input']>;
  eq?: InputMaybe<Scalars['JSON']['input']>;
  eqi?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  ne?: InputMaybe<Scalars['JSON']['input']>;
  nei?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<JsonFilterInput>;
  notContains?: InputMaybe<Scalars['JSON']['input']>;
  notContainsi?: InputMaybe<Scalars['JSON']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  startsWith?: InputMaybe<Scalars['JSON']['input']>;
};

export type LegalNotice = {
  __typename?: 'LegalNotice';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  legalNotice?: Maybe<Array<Maybe<LegalNoticeLegalNoticeDynamicZone>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LegalNoticeEntity = {
  __typename?: 'LegalNoticeEntity';
  attributes?: Maybe<LegalNotice>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type LegalNoticeEntityResponse = {
  __typename?: 'LegalNoticeEntityResponse';
  data?: Maybe<LegalNoticeEntity>;
};

export type LegalNoticeInput = {
  legalNotice?: InputMaybe<
    Array<Scalars['LegalNoticeLegalNoticeDynamicZoneInput']['input']>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type LegalNoticeLegalNoticeDynamicZone =
  | ComponentPoliciesDevider
  | ComponentPoliciesDownloadLink
  | ComponentPoliciesMainHeading
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | Error;

export type MlBattProtEuProject = {
  __typename?: 'MlBattProtEuProject';
  contents?: Maybe<Array<Maybe<MlBattProtEuProjectContentsDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  logos?: Maybe<Array<Maybe<ComponentUiImage>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type MlBattProtEuProjectLogosArgs = {
  filters?: InputMaybe<ComponentUiImageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MlBattProtEuProjectContentsDynamicZone =
  | ComponentPoliciesDevider
  | ComponentPoliciesDownloadLink
  | ComponentPoliciesMainHeading
  | ComponentPoliciesTitleTextContent
  | Error;

export type MlBattProtEuProjectEntity = {
  __typename?: 'MlBattProtEuProjectEntity';
  attributes?: Maybe<MlBattProtEuProject>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type MlBattProtEuProjectEntityResponse = {
  __typename?: 'MlBattProtEuProjectEntityResponse';
  data?: Maybe<MlBattProtEuProjectEntity>;
};

export type MlBattProtEuProjectInput = {
  contents?: InputMaybe<
    Array<Scalars['MlBattProtEuProjectContentsDynamicZoneInput']['input']>
  >;
  logos?: InputMaybe<Array<InputMaybe<ComponentUiImageInput>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  createCaseStudy?: Maybe<CaseStudyEntityResponse>;
  createSitemapSitemap?: Maybe<SitemapSitemapEntityResponse>;
  createSitemapSitemapCache?: Maybe<SitemapSitemapCacheEntityResponse>;
  createSpecTable?: Maybe<SpecTableEntityResponse>;
  createSpecsOption?: Maybe<SpecsOptionEntityResponse>;
  createTableRow?: Maybe<TableRowEntityResponse>;
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteBattery?: Maybe<BatteryEntityResponse>;
  deleteBms?: Maybe<BmsEntityResponse>;
  deleteCaseStudy?: Maybe<CaseStudyEntityResponse>;
  deleteCompany?: Maybe<CompanyEntityResponse>;
  deleteCookiePolicy?: Maybe<CookiePolicyEntityResponse>;
  deleteCookieSetting?: Maybe<CookieSettingEntityResponse>;
  deleteCookiesBanner?: Maybe<CookiesBannerEntityResponse>;
  deleteEcus?: Maybe<EcusEntityResponse>;
  deleteFooter?: Maybe<FooterEntityResponse>;
  deleteGetInTouch?: Maybe<GetInTouchEntityResponse>;
  deleteHome?: Maybe<HomeEntityResponse>;
  deleteImpressum?: Maybe<ImpressumEntityResponse>;
  deleteLegalNotice?: Maybe<LegalNoticeEntityResponse>;
  deleteMlBattProtEuProject?: Maybe<MlBattProtEuProjectEntityResponse>;
  deleteNavigation?: Maybe<NavigationEntityResponse>;
  deletePowertrain?: Maybe<PowertrainEntityResponse>;
  deletePrivacyPolicy?: Maybe<PrivacyPolicyEntityResponse>;
  deleteSitemapSitemap?: Maybe<SitemapSitemapEntityResponse>;
  deleteSitemapSitemapCache?: Maybe<SitemapSitemapCacheEntityResponse>;
  deleteSoftware?: Maybe<SoftwareEntityResponse>;
  deleteSpecTable?: Maybe<SpecTableEntityResponse>;
  deleteSpecsOption?: Maybe<SpecsOptionEntityResponse>;
  deleteSupplierPortal?: Maybe<SupplierPortalEntityResponse>;
  deleteTableRow?: Maybe<TableRowEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteWhistleblowingPolicy?: Maybe<WhistleblowingPolicyEntityResponse>;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  removeFile?: Maybe<UploadFileEntityResponse>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  sendContactForm?: Maybe<ContactFomResponse>;
  updateBattery?: Maybe<BatteryEntityResponse>;
  updateBms?: Maybe<BmsEntityResponse>;
  updateCaseStudy?: Maybe<CaseStudyEntityResponse>;
  updateCompany?: Maybe<CompanyEntityResponse>;
  updateCookiePolicy?: Maybe<CookiePolicyEntityResponse>;
  updateCookieSetting?: Maybe<CookieSettingEntityResponse>;
  updateCookiesBanner?: Maybe<CookiesBannerEntityResponse>;
  updateEcus?: Maybe<EcusEntityResponse>;
  updateFileInfo: UploadFileEntityResponse;
  updateFooter?: Maybe<FooterEntityResponse>;
  updateGetInTouch?: Maybe<GetInTouchEntityResponse>;
  updateHome?: Maybe<HomeEntityResponse>;
  updateImpressum?: Maybe<ImpressumEntityResponse>;
  updateLegalNotice?: Maybe<LegalNoticeEntityResponse>;
  updateMlBattProtEuProject?: Maybe<MlBattProtEuProjectEntityResponse>;
  updateNavigation?: Maybe<NavigationEntityResponse>;
  updatePowertrain?: Maybe<PowertrainEntityResponse>;
  updatePrivacyPolicy?: Maybe<PrivacyPolicyEntityResponse>;
  updateSitemapSitemap?: Maybe<SitemapSitemapEntityResponse>;
  updateSitemapSitemapCache?: Maybe<SitemapSitemapCacheEntityResponse>;
  updateSoftware?: Maybe<SoftwareEntityResponse>;
  updateSpecTable?: Maybe<SpecTableEntityResponse>;
  updateSpecsOption?: Maybe<SpecsOptionEntityResponse>;
  updateSupplierPortal?: Maybe<SupplierPortalEntityResponse>;
  updateTableRow?: Maybe<TableRowEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  updateWhistleblowingPolicy?: Maybe<WhistleblowingPolicyEntityResponse>;
  upload: UploadFileEntityResponse;
};

export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

export type MutationCreateCaseStudyArgs = {
  data: CaseStudyInput;
};

export type MutationCreateSitemapSitemapArgs = {
  data: SitemapSitemapInput;
};

export type MutationCreateSitemapSitemapCacheArgs = {
  data: SitemapSitemapCacheInput;
};

export type MutationCreateSpecTableArgs = {
  data: SpecTableInput;
};

export type MutationCreateSpecsOptionArgs = {
  data: SpecsOptionInput;
};

export type MutationCreateTableRowArgs = {
  data: TableRowInput;
};

export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};

export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput;
};

export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};

export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};

export type MutationDeleteCaseStudyArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteSitemapSitemapArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteSitemapSitemapCacheArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteSpecTableArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteSpecsOptionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteTableRowArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUploadFolderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String']['input'];
};

export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};

export type MutationMultipleUploadArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  files: Array<InputMaybe<Scalars['Upload']['input']>>;
  ref?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};

export type MutationRemoveFileArgs = {
  id: Scalars['ID']['input'];
};

export type MutationResetPasswordArgs = {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

export type MutationSendContactFormArgs = {
  data: ContactFormDataInput;
};

export type MutationUpdateBatteryArgs = {
  data: BatteryInput;
};

export type MutationUpdateBmsArgs = {
  data: BmsInput;
};

export type MutationUpdateCaseStudyArgs = {
  data: CaseStudyInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateCompanyArgs = {
  data: CompanyInput;
};

export type MutationUpdateCookiePolicyArgs = {
  data: CookiePolicyInput;
};

export type MutationUpdateCookieSettingArgs = {
  data: CookieSettingInput;
};

export type MutationUpdateCookiesBannerArgs = {
  data: CookiesBannerInput;
};

export type MutationUpdateEcusArgs = {
  data: EcusInput;
};

export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID']['input'];
  info?: InputMaybe<FileInfoInput>;
};

export type MutationUpdateFooterArgs = {
  data: FooterInput;
};

export type MutationUpdateGetInTouchArgs = {
  data: GetInTouchInput;
};

export type MutationUpdateHomeArgs = {
  data: HomeInput;
};

export type MutationUpdateImpressumArgs = {
  data: ImpressumInput;
};

export type MutationUpdateLegalNoticeArgs = {
  data: LegalNoticeInput;
};

export type MutationUpdateMlBattProtEuProjectArgs = {
  data: MlBattProtEuProjectInput;
};

export type MutationUpdateNavigationArgs = {
  data: NavigationInput;
};

export type MutationUpdatePowertrainArgs = {
  data: PowertrainInput;
};

export type MutationUpdatePrivacyPolicyArgs = {
  data: PrivacyPolicyInput;
};

export type MutationUpdateSitemapSitemapArgs = {
  data: SitemapSitemapInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateSitemapSitemapCacheArgs = {
  data: SitemapSitemapCacheInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateSoftwareArgs = {
  data: SoftwareInput;
};

export type MutationUpdateSpecTableArgs = {
  data: SpecTableInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateSpecsOptionArgs = {
  data: SpecsOptionInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateSupplierPortalArgs = {
  data: SupplierPortalInput;
};

export type MutationUpdateTableRowArgs = {
  data: TableRowInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUploadFileArgs = {
  data: UploadFileInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUploadFolderArgs = {
  data: UploadFolderInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateWhistleblowingPolicyArgs = {
  data: WhistleblowingPolicyInput;
};

export type MutationUploadArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['Upload']['input'];
  info?: InputMaybe<FileInfoInput>;
  ref?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
};

export type Navigation = {
  __typename?: 'Navigation';
  closedMenuLinks?: Maybe<Array<Maybe<ComponentNavigationLink>>>;
  companyContactInfo?: Maybe<ComponentNavigationContactInfo>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  openMenuColumns?: Maybe<Array<Maybe<ComponentNavigationOpenMenuColumn>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type NavigationClosedMenuLinksArgs = {
  filters?: InputMaybe<ComponentNavigationLinkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type NavigationOpenMenuColumnsArgs = {
  filters?: InputMaybe<ComponentNavigationOpenMenuColumnFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type NavigationEntity = {
  __typename?: 'NavigationEntity';
  attributes?: Maybe<Navigation>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type NavigationEntityResponse = {
  __typename?: 'NavigationEntityResponse';
  data?: Maybe<NavigationEntity>;
};

export type NavigationInput = {
  closedMenuLinks?: InputMaybe<Array<InputMaybe<ComponentNavigationLinkInput>>>;
  companyContactInfo?: InputMaybe<ComponentNavigationContactInfoInput>;
  openMenuColumns?: InputMaybe<
    Array<InputMaybe<ComponentNavigationOpenMenuColumnInput>>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int']['output'];
  pageCount: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginationArg = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type Powertrain = {
  __typename?: 'Powertrain';
  cardsSection?: Maybe<ComponentSharedSectionCards>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heroVideoSection: ComponentUiHeroVideoSection;
  partnersBlogSection?: Maybe<ComponentSharedPartnersBlogSection>;
  productSpecsBlocks?: Maybe<Array<Maybe<ComponentSharedProductSpecsBlocks>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  technologies?: Maybe<ComponentPowertrainTechnologies>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PowertrainProductSpecsBlocksArgs = {
  filters?: InputMaybe<ComponentSharedProductSpecsBlocksFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PowertrainEntity = {
  __typename?: 'PowertrainEntity';
  attributes?: Maybe<Powertrain>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type PowertrainEntityResponse = {
  __typename?: 'PowertrainEntityResponse';
  data?: Maybe<PowertrainEntity>;
};

export type PowertrainInput = {
  cardsSection?: InputMaybe<ComponentSharedSectionCardsInput>;
  heroVideoSection?: InputMaybe<ComponentUiHeroVideoSectionInput>;
  partnersBlogSection?: InputMaybe<ComponentSharedPartnersBlogSectionInput>;
  productSpecsBlocks?: InputMaybe<
    Array<InputMaybe<ComponentSharedProductSpecsBlocksInput>>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  technologies?: InputMaybe<ComponentPowertrainTechnologiesInput>;
};

export type PrivacyPolicy = {
  __typename?: 'PrivacyPolicy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  policies?: Maybe<Array<Maybe<PrivacyPolicyPoliciesDynamicZone>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PrivacyPolicyEntity = {
  __typename?: 'PrivacyPolicyEntity';
  attributes?: Maybe<PrivacyPolicy>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type PrivacyPolicyEntityResponse = {
  __typename?: 'PrivacyPolicyEntityResponse';
  data?: Maybe<PrivacyPolicyEntity>;
};

export type PrivacyPolicyInput = {
  policies?: InputMaybe<
    Array<Scalars['PrivacyPolicyPoliciesDynamicZoneInput']['input']>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type PrivacyPolicyPoliciesDynamicZone =
  | ComponentPoliciesDevider
  | ComponentPoliciesDownloadLink
  | ComponentPoliciesMainHeading
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | Error;

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW',
}

export type Query = {
  __typename?: 'Query';
  battery?: Maybe<BatteryEntityResponse>;
  bms?: Maybe<BmsEntityResponse>;
  caseStudies?: Maybe<CaseStudyEntityResponseCollection>;
  caseStudy?: Maybe<CaseStudyEntityResponse>;
  company?: Maybe<CompanyEntityResponse>;
  cookiePolicy?: Maybe<CookiePolicyEntityResponse>;
  cookieSetting?: Maybe<CookieSettingEntityResponse>;
  cookiesBanner?: Maybe<CookiesBannerEntityResponse>;
  ecus?: Maybe<EcusEntityResponse>;
  footer?: Maybe<FooterEntityResponse>;
  getInTouch?: Maybe<GetInTouchEntityResponse>;
  home?: Maybe<HomeEntityResponse>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  impressum?: Maybe<ImpressumEntityResponse>;
  legalNotice?: Maybe<LegalNoticeEntityResponse>;
  me?: Maybe<UsersPermissionsMe>;
  mlBattProtEuProject?: Maybe<MlBattProtEuProjectEntityResponse>;
  navigation?: Maybe<NavigationEntityResponse>;
  powertrain?: Maybe<PowertrainEntityResponse>;
  privacyPolicy?: Maybe<PrivacyPolicyEntityResponse>;
  sitemapSitemap?: Maybe<SitemapSitemapEntityResponse>;
  sitemapSitemapCache?: Maybe<SitemapSitemapCacheEntityResponse>;
  sitemapSitemapCaches?: Maybe<SitemapSitemapCacheEntityResponseCollection>;
  sitemapSitemaps?: Maybe<SitemapSitemapEntityResponseCollection>;
  software?: Maybe<SoftwareEntityResponse>;
  specTable?: Maybe<SpecTableEntityResponse>;
  specTables?: Maybe<SpecTableEntityResponseCollection>;
  specsOption?: Maybe<SpecsOptionEntityResponse>;
  specsOptions?: Maybe<SpecsOptionEntityResponseCollection>;
  supplierPortal?: Maybe<SupplierPortalEntityResponse>;
  tableRow?: Maybe<TableRowEntityResponse>;
  tableRows?: Maybe<TableRowEntityResponseCollection>;
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
  whistleblowingPolicy?: Maybe<WhistleblowingPolicyEntityResponse>;
};

export type QueryBatteryArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryBmsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryCaseStudiesArgs = {
  filters?: InputMaybe<CaseStudyFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryCaseStudyArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryCompanyArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryCookiePolicyArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryCookieSettingArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryCookiesBannerArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryEcusArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryFooterArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryGetInTouchArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryHomeArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryI18NLocaleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryI18NLocalesArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryImpressumArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryLegalNoticeArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryMlBattProtEuProjectArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryNavigationArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryPowertrainArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryPrivacyPolicyArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QuerySitemapSitemapArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySitemapSitemapCacheArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySitemapSitemapCachesArgs = {
  filters?: InputMaybe<SitemapSitemapCacheFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuerySitemapSitemapsArgs = {
  filters?: InputMaybe<SitemapSitemapFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuerySoftwareArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QuerySpecTableArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySpecTablesArgs = {
  filters?: InputMaybe<SpecTableFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuerySpecsOptionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySpecsOptionsArgs = {
  filters?: InputMaybe<SpecsOptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuerySupplierPortalArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryTableRowArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryTableRowsArgs = {
  filters?: InputMaybe<TableRowFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUploadFileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUploadFolderArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUploadFoldersArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUsersPermissionsRoleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUsersPermissionsRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUsersPermissionsUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUsersPermissionsUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryWhistleblowingPolicyArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export type SitemapSitemap = {
  __typename?: 'SitemapSitemap';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  delta?: Maybe<Scalars['Int']['output']>;
  link_count?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  sitemap_string: Scalars['String']['output'];
  type?: Maybe<Enum_Sitemapsitemap_Type>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SitemapSitemapCache = {
  __typename?: 'SitemapSitemapCache';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  sitemap_id: Scalars['Int']['output'];
  sitemap_json: Scalars['JSON']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SitemapSitemapCacheEntity = {
  __typename?: 'SitemapSitemapCacheEntity';
  attributes?: Maybe<SitemapSitemapCache>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SitemapSitemapCacheEntityResponse = {
  __typename?: 'SitemapSitemapCacheEntityResponse';
  data?: Maybe<SitemapSitemapCacheEntity>;
};

export type SitemapSitemapCacheEntityResponseCollection = {
  __typename?: 'SitemapSitemapCacheEntityResponseCollection';
  data: Array<SitemapSitemapCacheEntity>;
  meta: ResponseCollectionMeta;
};

export type SitemapSitemapCacheFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SitemapSitemapCacheFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SitemapSitemapCacheFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SitemapSitemapCacheFiltersInput>>>;
  sitemap_id?: InputMaybe<IntFilterInput>;
  sitemap_json?: InputMaybe<JsonFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SitemapSitemapCacheInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  sitemap_id?: InputMaybe<Scalars['Int']['input']>;
  sitemap_json?: InputMaybe<Scalars['JSON']['input']>;
};

export type SitemapSitemapEntity = {
  __typename?: 'SitemapSitemapEntity';
  attributes?: Maybe<SitemapSitemap>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SitemapSitemapEntityResponse = {
  __typename?: 'SitemapSitemapEntityResponse';
  data?: Maybe<SitemapSitemapEntity>;
};

export type SitemapSitemapEntityResponseCollection = {
  __typename?: 'SitemapSitemapEntityResponseCollection';
  data: Array<SitemapSitemapEntity>;
  meta: ResponseCollectionMeta;
};

export type SitemapSitemapFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SitemapSitemapFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  delta?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  link_count?: InputMaybe<IntFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SitemapSitemapFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SitemapSitemapFiltersInput>>>;
  sitemap_string?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SitemapSitemapInput = {
  delta?: InputMaybe<Scalars['Int']['input']>;
  link_count?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sitemap_string?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Enum_Sitemapsitemap_Type>;
};

export type Software = {
  __typename?: 'Software';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heroVideoSection: ComponentUiHeroVideoSection;
  partnersBlogSection?: Maybe<ComponentSharedPartnersBlogSection>;
  productImageBlock?: Maybe<Array<Maybe<ComponentSharedProductImageBlock>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SoftwareProductImageBlockArgs = {
  filters?: InputMaybe<ComponentSharedProductImageBlockFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SoftwareEntity = {
  __typename?: 'SoftwareEntity';
  attributes?: Maybe<Software>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SoftwareEntityResponse = {
  __typename?: 'SoftwareEntityResponse';
  data?: Maybe<SoftwareEntity>;
};

export type SoftwareInput = {
  heroVideoSection?: InputMaybe<ComponentUiHeroVideoSectionInput>;
  partnersBlogSection?: InputMaybe<ComponentSharedPartnersBlogSectionInput>;
  productImageBlock?: InputMaybe<
    Array<InputMaybe<ComponentSharedProductImageBlockInput>>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type SpecTable = {
  __typename?: 'SpecTable';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heading?: Maybe<ComponentUiHeading>;
  numOfItemsToShow?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  specs?: Maybe<Array<Maybe<ComponentUiSpec>>>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SpecTableSpecsArgs = {
  filters?: InputMaybe<ComponentUiSpecFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SpecTableEntity = {
  __typename?: 'SpecTableEntity';
  attributes?: Maybe<SpecTable>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SpecTableEntityResponse = {
  __typename?: 'SpecTableEntityResponse';
  data?: Maybe<SpecTableEntity>;
};

export type SpecTableEntityResponseCollection = {
  __typename?: 'SpecTableEntityResponseCollection';
  data: Array<SpecTableEntity>;
  meta: ResponseCollectionMeta;
};

export type SpecTableFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SpecTableFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  heading?: InputMaybe<ComponentUiHeadingFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<SpecTableFiltersInput>;
  numOfItemsToShow?: InputMaybe<IntFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SpecTableFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  specs?: InputMaybe<ComponentUiSpecFiltersInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SpecTableInput = {
  heading?: InputMaybe<ComponentUiHeadingInput>;
  numOfItemsToShow?: InputMaybe<Scalars['Int']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  specs?: InputMaybe<Array<InputMaybe<ComponentUiSpecInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type SpecTableRelationResponseCollection = {
  __typename?: 'SpecTableRelationResponseCollection';
  data: Array<SpecTableEntity>;
};

export type SpecsOption = {
  __typename?: 'SpecsOption';
  VehicleInfoContent?: Maybe<ComponentUiVehicleInfoContent>;
  button?: Maybe<ComponentUiButton>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  denseSpecs?: Maybe<Array<Maybe<ComponentSharedDenseSpec>>>;
  gallery?: Maybe<Array<Maybe<ComponentUiImage>>>;
  graph?: Maybe<Array<Maybe<ComponentUiGraph>>>;
  heading?: Maybe<ComponentUiHeading>;
  largeSpec?: Maybe<Array<Maybe<ComponentUiSpec>>>;
  option?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  specTable?: Maybe<SpecTableEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SpecsOptionDenseSpecsArgs = {
  filters?: InputMaybe<ComponentSharedDenseSpecFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SpecsOptionGalleryArgs = {
  filters?: InputMaybe<ComponentUiImageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SpecsOptionGraphArgs = {
  filters?: InputMaybe<ComponentUiGraphFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SpecsOptionLargeSpecArgs = {
  filters?: InputMaybe<ComponentUiSpecFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SpecsOptionEntity = {
  __typename?: 'SpecsOptionEntity';
  attributes?: Maybe<SpecsOption>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SpecsOptionEntityResponse = {
  __typename?: 'SpecsOptionEntityResponse';
  data?: Maybe<SpecsOptionEntity>;
};

export type SpecsOptionEntityResponseCollection = {
  __typename?: 'SpecsOptionEntityResponseCollection';
  data: Array<SpecsOptionEntity>;
  meta: ResponseCollectionMeta;
};

export type SpecsOptionFiltersInput = {
  VehicleInfoContent?: InputMaybe<ComponentUiVehicleInfoContentFiltersInput>;
  and?: InputMaybe<Array<InputMaybe<SpecsOptionFiltersInput>>>;
  button?: InputMaybe<ComponentUiButtonFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  denseSpecs?: InputMaybe<ComponentSharedDenseSpecFiltersInput>;
  gallery?: InputMaybe<ComponentUiImageFiltersInput>;
  graph?: InputMaybe<ComponentUiGraphFiltersInput>;
  heading?: InputMaybe<ComponentUiHeadingFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  largeSpec?: InputMaybe<ComponentUiSpecFiltersInput>;
  not?: InputMaybe<SpecsOptionFiltersInput>;
  option?: InputMaybe<StringFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SpecsOptionFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  specTable?: InputMaybe<SpecTableFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SpecsOptionInput = {
  VehicleInfoContent?: InputMaybe<ComponentUiVehicleInfoContentInput>;
  button?: InputMaybe<ComponentUiButtonInput>;
  denseSpecs?: InputMaybe<Array<InputMaybe<ComponentSharedDenseSpecInput>>>;
  gallery?: InputMaybe<Array<InputMaybe<ComponentUiImageInput>>>;
  graph?: InputMaybe<Array<InputMaybe<ComponentUiGraphInput>>>;
  heading?: InputMaybe<ComponentUiHeadingInput>;
  largeSpec?: InputMaybe<Array<InputMaybe<ComponentUiSpecInput>>>;
  option?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  specTable?: InputMaybe<Scalars['ID']['input']>;
};

export type SpecsOptionRelationResponseCollection = {
  __typename?: 'SpecsOptionRelationResponseCollection';
  data: Array<SpecsOptionEntity>;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  containsi?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  eqi?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nei?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<StringFilterInput>;
  notContains?: InputMaybe<Scalars['String']['input']>;
  notContainsi?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type SupplierPortal = {
  __typename?: 'SupplierPortal';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  policies?: Maybe<Array<Maybe<SupplierPortalPoliciesDynamicZone>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SupplierPortalEntity = {
  __typename?: 'SupplierPortalEntity';
  attributes?: Maybe<SupplierPortal>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SupplierPortalEntityResponse = {
  __typename?: 'SupplierPortalEntityResponse';
  data?: Maybe<SupplierPortalEntity>;
};

export type SupplierPortalInput = {
  policies?: InputMaybe<
    Array<Scalars['SupplierPortalPoliciesDynamicZoneInput']['input']>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type SupplierPortalPoliciesDynamicZone =
  | ComponentPoliciesDevider
  | ComponentPoliciesDownloadLink
  | ComponentPoliciesMainHeading
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | Error;

export type TableRow = {
  __typename?: 'TableRow';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  rows?: Maybe<Array<Maybe<ComponentPoliciesRowItem>>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TableRowRowsArgs = {
  filters?: InputMaybe<ComponentPoliciesRowItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TableRowEntity = {
  __typename?: 'TableRowEntity';
  attributes?: Maybe<TableRow>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type TableRowEntityResponse = {
  __typename?: 'TableRowEntityResponse';
  data?: Maybe<TableRowEntity>;
};

export type TableRowEntityResponseCollection = {
  __typename?: 'TableRowEntityResponseCollection';
  data: Array<TableRowEntity>;
  meta: ResponseCollectionMeta;
};

export type TableRowFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<TableRowFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<TableRowFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<TableRowFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  rows?: InputMaybe<ComponentPoliciesRowItemFiltersInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type TableRowInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  rows?: InputMaybe<Array<InputMaybe<ComponentPoliciesRowItemInput>>>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TableRowRelationResponseCollection = {
  __typename?: 'TableRowRelationResponseCollection';
  data: Array<TableRowEntity>;
};

export type UploadFile = {
  __typename?: 'UploadFile';
  alternativeText?: Maybe<Scalars['String']['output']>;
  blurhash?: Maybe<Scalars['String']['output']>;
  caption?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  ext?: Maybe<Scalars['String']['output']>;
  formats?: Maybe<Scalars['JSON']['output']>;
  hash: Scalars['String']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  mime: Scalars['String']['output'];
  name: Scalars['String']['output'];
  previewUrl?: Maybe<Scalars['String']['output']>;
  provider: Scalars['String']['output'];
  provider_metadata?: Maybe<Scalars['JSON']['output']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  size: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  attributes?: Maybe<UploadFile>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
  alternativeText?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  blurhash?: InputMaybe<StringFilterInput>;
  caption?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  folder?: InputMaybe<UploadFolderFiltersInput>;
  folderPath?: InputMaybe<StringFilterInput>;
  formats?: InputMaybe<JsonFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFileFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JsonFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
};

export type UploadFileInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  blurhash?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  ext?: InputMaybe<Scalars['String']['input']>;
  folder?: InputMaybe<Scalars['ID']['input']>;
  folderPath?: InputMaybe<Scalars['String']['input']>;
  formats?: InputMaybe<Scalars['JSON']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  previewUrl?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  provider_metadata?: InputMaybe<Scalars['JSON']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
};

export type UploadFolder = {
  __typename?: 'UploadFolder';
  children?: Maybe<UploadFolderRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  name: Scalars['String']['output'];
  parent?: Maybe<UploadFolderEntityResponse>;
  path: Scalars['String']['output'];
  pathId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UploadFolderChildrenArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UploadFolderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity';
  attributes?: Maybe<UploadFolder>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  children?: InputMaybe<UploadFolderFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  files?: InputMaybe<UploadFileFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFolderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  parent?: InputMaybe<UploadFolderFiltersInput>;
  path?: InputMaybe<StringFilterInput>;
  pathId?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UploadFolderInput = {
  children?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['ID']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  pathId?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String']['input'];
  password: Scalars['String']['input'];
  provider?: Scalars['String']['input'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']['output']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  role?: Maybe<UsersPermissionsMeRole>;
  username: Scalars['String']['output'];
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  attributes?: Maybe<UsersPermissionsPermission>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsPermissionFiltersInput = {
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};

export type UsersPermissionsRolePermissionsArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsRoleUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  attributes?: Maybe<UsersPermissionsRole>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  provider?: Maybe<Scalars['String']['output']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username: Scalars['String']['output'];
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  attributes?: Maybe<UsersPermissionsUser>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  blocked?: InputMaybe<BooleanFilterInput>;
  confirmationToken?: InputMaybe<StringFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsUserFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  password?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  resetPasswordToken?: InputMaybe<StringFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  username?: InputMaybe<StringFilterInput>;
};

export type UsersPermissionsUserInput = {
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  confirmationToken?: InputMaybe<Scalars['String']['input']>;
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  resetPasswordToken?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['ID']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};

export type WhistleblowingPolicy = {
  __typename?: 'WhistleblowingPolicy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  policies?: Maybe<Array<Maybe<WhistleblowingPolicyPoliciesDynamicZone>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type WhistleblowingPolicyEntity = {
  __typename?: 'WhistleblowingPolicyEntity';
  attributes?: Maybe<WhistleblowingPolicy>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type WhistleblowingPolicyEntityResponse = {
  __typename?: 'WhistleblowingPolicyEntityResponse';
  data?: Maybe<WhistleblowingPolicyEntity>;
};

export type WhistleblowingPolicyInput = {
  policies?: InputMaybe<
    Array<Scalars['WhistleblowingPolicyPoliciesDynamicZoneInput']['input']>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type WhistleblowingPolicyPoliciesDynamicZone =
  | ComponentPoliciesDevider
  | ComponentPoliciesDownloadLink
  | ComponentPoliciesMainHeading
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | Error;

export type BatteryHeroQueryVariables = Exact<{ [key: string]: never }>;

export type BatteryHeroQuery = {
  __typename?: 'Query';
  battery?: {
    __typename?: 'BatteryEntityResponse';
    data?: {
      __typename?: 'BatteryEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Battery';
        heroVideoSection: {
          __typename?: 'ComponentUiHeroVideoSection';
          id: string;
          title: string;
          subtitle?: string | null;
          previewVideoLength?: string | null;
          showPreviewVideo?: boolean | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          heroVideo?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          previewVideo?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        };
      } | null;
    } | null;
  } | null;
};

export type BatteryCardsSectionQueryVariables = Exact<{ [key: string]: never }>;

export type BatteryCardsSectionQuery = {
  __typename?: 'Query';
  battery?: {
    __typename?: 'BatteryEntityResponse';
    data?: {
      __typename?: 'BatteryEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Battery';
        cardsSection: {
          __typename?: 'ComponentSharedSectionCards';
          title: string;
          anchor?: string | null;
          subtitle?: string | null;
          iconCard?: Array<{
            __typename?: 'ComponentUiIconCard';
            id: string;
            title: string;
            description: string;
            icon: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
        };
      } | null;
    } | null;
  } | null;
};

export type BatteryProductImageBlockQueryVariables = Exact<{
  [key: string]: never;
}>;

export type BatteryProductImageBlockQuery = {
  __typename?: 'Query';
  battery?: {
    __typename?: 'BatteryEntityResponse';
    data?: {
      __typename?: 'BatteryEntity';
      attributes?: {
        __typename?: 'Battery';
        productImageBlock?: {
          __typename?: 'ComponentSharedProductImageBlock';
          title?: string | null;
          subtitle?: string | null;
          anchor?: string | null;
          galleryExtraText?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          largeSpec?: Array<{
            __typename?: 'ComponentUiSpec';
            label?: string | null;
            value?: string | null;
          } | null> | null;
          gallery?: Array<{
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          denseSpecs?: Array<{
            __typename?: 'ComponentSharedDenseSpec';
            title?: string | null;
            label?: string | null;
            value?: string | null;
            gapBellow?: boolean | null;
          } | null> | null;
          specTable?: {
            __typename?: 'SpecTableEntityResponse';
            data?: {
              __typename?: 'SpecTableEntity';
              attributes?: {
                __typename?: 'SpecTable';
                title?: string | null;
                numOfItemsToShow?: number | null;
                heading?: {
                  __typename?: 'ComponentUiHeading';
                  title?: string | null;
                  subtitle?: string | null;
                } | null;
                specs?: Array<{
                  __typename?: 'ComponentUiSpec';
                  label?: string | null;
                  value?: string | null;
                } | null> | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type BatteryTwoImagesGridQueryVariables = Exact<{
  [key: string]: never;
}>;

export type BatteryTwoImagesGridQuery = {
  __typename?: 'Query';
  battery?: {
    __typename?: 'BatteryEntityResponse';
    data?: {
      __typename?: 'BatteryEntity';
      attributes?: {
        __typename?: 'Battery';
        twoImagesGrid?: {
          __typename?: 'ComponentSharedTwoImagesGrid';
          title?: string | null;
          anchor?: string | null;
          imagesGridItem?: Array<{
            __typename?: 'ComponentUiCertification';
            title: string;
            description: string;
            tag?: string | null;
            tagColor?: Enum_Componentuicertification_Tagcolor | null;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type BatteryVideoSectionQueryVariables = Exact<{ [key: string]: never }>;

export type BatteryVideoSectionQuery = {
  __typename?: 'Query';
  battery?: {
    __typename?: 'BatteryEntityResponse';
    data?: {
      __typename?: 'BatteryEntity';
      attributes?: {
        __typename?: 'Battery';
        videoSection?: {
          __typename?: 'ComponentSharedVideoSection';
          anchor?: string | null;
          title?: string | null;
          description?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          video?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type BatteryPartnersBlogSectionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type BatteryPartnersBlogSectionQuery = {
  __typename?: 'Query';
  battery?: {
    __typename?: 'BatteryEntityResponse';
    data?: {
      __typename?: 'BatteryEntity';
      attributes?: {
        __typename?: 'Battery';
        partnersBlogSection?: {
          __typename?: 'ComponentSharedPartnersBlogSection';
          title?: string | null;
          anchor?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          logos?: Array<{
            __typename?: 'ComponentUiIcon';
            id: string;
            alt: string;
            svg: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
          caseStudies?: {
            __typename?: 'CaseStudyRelationResponseCollection';
            data: Array<{
              __typename?: 'CaseStudyEntity';
              attributes?: {
                __typename?: 'CaseStudy';
                slug?: string | null;
                preview?: {
                  __typename?: 'ComponentCaseStudyArticlePreview';
                  title: string;
                  date?: string | null;
                  alt: string;
                  tag?: string | null;
                  tagColor?: Enum_Componentcasestudyarticlepreview_Tagcolor | null;
                  img_desktop: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type BatterySeoQueryVariables = Exact<{ [key: string]: never }>;

export type BatterySeoQuery = {
  __typename?: 'Query';
  battery?: {
    __typename?: 'BatteryEntityResponse';
    data?: {
      __typename?: 'BatteryEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Battery';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type BmsHeroQueryVariables = Exact<{ [key: string]: never }>;

export type BmsHeroQuery = {
  __typename?: 'Query';
  bms?: {
    __typename?: 'BmsEntityResponse';
    data?: {
      __typename?: 'BmsEntity';
      attributes?: {
        __typename?: 'Bms';
        heroVideoSection?: {
          __typename?: 'ComponentUiHeroVideoSection';
          id: string;
          title: string;
          subtitle?: string | null;
          previewVideoLength?: string | null;
          showPreviewVideo?: boolean | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          heroVideo?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          previewVideo?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type BmsIconCardsQueryVariables = Exact<{ [key: string]: never }>;

export type BmsIconCardsQuery = {
  __typename?: 'Query';
  bms?: {
    __typename?: 'BmsEntityResponse';
    data?: {
      __typename?: 'BmsEntity';
      attributes?: {
        __typename?: 'Bms';
        cardsSection?: {
          __typename?: 'ComponentSharedSectionCards';
          title: string;
          anchor?: string | null;
          subtitle?: string | null;
          iconCard?: Array<{
            __typename?: 'ComponentUiIconCard';
            id: string;
            title: string;
            description: string;
            icon: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type BmsProductImageBlockQueryVariables = Exact<{
  [key: string]: never;
}>;

export type BmsProductImageBlockQuery = {
  __typename?: 'Query';
  bms?: {
    __typename?: 'BmsEntityResponse';
    data?: {
      __typename?: 'BmsEntity';
      attributes?: {
        __typename?: 'Bms';
        productImageBlock?: {
          __typename?: 'ComponentSharedProductImageBlock';
          title?: string | null;
          subtitle?: string | null;
          anchor?: string | null;
          galleryExtraText?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          largeSpec?: Array<{
            __typename?: 'ComponentUiSpec';
            label?: string | null;
            value?: string | null;
          } | null> | null;
          gallery?: Array<{
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          denseSpecs?: Array<{
            __typename?: 'ComponentSharedDenseSpec';
            title?: string | null;
            label?: string | null;
            value?: string | null;
            gapBellow?: boolean | null;
          } | null> | null;
          specTable?: {
            __typename?: 'SpecTableEntityResponse';
            data?: {
              __typename?: 'SpecTableEntity';
              attributes?: {
                __typename?: 'SpecTable';
                title?: string | null;
                numOfItemsToShow?: number | null;
                heading?: {
                  __typename?: 'ComponentUiHeading';
                  title?: string | null;
                  subtitle?: string | null;
                } | null;
                specs?: Array<{
                  __typename?: 'ComponentUiSpec';
                  label?: string | null;
                  value?: string | null;
                } | null> | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type BmsProductSpecsBlockQueryVariables = Exact<{
  [key: string]: never;
}>;

export type BmsProductSpecsBlockQuery = {
  __typename?: 'Query';
  bms?: {
    __typename?: 'BmsEntityResponse';
    data?: {
      __typename?: 'BmsEntity';
      attributes?: {
        __typename?: 'Bms';
        productSpecsBlock?: {
          __typename?: 'ComponentSharedProductSpecsBlocks';
          title?: string | null;
          subtitle?: string | null;
          anchor?: string | null;
          specs_options?: {
            __typename?: 'SpecsOptionRelationResponseCollection';
            data: Array<{
              __typename?: 'SpecsOptionEntity';
              attributes?: {
                __typename?: 'SpecsOption';
                option?: string | null;
                heading?: {
                  __typename?: 'ComponentUiHeading';
                  title?: string | null;
                  subtitle?: string | null;
                } | null;
                gallery?: Array<{
                  __typename?: 'ComponentUiImage';
                  id: string;
                  alt: string;
                  img_desktop: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
                largeSpec?: Array<{
                  __typename?: 'ComponentUiSpec';
                  label?: string | null;
                  value?: string | null;
                } | null> | null;
                denseSpecs?: Array<{
                  __typename?: 'ComponentSharedDenseSpec';
                  title?: string | null;
                  label?: string | null;
                  value?: string | null;
                  gapBellow?: boolean | null;
                } | null> | null;
                specTable?: {
                  __typename?: 'SpecTableEntityResponse';
                  data?: {
                    __typename?: 'SpecTableEntity';
                    attributes?: {
                      __typename?: 'SpecTable';
                      title?: string | null;
                      numOfItemsToShow?: number | null;
                      heading?: {
                        __typename?: 'ComponentUiHeading';
                        title?: string | null;
                        subtitle?: string | null;
                      } | null;
                      specs?: Array<{
                        __typename?: 'ComponentUiSpec';
                        label?: string | null;
                        value?: string | null;
                      } | null> | null;
                    } | null;
                  } | null;
                } | null;
                VehicleInfoContent?: {
                  __typename?: 'ComponentUiVehicleInfoContent';
                  title?: string | null;
                  text?: string | null;
                  carInfoItems?: Array<{
                    __typename?: 'ComponentUiCarInfoItems';
                    title?: string | null;
                    icon?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
                graph?: Array<{
                  __typename?: 'ComponentUiGraph';
                  label?: string | null;
                  image: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                } | null> | null;
                button?: {
                  __typename?: 'ComponentUiButton';
                  id: string;
                  label: string;
                  path?: string | null;
                  openInNewTab?: boolean | null;
                  file?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type BmsTwoImagesGridQueryVariables = Exact<{ [key: string]: never }>;

export type BmsTwoImagesGridQuery = {
  __typename?: 'Query';
  bms?: {
    __typename?: 'BmsEntityResponse';
    data?: {
      __typename?: 'BmsEntity';
      attributes?: {
        __typename?: 'Bms';
        twoImagesGrid?: Array<{
          __typename?: 'ComponentSharedTwoImagesGrid';
          title?: string | null;
          anchor?: string | null;
          imagesGridItem?: Array<{
            __typename?: 'ComponentUiCertification';
            title: string;
            description: string;
            tag?: string | null;
            tagColor?: Enum_Componentuicertification_Tagcolor | null;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type BmsLargeImageSectionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type BmsLargeImageSectionQuery = {
  __typename?: 'Query';
  bms?: {
    __typename?: 'BmsEntityResponse';
    data?: {
      __typename?: 'BmsEntity';
      attributes?: {
        __typename?: 'Bms';
        largeImageSection?: {
          __typename?: 'ComponentSharedLargeImageSection';
          anchor?: string | null;
          title?: string | null;
          description?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          image?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type BmsPartnersBlogSectionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type BmsPartnersBlogSectionQuery = {
  __typename?: 'Query';
  bms?: {
    __typename?: 'BmsEntityResponse';
    data?: {
      __typename?: 'BmsEntity';
      attributes?: {
        __typename?: 'Bms';
        partnersBlogSection?: {
          __typename?: 'ComponentSharedPartnersBlogSection';
          title?: string | null;
          anchor?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          logos?: Array<{
            __typename?: 'ComponentUiIcon';
            id: string;
            alt: string;
            svg: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
          caseStudies?: {
            __typename?: 'CaseStudyRelationResponseCollection';
            data: Array<{
              __typename?: 'CaseStudyEntity';
              attributes?: {
                __typename?: 'CaseStudy';
                slug?: string | null;
                preview?: {
                  __typename?: 'ComponentCaseStudyArticlePreview';
                  title: string;
                  date?: string | null;
                  alt: string;
                  tag?: string | null;
                  tagColor?: Enum_Componentcasestudyarticlepreview_Tagcolor | null;
                  img_desktop: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type BmsSeoQueryVariables = Exact<{ [key: string]: never }>;

export type BmsSeoQuery = {
  __typename?: 'Query';
  bms?: {
    __typename?: 'BmsEntityResponse';
    data?: {
      __typename?: 'BmsEntity';
      attributes?: {
        __typename?: 'Bms';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CaseStudyPreviewFragment = {
  __typename?: 'ComponentCaseStudyArticlePreview';
  title: string;
  date?: string | null;
  alt: string;
  tag?: string | null;
  tagColor?: Enum_Componentcasestudyarticlepreview_Tagcolor | null;
  img_desktop: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  };
  img_mobile?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CaseStudyFragment = {
  __typename?: 'CaseStudy';
  slug?: string | null;
  preview?: {
    __typename?: 'ComponentCaseStudyArticlePreview';
    title: string;
    date?: string | null;
    alt: string;
    tag?: string | null;
    tagColor?: Enum_Componentcasestudyarticlepreview_Tagcolor | null;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  hero?: {
    __typename?: 'ComponentUiHeroImageSection';
    id: string;
    title: string;
    subtitle?: string | null;
    button?: {
      __typename?: 'ComponentUiButton';
      id: string;
      label: string;
      path?: string | null;
      openInNewTab?: boolean | null;
      file?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    heroImage?: {
      __typename?: 'ComponentUiImage';
      id: string;
      alt: string;
      img_desktop: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      };
      img_mobile?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  content?: Array<
    | {
        __typename?: 'ComponentCaseStudyGallery';
        images?: Array<{
          __typename?: 'ComponentUiImage';
          id: string;
          alt: string;
          img_desktop: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          };
          img_mobile?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
      }
    | { __typename?: 'ComponentCaseStudyTextContent'; text?: any | null }
    | { __typename?: 'Error' }
    | null
  > | null;
  partnersBlogSection?: {
    __typename?: 'ComponentSharedPartnersBlogSection';
    title?: string | null;
    anchor?: string | null;
    button?: {
      __typename?: 'ComponentUiButton';
      id: string;
      label: string;
      path?: string | null;
      openInNewTab?: boolean | null;
      file?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    logos?: Array<{
      __typename?: 'ComponentUiIcon';
      id: string;
      alt: string;
      svg: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      };
    } | null> | null;
    caseStudies?: {
      __typename?: 'CaseStudyRelationResponseCollection';
      data: Array<{
        __typename?: 'CaseStudyEntity';
        attributes?: {
          __typename?: 'CaseStudy';
          slug?: string | null;
          preview?: {
            __typename?: 'ComponentCaseStudyArticlePreview';
            title: string;
            date?: string | null;
            alt: string;
            tag?: string | null;
            tagColor?: Enum_Componentcasestudyarticlepreview_Tagcolor | null;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      }>;
    } | null;
  } | null;
  seo?: {
    __typename?: 'ComponentSharedSeo';
    id: string;
    canonicalURL?: string | null;
    keywords?: string | null;
    metaDescription: string;
    metaRobots?: string | null;
    metaTitle: string;
    metaViewport?: string | null;
    structuredData?: any | null;
    metaImage?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    metaSocial?: Array<{
      __typename?: 'ComponentSharedMetaSocial';
      id: string;
      title: string;
      description: string;
      socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
      image?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type CaseStudiesQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CaseStudiesQuery = {
  __typename?: 'Query';
  caseStudies?: {
    __typename?: 'CaseStudyEntityResponseCollection';
    data: Array<{
      __typename?: 'CaseStudyEntity';
      attributes?: {
        __typename?: 'CaseStudy';
        slug?: string | null;
        preview?: {
          __typename?: 'ComponentCaseStudyArticlePreview';
          title: string;
          date?: string | null;
          alt: string;
          tag?: string | null;
          tagColor?: Enum_Componentcasestudyarticlepreview_Tagcolor | null;
          img_desktop: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          };
          img_mobile?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        hero?: {
          __typename?: 'ComponentUiHeroImageSection';
          id: string;
          title: string;
          subtitle?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          heroImage?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        content?: Array<
          | {
              __typename?: 'ComponentCaseStudyGallery';
              images?: Array<{
                __typename?: 'ComponentUiImage';
                id: string;
                alt: string;
                img_desktop: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    id?: string | null;
                    attributes?: {
                      __typename?: 'UploadFile';
                      url: string;
                      alternativeText?: string | null;
                      blurhash?: string | null;
                    } | null;
                  } | null;
                };
                img_mobile?: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    id?: string | null;
                    attributes?: {
                      __typename?: 'UploadFile';
                      url: string;
                      alternativeText?: string | null;
                      blurhash?: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
            }
          | { __typename?: 'ComponentCaseStudyTextContent'; text?: any | null }
          | { __typename?: 'Error' }
          | null
        > | null;
        partnersBlogSection?: {
          __typename?: 'ComponentSharedPartnersBlogSection';
          title?: string | null;
          anchor?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          logos?: Array<{
            __typename?: 'ComponentUiIcon';
            id: string;
            alt: string;
            svg: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
          caseStudies?: {
            __typename?: 'CaseStudyRelationResponseCollection';
            data: Array<{
              __typename?: 'CaseStudyEntity';
              attributes?: {
                __typename?: 'CaseStudy';
                slug?: string | null;
                preview?: {
                  __typename?: 'ComponentCaseStudyArticlePreview';
                  title: string;
                  date?: string | null;
                  alt: string;
                  tag?: string | null;
                  tagColor?: Enum_Componentcasestudyarticlepreview_Tagcolor | null;
                  img_desktop: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        } | null;
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type UploadFileFragment = {
  __typename?: 'UploadFileEntityResponse';
  data?: {
    __typename?: 'UploadFileEntity';
    id?: string | null;
    attributes?: {
      __typename?: 'UploadFile';
      url: string;
      alternativeText?: string | null;
      blurhash?: string | null;
    } | null;
  } | null;
};

export type UploadDocumentFragment = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<{
    __typename?: 'UploadFileEntity';
    id?: string | null;
    attributes?: {
      __typename?: 'UploadFile';
      url: string;
      size: number;
      blurhash?: string | null;
    } | null;
  }>;
};

export type ButtonFragment = {
  __typename?: 'ComponentUiButton';
  id: string;
  label: string;
  path?: string | null;
  openInNewTab?: boolean | null;
  file?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ImageFragment = {
  __typename?: 'ComponentUiImage';
  id: string;
  alt: string;
  img_desktop: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  };
  img_mobile?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type IconFragment = {
  __typename?: 'ComponentUiIcon';
  id: string;
  alt: string;
  svg: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  };
};

export type IconCardFragment = {
  __typename?: 'ComponentUiIconCard';
  id: string;
  title: string;
  description: string;
  icon: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  };
};

export type ImageCardLinkFragment = {
  __typename?: 'ComponentUiImageCardLink';
  title?: string | null;
  description?: string | null;
  link_label?: string | null;
  link_path?: string | null;
  alt?: string | null;
  tag?: string | null;
  img_desktop: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  };
  img_mobil?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type SpecTableFragment = {
  __typename?: 'SpecTable';
  title?: string | null;
  numOfItemsToShow?: number | null;
  heading?: {
    __typename?: 'ComponentUiHeading';
    title?: string | null;
    subtitle?: string | null;
  } | null;
  specs?: Array<{
    __typename?: 'ComponentUiSpec';
    label?: string | null;
    value?: string | null;
  } | null> | null;
};

export type SpecFragment = {
  __typename?: 'ComponentUiSpec';
  label?: string | null;
  value?: string | null;
};

export type GraphFragment = {
  __typename?: 'ComponentUiGraph';
  id: string;
  label?: string | null;
  image: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  };
};

export type HeroVideoSectionFragment = {
  __typename?: 'ComponentUiHeroVideoSection';
  id: string;
  title: string;
  subtitle?: string | null;
  previewVideoLength?: string | null;
  showPreviewVideo?: boolean | null;
  button?: {
    __typename?: 'ComponentUiButton';
    id: string;
    label: string;
    path?: string | null;
    openInNewTab?: boolean | null;
    file?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  heroVideo?: {
    __typename?: 'ComponentUiVideo';
    poster_alt?: string | null;
    poster: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    mobilePoster?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    video?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  previewVideo?: {
    __typename?: 'ComponentUiVideo';
    poster_alt?: string | null;
    poster: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    mobilePoster?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    video?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type HeroImageSectionFragment = {
  __typename?: 'ComponentUiHeroImageSection';
  id: string;
  title: string;
  subtitle?: string | null;
  button?: {
    __typename?: 'ComponentUiButton';
    id: string;
    label: string;
    path?: string | null;
    openInNewTab?: boolean | null;
    file?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  heroImage?: {
    __typename?: 'ComponentUiImage';
    id: string;
    alt: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type VideoFragment = {
  __typename?: 'ComponentUiVideo';
  poster_alt?: string | null;
  poster: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  };
  mobilePoster?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
  video?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ImpressumQueryVariables = Exact<{ [key: string]: never }>;

export type ImpressumQuery = {
  __typename?: 'Query';
  impressum?: {
    __typename?: 'ImpressumEntityResponse';
    data?: {
      __typename?: 'ImpressumEntity';
      attributes?: {
        __typename?: 'Impressum';
        title?: string | null;
        text?: any | null;
        lastUpdatedText?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CompanyHeroQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyHeroQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Company';
        heroVideoSection?: {
          __typename?: 'ComponentUiHeroVideoSection';
          id: string;
          title: string;
          subtitle?: string | null;
          previewVideoLength?: string | null;
          showPreviewVideo?: boolean | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          heroVideo?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          previewVideo?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CompanySliderImageSectionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CompanySliderImageSectionQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      attributes?: {
        __typename?: 'Company';
        sliderImageSection?: {
          __typename?: 'ComponentSharedSliderImageSection';
          id: string;
          anchor?: string | null;
          title?: string | null;
          titleMobile?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path?: string | null;
          } | null;
          imageCardLink?: Array<{
            __typename?: 'ComponentUiImageCardLink';
            title?: string | null;
            description?: string | null;
            link_label?: string | null;
            link_path?: string | null;
            alt?: string | null;
            tag?: string | null;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobil?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CompanyValuesQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyValuesQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      attributes?: {
        __typename?: 'Company';
        values?: {
          __typename?: 'ComponentSharedValues';
          title?: string | null;
          anchor?: string | null;
          cards?: Array<{
            __typename?: 'ComponentUiIconCard';
            id: string;
            title: string;
            description: string;
            icon: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CompanyCampusQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyCampusQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      attributes?: {
        __typename?: 'Company';
        CampusAnchor?: string | null;
        Campus?: Array<{
          __typename?: 'ComponentUiCampus';
          id: string;
          Text?: string | null;
          Title?: string | null;
          image?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          spec?: Array<{
            __typename?: 'ComponentUiSpec';
            label?: string | null;
            value?: string | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type CompanyMapSectionQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyMapSectionQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      attributes?: {
        __typename?: 'Company';
        mapSection?: {
          __typename?: 'ComponentSharedMapSection';
          title: string;
          anchor?: string | null;
          leftMapTitle: string;
          rightMapTitle: string;
          leftMapImage: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          };
          leftMapImageMobile?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          rightMapImage: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          };
          rightMapImageMobile: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          };
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CompanyTeamQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyTeamQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      attributes?: {
        __typename?: 'Company';
        teamCards?: {
          __typename?: 'ComponentSharedTeamCardsSection';
          anchor?: string | null;
          title?: string | null;
          teammates?: Array<{
            __typename?: 'ComponentUiTeamMate';
            name: string;
            position?: string | null;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CompanyLargeImageQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyLargeImageQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      attributes?: {
        __typename?: 'Company';
        largeImageSection?: {
          __typename?: 'ComponentSharedLargeImageSection';
          anchor?: string | null;
          title?: string | null;
          description?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          image?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CompanyNewsroomQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyNewsroomQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      attributes?: {
        __typename?: 'Company';
        newsroom?: {
          __typename?: 'ComponentSharedNewsroom';
          title?: string | null;
          anchor?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CompanySeoQueryVariables = Exact<{ [key: string]: never }>;

export type CompanySeoQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Company';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type EcusHeroQueryVariables = Exact<{ [key: string]: never }>;

export type EcusHeroQuery = {
  __typename?: 'Query';
  ecus?: {
    __typename?: 'EcusEntityResponse';
    data?: {
      __typename?: 'EcusEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Ecus';
        heroVideoSection: {
          __typename?: 'ComponentUiHeroVideoSection';
          id: string;
          title: string;
          subtitle?: string | null;
          previewVideoLength?: string | null;
          showPreviewVideo?: boolean | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          heroVideo?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          previewVideo?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        };
      } | null;
    } | null;
  } | null;
};

export type EcusCardsSectionQueryVariables = Exact<{ [key: string]: never }>;

export type EcusCardsSectionQuery = {
  __typename?: 'Query';
  ecus?: {
    __typename?: 'EcusEntityResponse';
    data?: {
      __typename?: 'EcusEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Ecus';
        cardsSection?: {
          __typename?: 'ComponentSharedSectionCards';
          title: string;
          anchor?: string | null;
          subtitle?: string | null;
          iconCard?: Array<{
            __typename?: 'ComponentUiIconCard';
            id: string;
            title: string;
            description: string;
            icon: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type EcusProductImageBlockQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EcusProductImageBlockQuery = {
  __typename?: 'Query';
  ecus?: {
    __typename?: 'EcusEntityResponse';
    data?: {
      __typename?: 'EcusEntity';
      attributes?: {
        __typename?: 'Ecus';
        productImageBlock?: Array<{
          __typename?: 'ComponentSharedProductImageBlock';
          title?: string | null;
          subtitle?: string | null;
          anchor?: string | null;
          galleryExtraText?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          largeSpec?: Array<{
            __typename?: 'ComponentUiSpec';
            label?: string | null;
            value?: string | null;
          } | null> | null;
          gallery?: Array<{
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          denseSpecs?: Array<{
            __typename?: 'ComponentSharedDenseSpec';
            title?: string | null;
            label?: string | null;
            value?: string | null;
            gapBellow?: boolean | null;
          } | null> | null;
          specTable?: {
            __typename?: 'SpecTableEntityResponse';
            data?: {
              __typename?: 'SpecTableEntity';
              attributes?: {
                __typename?: 'SpecTable';
                title?: string | null;
                numOfItemsToShow?: number | null;
                heading?: {
                  __typename?: 'ComponentUiHeading';
                  title?: string | null;
                  subtitle?: string | null;
                } | null;
                specs?: Array<{
                  __typename?: 'ComponentUiSpec';
                  label?: string | null;
                  value?: string | null;
                } | null> | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type EcusTwoImagesGridQueryVariables = Exact<{ [key: string]: never }>;

export type EcusTwoImagesGridQuery = {
  __typename?: 'Query';
  ecus?: {
    __typename?: 'EcusEntityResponse';
    data?: {
      __typename?: 'EcusEntity';
      attributes?: {
        __typename?: 'Ecus';
        twoImagesGrid?: {
          __typename?: 'ComponentSharedTwoImagesGrid';
          title?: string | null;
          anchor?: string | null;
          imagesGridItem?: Array<{
            __typename?: 'ComponentUiCertification';
            title: string;
            description: string;
            tag?: string | null;
            tagColor?: Enum_Componentuicertification_Tagcolor | null;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type EcusSliderImageSectionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EcusSliderImageSectionQuery = {
  __typename?: 'Query';
  ecus?: {
    __typename?: 'EcusEntityResponse';
    data?: {
      __typename?: 'EcusEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Ecus';
        sliderImageSection?: {
          __typename?: 'ComponentSharedSliderImageSection';
          id: string;
          anchor?: string | null;
          title?: string | null;
          titleMobile?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path?: string | null;
          } | null;
          imageCardLink?: Array<{
            __typename?: 'ComponentUiImageCardLink';
            title?: string | null;
            description?: string | null;
            link_label?: string | null;
            link_path?: string | null;
            alt?: string | null;
            tag?: string | null;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobil?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type EcusPartnersBlogSectionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EcusPartnersBlogSectionQuery = {
  __typename?: 'Query';
  ecus?: {
    __typename?: 'EcusEntityResponse';
    data?: {
      __typename?: 'EcusEntity';
      attributes?: {
        __typename?: 'Ecus';
        partnersBlogSection?: {
          __typename?: 'ComponentSharedPartnersBlogSection';
          title?: string | null;
          anchor?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          logos?: Array<{
            __typename?: 'ComponentUiIcon';
            id: string;
            alt: string;
            svg: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
          caseStudies?: {
            __typename?: 'CaseStudyRelationResponseCollection';
            data: Array<{
              __typename?: 'CaseStudyEntity';
              attributes?: {
                __typename?: 'CaseStudy';
                slug?: string | null;
                preview?: {
                  __typename?: 'ComponentCaseStudyArticlePreview';
                  title: string;
                  date?: string | null;
                  alt: string;
                  tag?: string | null;
                  tagColor?: Enum_Componentcasestudyarticlepreview_Tagcolor | null;
                  img_desktop: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type EcusSeoQueryVariables = Exact<{ [key: string]: never }>;

export type EcusSeoQuery = {
  __typename?: 'Query';
  ecus?: {
    __typename?: 'EcusEntityResponse';
    data?: {
      __typename?: 'EcusEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Ecus';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type SendContactFormMutationVariables = Exact<{
  data: ContactFormDataInput;
}>;

export type SendContactFormMutation = {
  __typename?: 'Mutation';
  sendContactForm?: {
    __typename?: 'ContactFomResponse';
    success?: boolean | null;
  } | null;
};

export type HomeHeroQueryVariables = Exact<{ [key: string]: never }>;

export type HomeHeroQuery = {
  __typename?: 'Query';
  home?: {
    __typename?: 'HomeEntityResponse';
    data?: {
      __typename?: 'HomeEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Home';
        heroVideoSection: {
          __typename?: 'ComponentUiHeroVideoSection';
          id: string;
          title: string;
          subtitle?: string | null;
          previewVideoLength?: string | null;
          showPreviewVideo?: boolean | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          heroVideo?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          previewVideo?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        };
      } | null;
    } | null;
  } | null;
};

export type ImageCardsWithLinkFragment = {
  __typename?: 'ComponentHomepageImageCardsWIthLink';
  id: string;
  anchor?: string | null;
  imageCardLink?: Array<{
    __typename?: 'ComponentUiImageCardLink';
    title?: string | null;
    description?: string | null;
    link_label?: string | null;
    link_path?: string | null;
    alt?: string | null;
    tag?: string | null;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobil?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type HomeCardsWithLinkQueryVariables = Exact<{ [key: string]: never }>;

export type HomeCardsWithLinkQuery = {
  __typename?: 'Query';
  home?: {
    __typename?: 'HomeEntityResponse';
    data?: {
      __typename?: 'HomeEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Home';
        imageCardsWithLink?: {
          __typename?: 'ComponentHomepageImageCardsWIthLink';
          id: string;
          anchor?: string | null;
          imageCardLink?: Array<{
            __typename?: 'ComponentUiImageCardLink';
            title?: string | null;
            description?: string | null;
            link_label?: string | null;
            link_path?: string | null;
            alt?: string | null;
            tag?: string | null;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobil?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type HomeLargeImageQueryVariables = Exact<{ [key: string]: never }>;

export type HomeLargeImageQuery = {
  __typename?: 'Query';
  home?: {
    __typename?: 'HomeEntityResponse';
    data?: {
      __typename?: 'HomeEntity';
      attributes?: {
        __typename?: 'Home';
        largeImageSection?: {
          __typename?: 'ComponentSharedLargeImageSection';
          anchor?: string | null;
          title?: string | null;
          description?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          image?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type HomePartnersBlogSectionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type HomePartnersBlogSectionQuery = {
  __typename?: 'Query';
  home?: {
    __typename?: 'HomeEntityResponse';
    data?: {
      __typename?: 'HomeEntity';
      attributes?: {
        __typename?: 'Home';
        partnersBlogSection?: {
          __typename?: 'ComponentSharedPartnersBlogSection';
          title?: string | null;
          anchor?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          logos?: Array<{
            __typename?: 'ComponentUiIcon';
            id: string;
            alt: string;
            svg: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
          caseStudies?: {
            __typename?: 'CaseStudyRelationResponseCollection';
            data: Array<{
              __typename?: 'CaseStudyEntity';
              attributes?: {
                __typename?: 'CaseStudy';
                slug?: string | null;
                preview?: {
                  __typename?: 'ComponentCaseStudyArticlePreview';
                  title: string;
                  date?: string | null;
                  alt: string;
                  tag?: string | null;
                  tagColor?: Enum_Componentcasestudyarticlepreview_Tagcolor | null;
                  img_desktop: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type HomeSeoQueryVariables = Exact<{ [key: string]: never }>;

export type HomeSeoQuery = {
  __typename?: 'Query';
  home?: {
    __typename?: 'HomeEntityResponse';
    data?: {
      __typename?: 'HomeEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Home';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type FooterLinkFragment = {
  __typename?: 'ComponentFooterLink';
  label: string;
  path?: string | null;
  openInNewTab?: boolean | null;
  file?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type NavLinkFragment = {
  __typename?: 'ComponentNavigationLink';
  label: string;
  path: string;
  openInNewTab?: boolean | null;
};

export type OpenMenuColumnsFragment = {
  __typename?: 'ComponentNavigationOpenMenuColumn';
  label?: string | null;
  link?: Array<{
    __typename?: 'ComponentUiButton';
    id: string;
    label: string;
    path?: string | null;
    openInNewTab?: boolean | null;
    file?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
  socials?: {
    __typename?: 'ComponentNavigationSocials';
    instagram?: string | null;
    linkedIn?: string | null;
    tiktok?: string | null;
    youtube?: string | null;
  } | null;
};

export type FooterQueryVariables = Exact<{ [key: string]: never }>;

export type FooterQuery = {
  __typename?: 'Query';
  footer?: {
    __typename?: 'FooterEntityResponse';
    data?: {
      __typename?: 'FooterEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Footer';
        column?: Array<{
          __typename?: 'ComponentFooterColumn';
          label?: string | null;
          link?: Array<{
            __typename?: 'ComponentFooterLink';
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null> | null;
        policies?: {
          __typename?: 'ComponentFooterPolicies';
          label?: string | null;
          link?: Array<{
            __typename?: 'ComponentFooterLink';
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        socials?: {
          __typename?: 'ComponentFooterSocials';
          label?: string | null;
          instagram?: string | null;
          linkedin?: string | null;
          tiktok?: string | null;
          youtube?: string | null;
        } | null;
        contact?: {
          __typename?: 'ComponentFooterContact';
          title?: string | null;
          address?: {
            __typename?: 'ComponentFooterLink';
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          email?: {
            __typename?: 'ComponentFooterLink';
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type NavigationQueryVariables = Exact<{ [key: string]: never }>;

export type NavigationQuery = {
  __typename?: 'Query';
  navigation?: {
    __typename?: 'NavigationEntityResponse';
    data?: {
      __typename?: 'NavigationEntity';
      attributes?: {
        __typename?: 'Navigation';
        closedMenuLinks?: Array<{
          __typename?: 'ComponentNavigationLink';
          label: string;
          path: string;
          openInNewTab?: boolean | null;
        } | null> | null;
        openMenuColumns?: Array<{
          __typename?: 'ComponentNavigationOpenMenuColumn';
          label?: string | null;
          link?: Array<{
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          socials?: {
            __typename?: 'ComponentNavigationSocials';
            instagram?: string | null;
            linkedIn?: string | null;
            tiktok?: string | null;
            youtube?: string | null;
          } | null;
        } | null> | null;
        companyContactInfo?: {
          __typename?: 'ComponentNavigationContactInfo';
          name?: string | null;
          address?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type FormQueryVariables = Exact<{ [key: string]: never }>;

export type FormQuery = {
  __typename?: 'Query';
  getInTouch?: {
    __typename?: 'GetInTouchEntityResponse';
    data?: {
      __typename?: 'GetInTouchEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'GetInTouch';
        anchor?: string | null;
        title?: string | null;
        first_line?: string | null;
        second_line?: string | null;
        options?: Array<{
          __typename?: 'ComponentUiSpec';
          label?: string | null;
          value?: string | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

type PolicyFields_ComponentPoliciesDevider_Fragment = {
  __typename?: 'ComponentPoliciesDevider';
  Devider?: boolean | null;
};

type PolicyFields_ComponentPoliciesDownloadLink_Fragment = {
  __typename?: 'ComponentPoliciesDownloadLink';
  label?: string | null;
  link?: string | null;
};

type PolicyFields_ComponentPoliciesMainHeading_Fragment = {
  __typename?: 'ComponentPoliciesMainHeading';
  text?: any | null;
};

type PolicyFields_ComponentPoliciesTableTextContent_Fragment = {
  __typename?: 'ComponentPoliciesTableTextContent';
  richText?: any | null;
  tableHead?: Array<{
    __typename?: 'ComponentPoliciesTableHeading';
    headingItem?: string | null;
  } | null> | null;
  tableRow?: {
    __typename?: 'ComponentPoliciesTableRow';
    table_rows?: {
      __typename?: 'TableRowRelationResponseCollection';
      data: Array<{
        __typename?: 'TableRowEntity';
        attributes?: {
          __typename?: 'TableRow';
          rows?: Array<{
            __typename?: 'ComponentPoliciesRowItem';
            item?: string | null;
          } | null> | null;
        } | null;
      }>;
    } | null;
  } | null;
};

type PolicyFields_ComponentPoliciesTitleTextContent_Fragment = {
  __typename?: 'ComponentPoliciesTitleTextContent';
  text?: any | null;
};

type PolicyFields_Error_Fragment = { __typename?: 'Error' };

export type PolicyFieldsFragment =
  | PolicyFields_ComponentPoliciesDevider_Fragment
  | PolicyFields_ComponentPoliciesDownloadLink_Fragment
  | PolicyFields_ComponentPoliciesMainHeading_Fragment
  | PolicyFields_ComponentPoliciesTableTextContent_Fragment
  | PolicyFields_ComponentPoliciesTitleTextContent_Fragment
  | PolicyFields_Error_Fragment;

export type PrivacyPolicyQueryVariables = Exact<{ [key: string]: never }>;

export type PrivacyPolicyQuery = {
  __typename?: 'Query';
  privacyPolicy?: {
    __typename?: 'PrivacyPolicyEntityResponse';
    data?: {
      __typename?: 'PrivacyPolicyEntity';
      attributes?: {
        __typename?: 'PrivacyPolicy';
        policies?: Array<
          | {
              __typename?: 'ComponentPoliciesDevider';
              Devider?: boolean | null;
            }
          | {
              __typename?: 'ComponentPoliciesDownloadLink';
              label?: string | null;
              link?: string | null;
            }
          | { __typename?: 'ComponentPoliciesMainHeading'; text?: any | null }
          | {
              __typename?: 'ComponentPoliciesTableTextContent';
              richText?: any | null;
              tableHead?: Array<{
                __typename?: 'ComponentPoliciesTableHeading';
                headingItem?: string | null;
              } | null> | null;
              tableRow?: {
                __typename?: 'ComponentPoliciesTableRow';
                table_rows?: {
                  __typename?: 'TableRowRelationResponseCollection';
                  data: Array<{
                    __typename?: 'TableRowEntity';
                    attributes?: {
                      __typename?: 'TableRow';
                      rows?: Array<{
                        __typename?: 'ComponentPoliciesRowItem';
                        item?: string | null;
                      } | null> | null;
                    } | null;
                  }>;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentPoliciesTitleTextContent';
              text?: any | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type LegalNoticeQueryVariables = Exact<{ [key: string]: never }>;

export type LegalNoticeQuery = {
  __typename?: 'Query';
  legalNotice?: {
    __typename?: 'LegalNoticeEntityResponse';
    data?: {
      __typename?: 'LegalNoticeEntity';
      attributes?: {
        __typename?: 'LegalNotice';
        legalNotice?: Array<
          | {
              __typename?: 'ComponentPoliciesDevider';
              Devider?: boolean | null;
            }
          | {
              __typename?: 'ComponentPoliciesDownloadLink';
              label?: string | null;
              link?: string | null;
            }
          | { __typename?: 'ComponentPoliciesMainHeading'; text?: any | null }
          | {
              __typename?: 'ComponentPoliciesTableTextContent';
              richText?: any | null;
              tableHead?: Array<{
                __typename?: 'ComponentPoliciesTableHeading';
                headingItem?: string | null;
              } | null> | null;
              tableRow?: {
                __typename?: 'ComponentPoliciesTableRow';
                table_rows?: {
                  __typename?: 'TableRowRelationResponseCollection';
                  data: Array<{
                    __typename?: 'TableRowEntity';
                    attributes?: {
                      __typename?: 'TableRow';
                      rows?: Array<{
                        __typename?: 'ComponentPoliciesRowItem';
                        item?: string | null;
                      } | null> | null;
                    } | null;
                  }>;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentPoliciesTitleTextContent';
              text?: any | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type MlBattProtEuProjectQueryVariables = Exact<{ [key: string]: never }>;

export type MlBattProtEuProjectQuery = {
  __typename?: 'Query';
  mlBattProtEuProject?: {
    __typename?: 'MlBattProtEuProjectEntityResponse';
    data?: {
      __typename?: 'MlBattProtEuProjectEntity';
      attributes?: {
        __typename?: 'MlBattProtEuProject';
        contents?: Array<
          | {
              __typename?: 'ComponentPoliciesDevider';
              Devider?: boolean | null;
            }
          | {
              __typename?: 'ComponentPoliciesDownloadLink';
              label?: string | null;
              link?: string | null;
            }
          | { __typename?: 'ComponentPoliciesMainHeading'; text?: any | null }
          | {
              __typename?: 'ComponentPoliciesTitleTextContent';
              text?: any | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
        logos?: Array<{
          __typename?: 'ComponentUiImage';
          id: string;
          alt: string;
          img_desktop: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          };
          img_mobile?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CookiePolicyQueryVariables = Exact<{ [key: string]: never }>;

export type CookiePolicyQuery = {
  __typename?: 'Query';
  cookiePolicy?: {
    __typename?: 'CookiePolicyEntityResponse';
    data?: {
      __typename?: 'CookiePolicyEntity';
      attributes?: {
        __typename?: 'CookiePolicy';
        cookiePolicy?: Array<
          | {
              __typename?: 'ComponentPoliciesDevider';
              Devider?: boolean | null;
            }
          | {
              __typename?: 'ComponentPoliciesDownloadLink';
              label?: string | null;
              link?: string | null;
            }
          | { __typename?: 'ComponentPoliciesMainHeading'; text?: any | null }
          | {
              __typename?: 'ComponentPoliciesTableTextContent';
              richText?: any | null;
              tableHead?: Array<{
                __typename?: 'ComponentPoliciesTableHeading';
                headingItem?: string | null;
              } | null> | null;
              tableRow?: {
                __typename?: 'ComponentPoliciesTableRow';
                table_rows?: {
                  __typename?: 'TableRowRelationResponseCollection';
                  data: Array<{
                    __typename?: 'TableRowEntity';
                    attributes?: {
                      __typename?: 'TableRow';
                      rows?: Array<{
                        __typename?: 'ComponentPoliciesRowItem';
                        item?: string | null;
                      } | null> | null;
                    } | null;
                  }>;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentPoliciesTitleTextContent';
              text?: any | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type SupplierPortalQueryVariables = Exact<{ [key: string]: never }>;

export type SupplierPortalQuery = {
  __typename?: 'Query';
  supplierPortal?: {
    __typename?: 'SupplierPortalEntityResponse';
    data?: {
      __typename?: 'SupplierPortalEntity';
      attributes?: {
        __typename?: 'SupplierPortal';
        policies?: Array<
          | {
              __typename?: 'ComponentPoliciesDevider';
              Devider?: boolean | null;
            }
          | {
              __typename?: 'ComponentPoliciesDownloadLink';
              label?: string | null;
              link?: string | null;
            }
          | { __typename?: 'ComponentPoliciesMainHeading'; text?: any | null }
          | {
              __typename?: 'ComponentPoliciesTableTextContent';
              richText?: any | null;
              tableHead?: Array<{
                __typename?: 'ComponentPoliciesTableHeading';
                headingItem?: string | null;
              } | null> | null;
              tableRow?: {
                __typename?: 'ComponentPoliciesTableRow';
                table_rows?: {
                  __typename?: 'TableRowRelationResponseCollection';
                  data: Array<{
                    __typename?: 'TableRowEntity';
                    attributes?: {
                      __typename?: 'TableRow';
                      rows?: Array<{
                        __typename?: 'ComponentPoliciesRowItem';
                        item?: string | null;
                      } | null> | null;
                    } | null;
                  }>;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentPoliciesTitleTextContent';
              text?: any | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type WhistleblowingPolicyQueryVariables = Exact<{
  [key: string]: never;
}>;

export type WhistleblowingPolicyQuery = {
  __typename?: 'Query';
  whistleblowingPolicy?: {
    __typename?: 'WhistleblowingPolicyEntityResponse';
    data?: {
      __typename?: 'WhistleblowingPolicyEntity';
      attributes?: {
        __typename?: 'WhistleblowingPolicy';
        policies?: Array<
          | {
              __typename?: 'ComponentPoliciesDevider';
              Devider?: boolean | null;
            }
          | {
              __typename?: 'ComponentPoliciesDownloadLink';
              label?: string | null;
              link?: string | null;
            }
          | { __typename?: 'ComponentPoliciesMainHeading'; text?: any | null }
          | {
              __typename?: 'ComponentPoliciesTableTextContent';
              richText?: any | null;
              tableHead?: Array<{
                __typename?: 'ComponentPoliciesTableHeading';
                headingItem?: string | null;
              } | null> | null;
              tableRow?: {
                __typename?: 'ComponentPoliciesTableRow';
                table_rows?: {
                  __typename?: 'TableRowRelationResponseCollection';
                  data: Array<{
                    __typename?: 'TableRowEntity';
                    attributes?: {
                      __typename?: 'TableRow';
                      rows?: Array<{
                        __typename?: 'ComponentPoliciesRowItem';
                        item?: string | null;
                      } | null> | null;
                    } | null;
                  }>;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentPoliciesTitleTextContent';
              text?: any | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CookiesSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type CookiesSettingsQuery = {
  __typename?: 'Query';
  cookieSetting?: {
    __typename?: 'CookieSettingEntityResponse';
    data?: {
      __typename?: 'CookieSettingEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'CookieSetting';
        actionButtonLabel?: string | null;
        necessaryCookiesHeading?: string | null;
        optionalCookiesHeading?: string | null;
        title?: string | null;
        necessaryCookiesTable?: {
          __typename?: 'ComponentPoliciesTableTextContent';
          richText?: any | null;
          tableHead?: Array<{
            __typename?: 'ComponentPoliciesTableHeading';
            headingItem?: string | null;
          } | null> | null;
          tableRow?: {
            __typename?: 'ComponentPoliciesTableRow';
            table_rows?: {
              __typename?: 'TableRowRelationResponseCollection';
              data: Array<{
                __typename?: 'TableRowEntity';
                attributes?: {
                  __typename?: 'TableRow';
                  rows?: Array<{
                    __typename?: 'ComponentPoliciesRowItem';
                    item?: string | null;
                  } | null> | null;
                } | null;
              }>;
            } | null;
          } | null;
        } | null;
        optionalCookiesTale?: {
          __typename?: 'ComponentPoliciesTableTextContent';
          richText?: any | null;
          tableHead?: Array<{
            __typename?: 'ComponentPoliciesTableHeading';
            headingItem?: string | null;
          } | null> | null;
          tableRow?: {
            __typename?: 'ComponentPoliciesTableRow';
            table_rows?: {
              __typename?: 'TableRowRelationResponseCollection';
              data: Array<{
                __typename?: 'TableRowEntity';
                attributes?: {
                  __typename?: 'TableRow';
                  rows?: Array<{
                    __typename?: 'ComponentPoliciesRowItem';
                    item?: string | null;
                  } | null> | null;
                } | null;
              }>;
            } | null;
          } | null;
        } | null;
        redirectButton?: {
          __typename?: 'ComponentUiButton';
          label: string;
          path?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CookiesBarQueryVariables = Exact<{ [key: string]: never }>;

export type CookiesBarQuery = {
  __typename?: 'Query';
  cookiesBanner?: {
    __typename?: 'CookiesBannerEntityResponse';
    data?: {
      __typename?: 'CookiesBannerEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'CookiesBanner';
        heading?: string | null;
        editButtonLabel?: string | null;
        declineButtonLabel?: string | null;
        acceptButtonLabel?: string | null;
        text?: any | null;
        extraLinks?: Array<{
          __typename?: 'ComponentUiButton';
          id: string;
          label: string;
          path?: string | null;
          openInNewTab?: boolean | null;
          file?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type PowertrainHeroQueryVariables = Exact<{ [key: string]: never }>;

export type PowertrainHeroQuery = {
  __typename?: 'Query';
  powertrain?: {
    __typename?: 'PowertrainEntityResponse';
    data?: {
      __typename?: 'PowertrainEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Powertrain';
        heroVideoSection: {
          __typename?: 'ComponentUiHeroVideoSection';
          id: string;
          title: string;
          subtitle?: string | null;
          previewVideoLength?: string | null;
          showPreviewVideo?: boolean | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          heroVideo?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          previewVideo?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        };
      } | null;
    } | null;
  } | null;
};

export type PowertrainCardsSectionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PowertrainCardsSectionQuery = {
  __typename?: 'Query';
  powertrain?: {
    __typename?: 'PowertrainEntityResponse';
    data?: {
      __typename?: 'PowertrainEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Powertrain';
        cardsSection?: {
          __typename?: 'ComponentSharedSectionCards';
          title: string;
          anchor?: string | null;
          subtitle?: string | null;
          iconCard?: Array<{
            __typename?: 'ComponentUiIconCard';
            id: string;
            title: string;
            description: string;
            icon: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type PowertrainSpecsFragment = {
  __typename?: 'ComponentPowertrainTechnologies';
  id: string;
  anchor?: string | null;
  title?: string | null;
  description?: string | null;
  image?: {
    __typename?: 'ComponentUiImage';
    id: string;
    alt: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  paragraphs?: Array<{
    __typename?: 'ComponentUiParagraph';
    paragraphs?: string | null;
  } | null> | null;
  specTables?: {
    __typename?: 'SpecTableRelationResponseCollection';
    data: Array<{
      __typename?: 'SpecTableEntity';
      attributes?: {
        __typename?: 'SpecTable';
        title?: string | null;
        numOfItemsToShow?: number | null;
        heading?: {
          __typename?: 'ComponentUiHeading';
          title?: string | null;
          subtitle?: string | null;
        } | null;
        specs?: Array<{
          __typename?: 'ComponentUiSpec';
          label?: string | null;
          value?: string | null;
        } | null> | null;
      } | null;
    }>;
  } | null;
};

export type PowertrainSpecsQueryVariables = Exact<{ [key: string]: never }>;

export type PowertrainSpecsQuery = {
  __typename?: 'Query';
  powertrain?: {
    __typename?: 'PowertrainEntityResponse';
    data?: {
      __typename?: 'PowertrainEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Powertrain';
        technologies?: {
          __typename?: 'ComponentPowertrainTechnologies';
          id: string;
          anchor?: string | null;
          title?: string | null;
          description?: string | null;
          image?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          paragraphs?: Array<{
            __typename?: 'ComponentUiParagraph';
            paragraphs?: string | null;
          } | null> | null;
          specTables?: {
            __typename?: 'SpecTableRelationResponseCollection';
            data: Array<{
              __typename?: 'SpecTableEntity';
              attributes?: {
                __typename?: 'SpecTable';
                title?: string | null;
                numOfItemsToShow?: number | null;
                heading?: {
                  __typename?: 'ComponentUiHeading';
                  title?: string | null;
                  subtitle?: string | null;
                } | null;
                specs?: Array<{
                  __typename?: 'ComponentUiSpec';
                  label?: string | null;
                  value?: string | null;
                } | null> | null;
              } | null;
            }>;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type PowertrainProductSpecsBlocksQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PowertrainProductSpecsBlocksQuery = {
  __typename?: 'Query';
  powertrain?: {
    __typename?: 'PowertrainEntityResponse';
    data?: {
      __typename?: 'PowertrainEntity';
      attributes?: {
        __typename?: 'Powertrain';
        productSpecsBlocks?: Array<{
          __typename?: 'ComponentSharedProductSpecsBlocks';
          title?: string | null;
          subtitle?: string | null;
          anchor?: string | null;
          specs_options?: {
            __typename?: 'SpecsOptionRelationResponseCollection';
            data: Array<{
              __typename?: 'SpecsOptionEntity';
              attributes?: {
                __typename?: 'SpecsOption';
                option?: string | null;
                heading?: {
                  __typename?: 'ComponentUiHeading';
                  title?: string | null;
                  subtitle?: string | null;
                } | null;
                gallery?: Array<{
                  __typename?: 'ComponentUiImage';
                  id: string;
                  alt: string;
                  img_desktop: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
                largeSpec?: Array<{
                  __typename?: 'ComponentUiSpec';
                  label?: string | null;
                  value?: string | null;
                } | null> | null;
                denseSpecs?: Array<{
                  __typename?: 'ComponentSharedDenseSpec';
                  title?: string | null;
                  label?: string | null;
                  value?: string | null;
                  gapBellow?: boolean | null;
                } | null> | null;
                specTable?: {
                  __typename?: 'SpecTableEntityResponse';
                  data?: {
                    __typename?: 'SpecTableEntity';
                    attributes?: {
                      __typename?: 'SpecTable';
                      title?: string | null;
                      numOfItemsToShow?: number | null;
                      heading?: {
                        __typename?: 'ComponentUiHeading';
                        title?: string | null;
                        subtitle?: string | null;
                      } | null;
                      specs?: Array<{
                        __typename?: 'ComponentUiSpec';
                        label?: string | null;
                        value?: string | null;
                      } | null> | null;
                    } | null;
                  } | null;
                } | null;
                VehicleInfoContent?: {
                  __typename?: 'ComponentUiVehicleInfoContent';
                  title?: string | null;
                  text?: string | null;
                  carInfoItems?: Array<{
                    __typename?: 'ComponentUiCarInfoItems';
                    title?: string | null;
                    icon?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
                graph?: Array<{
                  __typename?: 'ComponentUiGraph';
                  label?: string | null;
                  image: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                } | null> | null;
                button?: {
                  __typename?: 'ComponentUiButton';
                  id: string;
                  label: string;
                  path?: string | null;
                  openInNewTab?: boolean | null;
                  file?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type PowertrainPartnersBlogSectionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PowertrainPartnersBlogSectionQuery = {
  __typename?: 'Query';
  powertrain?: {
    __typename?: 'PowertrainEntityResponse';
    data?: {
      __typename?: 'PowertrainEntity';
      attributes?: {
        __typename?: 'Powertrain';
        partnersBlogSection?: {
          __typename?: 'ComponentSharedPartnersBlogSection';
          title?: string | null;
          anchor?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          logos?: Array<{
            __typename?: 'ComponentUiIcon';
            id: string;
            alt: string;
            svg: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
          caseStudies?: {
            __typename?: 'CaseStudyRelationResponseCollection';
            data: Array<{
              __typename?: 'CaseStudyEntity';
              attributes?: {
                __typename?: 'CaseStudy';
                slug?: string | null;
                preview?: {
                  __typename?: 'ComponentCaseStudyArticlePreview';
                  title: string;
                  date?: string | null;
                  alt: string;
                  tag?: string | null;
                  tagColor?: Enum_Componentcasestudyarticlepreview_Tagcolor | null;
                  img_desktop: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type PowertrainSeoQueryVariables = Exact<{ [key: string]: never }>;

export type PowertrainSeoQuery = {
  __typename?: 'Query';
  powertrain?: {
    __typename?: 'PowertrainEntityResponse';
    data?: {
      __typename?: 'PowertrainEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Powertrain';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ValuesFragment = {
  __typename?: 'ComponentSharedValues';
  title?: string | null;
  anchor?: string | null;
  cards?: Array<{
    __typename?: 'ComponentUiIconCard';
    id: string;
    title: string;
    description: string;
    icon: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
  } | null> | null;
};

export type TwoImagesGridFragment = {
  __typename?: 'ComponentSharedTwoImagesGrid';
  title?: string | null;
  anchor?: string | null;
  imagesGridItem?: Array<{
    __typename?: 'ComponentUiCertification';
    title: string;
    description: string;
    tag?: string | null;
    tagColor?: Enum_Componentuicertification_Tagcolor | null;
    alt: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type LargeImageSectionFragment = {
  __typename?: 'ComponentSharedLargeImageSection';
  anchor?: string | null;
  title?: string | null;
  description?: string | null;
  button?: {
    __typename?: 'ComponentUiButton';
    id: string;
    label: string;
    path?: string | null;
    openInNewTab?: boolean | null;
    file?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  image?: {
    __typename?: 'ComponentUiImage';
    id: string;
    alt: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CardsSectionFragment = {
  __typename?: 'ComponentSharedSectionCards';
  title: string;
  anchor?: string | null;
  subtitle?: string | null;
  iconCard?: Array<{
    __typename?: 'ComponentUiIconCard';
    id: string;
    title: string;
    description: string;
    icon: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
  } | null> | null;
};

export type DenseSpecFragment = {
  __typename?: 'ComponentSharedDenseSpec';
  title?: string | null;
  label?: string | null;
  value?: string | null;
  gapBellow?: boolean | null;
};

export type ProductSpecsBlockFragment = {
  __typename?: 'ComponentSharedProductSpecsBlocks';
  title?: string | null;
  subtitle?: string | null;
  anchor?: string | null;
  specs_options?: {
    __typename?: 'SpecsOptionRelationResponseCollection';
    data: Array<{
      __typename?: 'SpecsOptionEntity';
      attributes?: {
        __typename?: 'SpecsOption';
        option?: string | null;
        heading?: {
          __typename?: 'ComponentUiHeading';
          title?: string | null;
          subtitle?: string | null;
        } | null;
        gallery?: Array<{
          __typename?: 'ComponentUiImage';
          id: string;
          alt: string;
          img_desktop: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          };
          img_mobile?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        largeSpec?: Array<{
          __typename?: 'ComponentUiSpec';
          label?: string | null;
          value?: string | null;
        } | null> | null;
        denseSpecs?: Array<{
          __typename?: 'ComponentSharedDenseSpec';
          title?: string | null;
          label?: string | null;
          value?: string | null;
          gapBellow?: boolean | null;
        } | null> | null;
        specTable?: {
          __typename?: 'SpecTableEntityResponse';
          data?: {
            __typename?: 'SpecTableEntity';
            attributes?: {
              __typename?: 'SpecTable';
              title?: string | null;
              numOfItemsToShow?: number | null;
              heading?: {
                __typename?: 'ComponentUiHeading';
                title?: string | null;
                subtitle?: string | null;
              } | null;
              specs?: Array<{
                __typename?: 'ComponentUiSpec';
                label?: string | null;
                value?: string | null;
              } | null> | null;
            } | null;
          } | null;
        } | null;
        VehicleInfoContent?: {
          __typename?: 'ComponentUiVehicleInfoContent';
          title?: string | null;
          text?: string | null;
          carInfoItems?: Array<{
            __typename?: 'ComponentUiCarInfoItems';
            title?: string | null;
            icon?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        graph?: Array<{
          __typename?: 'ComponentUiGraph';
          label?: string | null;
          image: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          };
        } | null> | null;
        button?: {
          __typename?: 'ComponentUiButton';
          id: string;
          label: string;
          path?: string | null;
          openInNewTab?: boolean | null;
          file?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type SliderImageSectionFragment = {
  __typename?: 'ComponentSharedSliderImageSection';
  id: string;
  anchor?: string | null;
  title?: string | null;
  titleMobile?: string | null;
  button?: {
    __typename?: 'ComponentUiButton';
    label: string;
    path?: string | null;
  } | null;
  imageCardLink?: Array<{
    __typename?: 'ComponentUiImageCardLink';
    title?: string | null;
    description?: string | null;
    link_label?: string | null;
    link_path?: string | null;
    alt?: string | null;
    tag?: string | null;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobil?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type MapSectionFragment = {
  __typename?: 'ComponentSharedMapSection';
  title: string;
  anchor?: string | null;
  leftMapTitle: string;
  rightMapTitle: string;
  leftMapImage: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  };
  leftMapImageMobile?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
  rightMapImage: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  };
  rightMapImageMobile: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  };
};

export type ProductImageBlockFragment = {
  __typename?: 'ComponentSharedProductImageBlock';
  title?: string | null;
  subtitle?: string | null;
  anchor?: string | null;
  galleryExtraText?: string | null;
  button?: {
    __typename?: 'ComponentUiButton';
    id: string;
    label: string;
    path?: string | null;
    openInNewTab?: boolean | null;
    file?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  largeSpec?: Array<{
    __typename?: 'ComponentUiSpec';
    label?: string | null;
    value?: string | null;
  } | null> | null;
  gallery?: Array<{
    __typename?: 'ComponentUiImage';
    id: string;
    alt: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
  denseSpecs?: Array<{
    __typename?: 'ComponentSharedDenseSpec';
    title?: string | null;
    label?: string | null;
    value?: string | null;
    gapBellow?: boolean | null;
  } | null> | null;
  specTable?: {
    __typename?: 'SpecTableEntityResponse';
    data?: {
      __typename?: 'SpecTableEntity';
      attributes?: {
        __typename?: 'SpecTable';
        title?: string | null;
        numOfItemsToShow?: number | null;
        heading?: {
          __typename?: 'ComponentUiHeading';
          title?: string | null;
          subtitle?: string | null;
        } | null;
        specs?: Array<{
          __typename?: 'ComponentUiSpec';
          label?: string | null;
          value?: string | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type TeamCardsFragment = {
  __typename?: 'ComponentSharedTeamCardsSection';
  anchor?: string | null;
  title?: string | null;
  teammates?: Array<{
    __typename?: 'ComponentUiTeamMate';
    name: string;
    position?: string | null;
    alt: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type NewsroomFragment = {
  __typename?: 'ComponentSharedNewsroom';
  title?: string | null;
  anchor?: string | null;
  button?: {
    __typename?: 'ComponentUiButton';
    id: string;
    label: string;
    path?: string | null;
    openInNewTab?: boolean | null;
    file?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type PartnersBlogSectionFragment = {
  __typename?: 'ComponentSharedPartnersBlogSection';
  title?: string | null;
  anchor?: string | null;
  button?: {
    __typename?: 'ComponentUiButton';
    id: string;
    label: string;
    path?: string | null;
    openInNewTab?: boolean | null;
    file?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  logos?: Array<{
    __typename?: 'ComponentUiIcon';
    id: string;
    alt: string;
    svg: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
  } | null> | null;
  caseStudies?: {
    __typename?: 'CaseStudyRelationResponseCollection';
    data: Array<{
      __typename?: 'CaseStudyEntity';
      attributes?: {
        __typename?: 'CaseStudy';
        slug?: string | null;
        preview?: {
          __typename?: 'ComponentCaseStudyArticlePreview';
          title: string;
          date?: string | null;
          alt: string;
          tag?: string | null;
          tagColor?: Enum_Componentcasestudyarticlepreview_Tagcolor | null;
          img_desktop: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          };
          img_mobile?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type VideoSectionFragment = {
  __typename?: 'ComponentSharedVideoSection';
  anchor?: string | null;
  title?: string | null;
  description?: string | null;
  button?: {
    __typename?: 'ComponentUiButton';
    id: string;
    label: string;
    path?: string | null;
    openInNewTab?: boolean | null;
    file?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  video?: {
    __typename?: 'ComponentUiVideo';
    poster_alt?: string | null;
    poster: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    mobilePoster?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    video?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type MetaSocialFragment = {
  __typename?: 'ComponentSharedMetaSocial';
  id: string;
  title: string;
  description: string;
  socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
  image?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type SeoFragment = {
  __typename?: 'ComponentSharedSeo';
  id: string;
  canonicalURL?: string | null;
  keywords?: string | null;
  metaDescription: string;
  metaRobots?: string | null;
  metaTitle: string;
  metaViewport?: string | null;
  structuredData?: any | null;
  metaImage?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
  metaSocial?: Array<{
    __typename?: 'ComponentSharedMetaSocial';
    id: string;
    title: string;
    description: string;
    socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
    image?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type SoftwareHeroQueryVariables = Exact<{ [key: string]: never }>;

export type SoftwareHeroQuery = {
  __typename?: 'Query';
  software?: {
    __typename?: 'SoftwareEntityResponse';
    data?: {
      __typename?: 'SoftwareEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Software';
        heroVideoSection: {
          __typename?: 'ComponentUiHeroVideoSection';
          id: string;
          title: string;
          subtitle?: string | null;
          previewVideoLength?: string | null;
          showPreviewVideo?: boolean | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          heroVideo?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          previewVideo?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        };
      } | null;
    } | null;
  } | null;
};

export type SoftwareProductImageBlockQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SoftwareProductImageBlockQuery = {
  __typename?: 'Query';
  software?: {
    __typename?: 'SoftwareEntityResponse';
    data?: {
      __typename?: 'SoftwareEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Software';
        productImageBlock?: Array<{
          __typename?: 'ComponentSharedProductImageBlock';
          title?: string | null;
          subtitle?: string | null;
          anchor?: string | null;
          galleryExtraText?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          largeSpec?: Array<{
            __typename?: 'ComponentUiSpec';
            label?: string | null;
            value?: string | null;
          } | null> | null;
          gallery?: Array<{
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          denseSpecs?: Array<{
            __typename?: 'ComponentSharedDenseSpec';
            title?: string | null;
            label?: string | null;
            value?: string | null;
            gapBellow?: boolean | null;
          } | null> | null;
          specTable?: {
            __typename?: 'SpecTableEntityResponse';
            data?: {
              __typename?: 'SpecTableEntity';
              attributes?: {
                __typename?: 'SpecTable';
                title?: string | null;
                numOfItemsToShow?: number | null;
                heading?: {
                  __typename?: 'ComponentUiHeading';
                  title?: string | null;
                  subtitle?: string | null;
                } | null;
                specs?: Array<{
                  __typename?: 'ComponentUiSpec';
                  label?: string | null;
                  value?: string | null;
                } | null> | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type SoftwarePartnersBlogSectionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SoftwarePartnersBlogSectionQuery = {
  __typename?: 'Query';
  software?: {
    __typename?: 'SoftwareEntityResponse';
    data?: {
      __typename?: 'SoftwareEntity';
      attributes?: {
        __typename?: 'Software';
        partnersBlogSection?: {
          __typename?: 'ComponentSharedPartnersBlogSection';
          title?: string | null;
          anchor?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          logos?: Array<{
            __typename?: 'ComponentUiIcon';
            id: string;
            alt: string;
            svg: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
          caseStudies?: {
            __typename?: 'CaseStudyRelationResponseCollection';
            data: Array<{
              __typename?: 'CaseStudyEntity';
              attributes?: {
                __typename?: 'CaseStudy';
                slug?: string | null;
                preview?: {
                  __typename?: 'ComponentCaseStudyArticlePreview';
                  title: string;
                  date?: string | null;
                  alt: string;
                  tag?: string | null;
                  tagColor?: Enum_Componentcasestudyarticlepreview_Tagcolor | null;
                  img_desktop: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type SoftwareSeoQueryVariables = Exact<{ [key: string]: never }>;

export type SoftwareSeoQuery = {
  __typename?: 'Query';
  software?: {
    __typename?: 'SoftwareEntityResponse';
    data?: {
      __typename?: 'SoftwareEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Software';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export const UploadFileFragmentDoc = gql`
  fragment uploadFile on UploadFileEntityResponse {
    data {
      id
      attributes {
        url
        alternativeText
        blurhash
      }
    }
  }
`;
export const CaseStudyPreviewFragmentDoc = gql`
  fragment caseStudyPreview on ComponentCaseStudyArticlePreview {
    title
    date
    alt
    tag
    tagColor
    img_desktop {
      ...uploadFile
    }
    img_mobile {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const ButtonFragmentDoc = gql`
  fragment button on ComponentUiButton {
    id
    label
    path
    openInNewTab
    file {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const ImageFragmentDoc = gql`
  fragment image on ComponentUiImage {
    id
    alt
    img_desktop {
      ...uploadFile
    }
    img_mobile {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const HeroImageSectionFragmentDoc = gql`
  fragment heroImageSection on ComponentUiHeroImageSection {
    id
    title
    subtitle
    button {
      ...button
    }
    heroImage {
      ...image
    }
  }
  ${ButtonFragmentDoc}
  ${ImageFragmentDoc}
`;
export const IconFragmentDoc = gql`
  fragment icon on ComponentUiIcon {
    id
    alt
    svg {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const PartnersBlogSectionFragmentDoc = gql`
  fragment partnersBlogSection on ComponentSharedPartnersBlogSection {
    title
    anchor
    button {
      ...button
    }
    logos {
      ...icon
    }
    caseStudies {
      data {
        attributes {
          slug
          preview {
            ...caseStudyPreview
          }
        }
      }
    }
  }
  ${ButtonFragmentDoc}
  ${IconFragmentDoc}
  ${CaseStudyPreviewFragmentDoc}
`;
export const MetaSocialFragmentDoc = gql`
  fragment metaSocial on ComponentSharedMetaSocial {
    id
    title
    description
    socialNetwork
    image {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const SeoFragmentDoc = gql`
  fragment seo on ComponentSharedSeo {
    id
    canonicalURL
    keywords
    metaDescription
    metaImage {
      ...uploadFile
    }
    metaRobots
    metaSocial {
      ...metaSocial
    }
    metaTitle
    metaViewport
    structuredData
  }
  ${UploadFileFragmentDoc}
  ${MetaSocialFragmentDoc}
`;
export const CaseStudyFragmentDoc = gql`
  fragment caseStudy on CaseStudy {
    slug
    preview {
      ...caseStudyPreview
    }
    hero {
      ...heroImageSection
    }
    content {
      ... on ComponentCaseStudyTextContent {
        text
      }
      ... on ComponentCaseStudyGallery {
        images {
          ...image
        }
      }
    }
    partnersBlogSection {
      ...partnersBlogSection
    }
    seo {
      ...seo
    }
  }
  ${CaseStudyPreviewFragmentDoc}
  ${HeroImageSectionFragmentDoc}
  ${ImageFragmentDoc}
  ${PartnersBlogSectionFragmentDoc}
  ${SeoFragmentDoc}
`;
export const UploadDocumentFragmentDoc = gql`
  fragment uploadDocument on UploadFileRelationResponseCollection {
    data {
      id
      attributes {
        url
        size
        blurhash
      }
    }
  }
`;
export const GraphFragmentDoc = gql`
  fragment graph on ComponentUiGraph {
    id
    label
    image {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const VideoFragmentDoc = gql`
  fragment video on ComponentUiVideo {
    poster {
      ...uploadFile
    }
    mobilePoster {
      ...uploadFile
    }
    poster_alt
    video {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const HeroVideoSectionFragmentDoc = gql`
  fragment heroVideoSection on ComponentUiHeroVideoSection {
    id
    title
    subtitle
    button {
      ...button
    }
    heroVideo {
      ...video
    }
    previewVideo {
      ...video
    }
    previewVideoLength
    showPreviewVideo
  }
  ${ButtonFragmentDoc}
  ${VideoFragmentDoc}
`;
export const ImageCardLinkFragmentDoc = gql`
  fragment imageCardLink on ComponentUiImageCardLink {
    title
    description
    link_label
    link_path
    alt
    tag
    img_desktop {
      ...uploadFile
    }
    img_mobil {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const ImageCardsWithLinkFragmentDoc = gql`
  fragment imageCardsWithLink on ComponentHomepageImageCardsWIthLink {
    id
    anchor
    imageCardLink {
      ...imageCardLink
    }
  }
  ${ImageCardLinkFragmentDoc}
`;
export const FooterLinkFragmentDoc = gql`
  fragment footerLink on ComponentFooterLink {
    label
    path
    openInNewTab
    file {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const NavLinkFragmentDoc = gql`
  fragment navLink on ComponentNavigationLink {
    label
    path
    openInNewTab
  }
`;
export const OpenMenuColumnsFragmentDoc = gql`
  fragment openMenuColumns on ComponentNavigationOpenMenuColumn {
    label
    link {
      ...button
    }
    socials {
      instagram
      linkedIn
      tiktok
      youtube
    }
  }
  ${ButtonFragmentDoc}
`;
export const PolicyFieldsFragmentDoc = gql`
  fragment policyFields on PrivacyPolicyPoliciesDynamicZone {
    ... on ComponentPoliciesDownloadLink {
      label
      link
    }
    ... on ComponentPoliciesMainHeading {
      text
    }
    ... on ComponentPoliciesTitleTextContent {
      text
    }
    ... on ComponentPoliciesTableTextContent {
      richText
      tableHead {
        headingItem
      }
      tableRow {
        table_rows {
          data {
            attributes {
              rows {
                item
              }
            }
          }
        }
      }
    }
    ... on ComponentPoliciesDevider {
      Devider
    }
  }
`;
export const SpecFragmentDoc = gql`
  fragment spec on ComponentUiSpec {
    label
    value
  }
`;
export const SpecTableFragmentDoc = gql`
  fragment specTable on SpecTable {
    heading {
      title
      subtitle
    }
    title
    specs(pagination: { limit: 100 }) {
      ...spec
    }
    numOfItemsToShow
  }
  ${SpecFragmentDoc}
`;
export const PowertrainSpecsFragmentDoc = gql`
  fragment powertrainSpecs on ComponentPowertrainTechnologies {
    id
    anchor
    title
    description
    image {
      ...image
    }
    paragraphs {
      paragraphs
    }
    specTables {
      data {
        attributes {
          ...specTable
        }
      }
    }
  }
  ${ImageFragmentDoc}
  ${SpecTableFragmentDoc}
`;
export const IconCardFragmentDoc = gql`
  fragment iconCard on ComponentUiIconCard {
    id
    title
    description
    icon {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const ValuesFragmentDoc = gql`
  fragment values on ComponentSharedValues {
    title
    anchor
    cards {
      ...iconCard
    }
  }
  ${IconCardFragmentDoc}
`;
export const TwoImagesGridFragmentDoc = gql`
  fragment twoImagesGrid on ComponentSharedTwoImagesGrid {
    title
    anchor
    imagesGridItem {
      title
      description
      tag
      tagColor
      alt
      img_desktop {
        ...uploadFile
      }
      img_mobile {
        ...uploadFile
      }
    }
  }
  ${UploadFileFragmentDoc}
`;
export const LargeImageSectionFragmentDoc = gql`
  fragment largeImageSection on ComponentSharedLargeImageSection {
    anchor
    title
    description
    button {
      ...button
    }
    image {
      ...image
    }
  }
  ${ButtonFragmentDoc}
  ${ImageFragmentDoc}
`;
export const CardsSectionFragmentDoc = gql`
  fragment cardsSection on ComponentSharedSectionCards {
    title
    anchor
    subtitle
    iconCard {
      ...iconCard
    }
  }
  ${IconCardFragmentDoc}
`;
export const DenseSpecFragmentDoc = gql`
  fragment denseSpec on ComponentSharedDenseSpec {
    title
    label
    value
    gapBellow
  }
`;
export const ProductSpecsBlockFragmentDoc = gql`
  fragment productSpecsBlock on ComponentSharedProductSpecsBlocks {
    title
    subtitle
    anchor
    specs_options {
      data {
        attributes {
          option
          heading {
            title
            subtitle
          }
          gallery {
            ...image
          }
          largeSpec {
            label
            value
          }
          denseSpecs(pagination: { limit: 100 }) {
            ...denseSpec
          }
          specTable {
            data {
              attributes {
                ...specTable
              }
            }
          }
          VehicleInfoContent {
            title
            text
            carInfoItems {
              title
              icon {
                ...uploadFile
              }
            }
          }
          graph {
            label
            image {
              ...uploadFile
            }
          }
          button {
            ...button
          }
        }
      }
    }
  }
  ${ImageFragmentDoc}
  ${DenseSpecFragmentDoc}
  ${SpecTableFragmentDoc}
  ${UploadFileFragmentDoc}
  ${ButtonFragmentDoc}
`;
export const SliderImageSectionFragmentDoc = gql`
  fragment sliderImageSection on ComponentSharedSliderImageSection {
    id
    anchor
    title
    titleMobile
    button {
      label
      path
    }
    imageCardLink {
      ...imageCardLink
    }
  }
  ${ImageCardLinkFragmentDoc}
`;
export const MapSectionFragmentDoc = gql`
  fragment mapSection on ComponentSharedMapSection {
    title
    anchor
    leftMapTitle
    leftMapImage {
      ...uploadFile
    }
    leftMapImageMobile {
      ...uploadFile
    }
    rightMapTitle
    rightMapImage {
      ...uploadFile
    }
    rightMapImageMobile {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const ProductImageBlockFragmentDoc = gql`
  fragment productImageBlock on ComponentSharedProductImageBlock {
    title
    subtitle
    anchor
    button {
      ...button
    }
    largeSpec {
      label
      value
    }
    gallery {
      ...image
    }
    denseSpecs(pagination: { limit: 100 }) {
      ...denseSpec
    }
    specTable {
      data {
        attributes {
          ...specTable
        }
      }
    }
    galleryExtraText
  }
  ${ButtonFragmentDoc}
  ${ImageFragmentDoc}
  ${DenseSpecFragmentDoc}
  ${SpecTableFragmentDoc}
`;
export const TeamCardsFragmentDoc = gql`
  fragment teamCards on ComponentSharedTeamCardsSection {
    anchor
    title
    teammates {
      name
      position
      alt
      img_desktop {
        ...uploadFile
      }
      img_mobile {
        ...uploadFile
      }
    }
  }
  ${UploadFileFragmentDoc}
`;
export const NewsroomFragmentDoc = gql`
  fragment newsroom on ComponentSharedNewsroom {
    title
    anchor
    button {
      ...button
    }
  }
  ${ButtonFragmentDoc}
`;
export const VideoSectionFragmentDoc = gql`
  fragment videoSection on ComponentSharedVideoSection {
    anchor
    title
    description
    button {
      ...button
    }
    video {
      ...video
    }
  }
  ${ButtonFragmentDoc}
  ${VideoFragmentDoc}
`;
export const BatteryHeroDocument = gql`
  query BatteryHero {
    battery {
      data {
        id
        attributes {
          heroVideoSection {
            ...heroVideoSection
          }
        }
      }
    }
  }
  ${HeroVideoSectionFragmentDoc}
`;

/**
 * __useBatteryHeroQuery__
 *
 * To run a query within a React component, call `useBatteryHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatteryHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatteryHeroQuery({
 *   variables: {
 *   },
 * });
 */
export function useBatteryHeroQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BatteryHeroQuery,
    BatteryHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BatteryHeroQuery, BatteryHeroQueryVariables>(
    BatteryHeroDocument,
    options,
  );
}
export function useBatteryHeroLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BatteryHeroQuery,
    BatteryHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BatteryHeroQuery, BatteryHeroQueryVariables>(
    BatteryHeroDocument,
    options,
  );
}
export function useBatteryHeroSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BatteryHeroQuery,
        BatteryHeroQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BatteryHeroQuery, BatteryHeroQueryVariables>(
    BatteryHeroDocument,
    options,
  );
}
export type BatteryHeroQueryHookResult = ReturnType<typeof useBatteryHeroQuery>;
export type BatteryHeroLazyQueryHookResult = ReturnType<
  typeof useBatteryHeroLazyQuery
>;
export type BatteryHeroSuspenseQueryHookResult = ReturnType<
  typeof useBatteryHeroSuspenseQuery
>;
export type BatteryHeroQueryResult = Apollo.QueryResult<
  BatteryHeroQuery,
  BatteryHeroQueryVariables
>;
export const BatteryCardsSectionDocument = gql`
  query BatteryCardsSection {
    battery {
      data {
        id
        attributes {
          cardsSection {
            ...cardsSection
          }
        }
      }
    }
  }
  ${CardsSectionFragmentDoc}
`;

/**
 * __useBatteryCardsSectionQuery__
 *
 * To run a query within a React component, call `useBatteryCardsSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatteryCardsSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatteryCardsSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useBatteryCardsSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BatteryCardsSectionQuery,
    BatteryCardsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BatteryCardsSectionQuery,
    BatteryCardsSectionQueryVariables
  >(BatteryCardsSectionDocument, options);
}
export function useBatteryCardsSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BatteryCardsSectionQuery,
    BatteryCardsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BatteryCardsSectionQuery,
    BatteryCardsSectionQueryVariables
  >(BatteryCardsSectionDocument, options);
}
export function useBatteryCardsSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BatteryCardsSectionQuery,
        BatteryCardsSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BatteryCardsSectionQuery,
    BatteryCardsSectionQueryVariables
  >(BatteryCardsSectionDocument, options);
}
export type BatteryCardsSectionQueryHookResult = ReturnType<
  typeof useBatteryCardsSectionQuery
>;
export type BatteryCardsSectionLazyQueryHookResult = ReturnType<
  typeof useBatteryCardsSectionLazyQuery
>;
export type BatteryCardsSectionSuspenseQueryHookResult = ReturnType<
  typeof useBatteryCardsSectionSuspenseQuery
>;
export type BatteryCardsSectionQueryResult = Apollo.QueryResult<
  BatteryCardsSectionQuery,
  BatteryCardsSectionQueryVariables
>;
export const BatteryProductImageBlockDocument = gql`
  query BatteryProductImageBlock {
    battery {
      data {
        attributes {
          productImageBlock {
            ...productImageBlock
          }
        }
      }
    }
  }
  ${ProductImageBlockFragmentDoc}
`;

/**
 * __useBatteryProductImageBlockQuery__
 *
 * To run a query within a React component, call `useBatteryProductImageBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatteryProductImageBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatteryProductImageBlockQuery({
 *   variables: {
 *   },
 * });
 */
export function useBatteryProductImageBlockQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BatteryProductImageBlockQuery,
    BatteryProductImageBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BatteryProductImageBlockQuery,
    BatteryProductImageBlockQueryVariables
  >(BatteryProductImageBlockDocument, options);
}
export function useBatteryProductImageBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BatteryProductImageBlockQuery,
    BatteryProductImageBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BatteryProductImageBlockQuery,
    BatteryProductImageBlockQueryVariables
  >(BatteryProductImageBlockDocument, options);
}
export function useBatteryProductImageBlockSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BatteryProductImageBlockQuery,
        BatteryProductImageBlockQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BatteryProductImageBlockQuery,
    BatteryProductImageBlockQueryVariables
  >(BatteryProductImageBlockDocument, options);
}
export type BatteryProductImageBlockQueryHookResult = ReturnType<
  typeof useBatteryProductImageBlockQuery
>;
export type BatteryProductImageBlockLazyQueryHookResult = ReturnType<
  typeof useBatteryProductImageBlockLazyQuery
>;
export type BatteryProductImageBlockSuspenseQueryHookResult = ReturnType<
  typeof useBatteryProductImageBlockSuspenseQuery
>;
export type BatteryProductImageBlockQueryResult = Apollo.QueryResult<
  BatteryProductImageBlockQuery,
  BatteryProductImageBlockQueryVariables
>;
export const BatteryTwoImagesGridDocument = gql`
  query BatteryTwoImagesGrid {
    battery {
      data {
        attributes {
          twoImagesGrid {
            ...twoImagesGrid
          }
        }
      }
    }
  }
  ${TwoImagesGridFragmentDoc}
`;

/**
 * __useBatteryTwoImagesGridQuery__
 *
 * To run a query within a React component, call `useBatteryTwoImagesGridQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatteryTwoImagesGridQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatteryTwoImagesGridQuery({
 *   variables: {
 *   },
 * });
 */
export function useBatteryTwoImagesGridQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BatteryTwoImagesGridQuery,
    BatteryTwoImagesGridQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BatteryTwoImagesGridQuery,
    BatteryTwoImagesGridQueryVariables
  >(BatteryTwoImagesGridDocument, options);
}
export function useBatteryTwoImagesGridLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BatteryTwoImagesGridQuery,
    BatteryTwoImagesGridQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BatteryTwoImagesGridQuery,
    BatteryTwoImagesGridQueryVariables
  >(BatteryTwoImagesGridDocument, options);
}
export function useBatteryTwoImagesGridSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BatteryTwoImagesGridQuery,
        BatteryTwoImagesGridQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BatteryTwoImagesGridQuery,
    BatteryTwoImagesGridQueryVariables
  >(BatteryTwoImagesGridDocument, options);
}
export type BatteryTwoImagesGridQueryHookResult = ReturnType<
  typeof useBatteryTwoImagesGridQuery
>;
export type BatteryTwoImagesGridLazyQueryHookResult = ReturnType<
  typeof useBatteryTwoImagesGridLazyQuery
>;
export type BatteryTwoImagesGridSuspenseQueryHookResult = ReturnType<
  typeof useBatteryTwoImagesGridSuspenseQuery
>;
export type BatteryTwoImagesGridQueryResult = Apollo.QueryResult<
  BatteryTwoImagesGridQuery,
  BatteryTwoImagesGridQueryVariables
>;
export const BatteryVideoSectionDocument = gql`
  query BatteryVideoSection {
    battery {
      data {
        attributes {
          videoSection {
            ...videoSection
          }
        }
      }
    }
  }
  ${VideoSectionFragmentDoc}
`;

/**
 * __useBatteryVideoSectionQuery__
 *
 * To run a query within a React component, call `useBatteryVideoSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatteryVideoSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatteryVideoSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useBatteryVideoSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BatteryVideoSectionQuery,
    BatteryVideoSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BatteryVideoSectionQuery,
    BatteryVideoSectionQueryVariables
  >(BatteryVideoSectionDocument, options);
}
export function useBatteryVideoSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BatteryVideoSectionQuery,
    BatteryVideoSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BatteryVideoSectionQuery,
    BatteryVideoSectionQueryVariables
  >(BatteryVideoSectionDocument, options);
}
export function useBatteryVideoSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BatteryVideoSectionQuery,
        BatteryVideoSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BatteryVideoSectionQuery,
    BatteryVideoSectionQueryVariables
  >(BatteryVideoSectionDocument, options);
}
export type BatteryVideoSectionQueryHookResult = ReturnType<
  typeof useBatteryVideoSectionQuery
>;
export type BatteryVideoSectionLazyQueryHookResult = ReturnType<
  typeof useBatteryVideoSectionLazyQuery
>;
export type BatteryVideoSectionSuspenseQueryHookResult = ReturnType<
  typeof useBatteryVideoSectionSuspenseQuery
>;
export type BatteryVideoSectionQueryResult = Apollo.QueryResult<
  BatteryVideoSectionQuery,
  BatteryVideoSectionQueryVariables
>;
export const BatteryPartnersBlogSectionDocument = gql`
  query BatteryPartnersBlogSection {
    battery {
      data {
        attributes {
          partnersBlogSection {
            ...partnersBlogSection
          }
        }
      }
    }
  }
  ${PartnersBlogSectionFragmentDoc}
`;

/**
 * __useBatteryPartnersBlogSectionQuery__
 *
 * To run a query within a React component, call `useBatteryPartnersBlogSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatteryPartnersBlogSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatteryPartnersBlogSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useBatteryPartnersBlogSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BatteryPartnersBlogSectionQuery,
    BatteryPartnersBlogSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BatteryPartnersBlogSectionQuery,
    BatteryPartnersBlogSectionQueryVariables
  >(BatteryPartnersBlogSectionDocument, options);
}
export function useBatteryPartnersBlogSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BatteryPartnersBlogSectionQuery,
    BatteryPartnersBlogSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BatteryPartnersBlogSectionQuery,
    BatteryPartnersBlogSectionQueryVariables
  >(BatteryPartnersBlogSectionDocument, options);
}
export function useBatteryPartnersBlogSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BatteryPartnersBlogSectionQuery,
        BatteryPartnersBlogSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BatteryPartnersBlogSectionQuery,
    BatteryPartnersBlogSectionQueryVariables
  >(BatteryPartnersBlogSectionDocument, options);
}
export type BatteryPartnersBlogSectionQueryHookResult = ReturnType<
  typeof useBatteryPartnersBlogSectionQuery
>;
export type BatteryPartnersBlogSectionLazyQueryHookResult = ReturnType<
  typeof useBatteryPartnersBlogSectionLazyQuery
>;
export type BatteryPartnersBlogSectionSuspenseQueryHookResult = ReturnType<
  typeof useBatteryPartnersBlogSectionSuspenseQuery
>;
export type BatteryPartnersBlogSectionQueryResult = Apollo.QueryResult<
  BatteryPartnersBlogSectionQuery,
  BatteryPartnersBlogSectionQueryVariables
>;
export const BatterySeoDocument = gql`
  query BatterySeo {
    battery {
      data {
        id
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useBatterySeoQuery__
 *
 * To run a query within a React component, call `useBatterySeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatterySeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatterySeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useBatterySeoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BatterySeoQuery,
    BatterySeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BatterySeoQuery, BatterySeoQueryVariables>(
    BatterySeoDocument,
    options,
  );
}
export function useBatterySeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BatterySeoQuery,
    BatterySeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BatterySeoQuery, BatterySeoQueryVariables>(
    BatterySeoDocument,
    options,
  );
}
export function useBatterySeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BatterySeoQuery,
        BatterySeoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BatterySeoQuery, BatterySeoQueryVariables>(
    BatterySeoDocument,
    options,
  );
}
export type BatterySeoQueryHookResult = ReturnType<typeof useBatterySeoQuery>;
export type BatterySeoLazyQueryHookResult = ReturnType<
  typeof useBatterySeoLazyQuery
>;
export type BatterySeoSuspenseQueryHookResult = ReturnType<
  typeof useBatterySeoSuspenseQuery
>;
export type BatterySeoQueryResult = Apollo.QueryResult<
  BatterySeoQuery,
  BatterySeoQueryVariables
>;
export const BmsHeroDocument = gql`
  query BmsHero {
    bms {
      data {
        attributes {
          heroVideoSection {
            ...heroVideoSection
          }
        }
      }
    }
  }
  ${HeroVideoSectionFragmentDoc}
`;

/**
 * __useBmsHeroQuery__
 *
 * To run a query within a React component, call `useBmsHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `useBmsHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBmsHeroQuery({
 *   variables: {
 *   },
 * });
 */
export function useBmsHeroQuery(
  baseOptions?: Apollo.QueryHookOptions<BmsHeroQuery, BmsHeroQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BmsHeroQuery, BmsHeroQueryVariables>(
    BmsHeroDocument,
    options,
  );
}
export function useBmsHeroLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BmsHeroQuery,
    BmsHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BmsHeroQuery, BmsHeroQueryVariables>(
    BmsHeroDocument,
    options,
  );
}
export function useBmsHeroSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BmsHeroQuery, BmsHeroQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BmsHeroQuery, BmsHeroQueryVariables>(
    BmsHeroDocument,
    options,
  );
}
export type BmsHeroQueryHookResult = ReturnType<typeof useBmsHeroQuery>;
export type BmsHeroLazyQueryHookResult = ReturnType<typeof useBmsHeroLazyQuery>;
export type BmsHeroSuspenseQueryHookResult = ReturnType<
  typeof useBmsHeroSuspenseQuery
>;
export type BmsHeroQueryResult = Apollo.QueryResult<
  BmsHeroQuery,
  BmsHeroQueryVariables
>;
export const BmsIconCardsDocument = gql`
  query BmsIconCards {
    bms {
      data {
        attributes {
          cardsSection {
            ...cardsSection
          }
        }
      }
    }
  }
  ${CardsSectionFragmentDoc}
`;

/**
 * __useBmsIconCardsQuery__
 *
 * To run a query within a React component, call `useBmsIconCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBmsIconCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBmsIconCardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBmsIconCardsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BmsIconCardsQuery,
    BmsIconCardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BmsIconCardsQuery, BmsIconCardsQueryVariables>(
    BmsIconCardsDocument,
    options,
  );
}
export function useBmsIconCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BmsIconCardsQuery,
    BmsIconCardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BmsIconCardsQuery, BmsIconCardsQueryVariables>(
    BmsIconCardsDocument,
    options,
  );
}
export function useBmsIconCardsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BmsIconCardsQuery,
        BmsIconCardsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BmsIconCardsQuery, BmsIconCardsQueryVariables>(
    BmsIconCardsDocument,
    options,
  );
}
export type BmsIconCardsQueryHookResult = ReturnType<
  typeof useBmsIconCardsQuery
>;
export type BmsIconCardsLazyQueryHookResult = ReturnType<
  typeof useBmsIconCardsLazyQuery
>;
export type BmsIconCardsSuspenseQueryHookResult = ReturnType<
  typeof useBmsIconCardsSuspenseQuery
>;
export type BmsIconCardsQueryResult = Apollo.QueryResult<
  BmsIconCardsQuery,
  BmsIconCardsQueryVariables
>;
export const BmsProductImageBlockDocument = gql`
  query BmsProductImageBlock {
    bms {
      data {
        attributes {
          productImageBlock {
            ...productImageBlock
          }
        }
      }
    }
  }
  ${ProductImageBlockFragmentDoc}
`;

/**
 * __useBmsProductImageBlockQuery__
 *
 * To run a query within a React component, call `useBmsProductImageBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useBmsProductImageBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBmsProductImageBlockQuery({
 *   variables: {
 *   },
 * });
 */
export function useBmsProductImageBlockQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BmsProductImageBlockQuery,
    BmsProductImageBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BmsProductImageBlockQuery,
    BmsProductImageBlockQueryVariables
  >(BmsProductImageBlockDocument, options);
}
export function useBmsProductImageBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BmsProductImageBlockQuery,
    BmsProductImageBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BmsProductImageBlockQuery,
    BmsProductImageBlockQueryVariables
  >(BmsProductImageBlockDocument, options);
}
export function useBmsProductImageBlockSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BmsProductImageBlockQuery,
        BmsProductImageBlockQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BmsProductImageBlockQuery,
    BmsProductImageBlockQueryVariables
  >(BmsProductImageBlockDocument, options);
}
export type BmsProductImageBlockQueryHookResult = ReturnType<
  typeof useBmsProductImageBlockQuery
>;
export type BmsProductImageBlockLazyQueryHookResult = ReturnType<
  typeof useBmsProductImageBlockLazyQuery
>;
export type BmsProductImageBlockSuspenseQueryHookResult = ReturnType<
  typeof useBmsProductImageBlockSuspenseQuery
>;
export type BmsProductImageBlockQueryResult = Apollo.QueryResult<
  BmsProductImageBlockQuery,
  BmsProductImageBlockQueryVariables
>;
export const BmsProductSpecsBlockDocument = gql`
  query BmsProductSpecsBlock {
    bms {
      data {
        attributes {
          productSpecsBlock {
            ...productSpecsBlock
          }
        }
      }
    }
  }
  ${ProductSpecsBlockFragmentDoc}
`;

/**
 * __useBmsProductSpecsBlockQuery__
 *
 * To run a query within a React component, call `useBmsProductSpecsBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useBmsProductSpecsBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBmsProductSpecsBlockQuery({
 *   variables: {
 *   },
 * });
 */
export function useBmsProductSpecsBlockQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BmsProductSpecsBlockQuery,
    BmsProductSpecsBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BmsProductSpecsBlockQuery,
    BmsProductSpecsBlockQueryVariables
  >(BmsProductSpecsBlockDocument, options);
}
export function useBmsProductSpecsBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BmsProductSpecsBlockQuery,
    BmsProductSpecsBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BmsProductSpecsBlockQuery,
    BmsProductSpecsBlockQueryVariables
  >(BmsProductSpecsBlockDocument, options);
}
export function useBmsProductSpecsBlockSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BmsProductSpecsBlockQuery,
        BmsProductSpecsBlockQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BmsProductSpecsBlockQuery,
    BmsProductSpecsBlockQueryVariables
  >(BmsProductSpecsBlockDocument, options);
}
export type BmsProductSpecsBlockQueryHookResult = ReturnType<
  typeof useBmsProductSpecsBlockQuery
>;
export type BmsProductSpecsBlockLazyQueryHookResult = ReturnType<
  typeof useBmsProductSpecsBlockLazyQuery
>;
export type BmsProductSpecsBlockSuspenseQueryHookResult = ReturnType<
  typeof useBmsProductSpecsBlockSuspenseQuery
>;
export type BmsProductSpecsBlockQueryResult = Apollo.QueryResult<
  BmsProductSpecsBlockQuery,
  BmsProductSpecsBlockQueryVariables
>;
export const BmsTwoImagesGridDocument = gql`
  query BmsTwoImagesGrid {
    bms {
      data {
        attributes {
          twoImagesGrid {
            ...twoImagesGrid
          }
        }
      }
    }
  }
  ${TwoImagesGridFragmentDoc}
`;

/**
 * __useBmsTwoImagesGridQuery__
 *
 * To run a query within a React component, call `useBmsTwoImagesGridQuery` and pass it any options that fit your needs.
 * When your component renders, `useBmsTwoImagesGridQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBmsTwoImagesGridQuery({
 *   variables: {
 *   },
 * });
 */
export function useBmsTwoImagesGridQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BmsTwoImagesGridQuery,
    BmsTwoImagesGridQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BmsTwoImagesGridQuery, BmsTwoImagesGridQueryVariables>(
    BmsTwoImagesGridDocument,
    options,
  );
}
export function useBmsTwoImagesGridLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BmsTwoImagesGridQuery,
    BmsTwoImagesGridQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BmsTwoImagesGridQuery,
    BmsTwoImagesGridQueryVariables
  >(BmsTwoImagesGridDocument, options);
}
export function useBmsTwoImagesGridSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BmsTwoImagesGridQuery,
        BmsTwoImagesGridQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BmsTwoImagesGridQuery,
    BmsTwoImagesGridQueryVariables
  >(BmsTwoImagesGridDocument, options);
}
export type BmsTwoImagesGridQueryHookResult = ReturnType<
  typeof useBmsTwoImagesGridQuery
>;
export type BmsTwoImagesGridLazyQueryHookResult = ReturnType<
  typeof useBmsTwoImagesGridLazyQuery
>;
export type BmsTwoImagesGridSuspenseQueryHookResult = ReturnType<
  typeof useBmsTwoImagesGridSuspenseQuery
>;
export type BmsTwoImagesGridQueryResult = Apollo.QueryResult<
  BmsTwoImagesGridQuery,
  BmsTwoImagesGridQueryVariables
>;
export const BmsLargeImageSectionDocument = gql`
  query BmsLargeImageSection {
    bms {
      data {
        attributes {
          largeImageSection {
            ...largeImageSection
          }
        }
      }
    }
  }
  ${LargeImageSectionFragmentDoc}
`;

/**
 * __useBmsLargeImageSectionQuery__
 *
 * To run a query within a React component, call `useBmsLargeImageSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useBmsLargeImageSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBmsLargeImageSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useBmsLargeImageSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BmsLargeImageSectionQuery,
    BmsLargeImageSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BmsLargeImageSectionQuery,
    BmsLargeImageSectionQueryVariables
  >(BmsLargeImageSectionDocument, options);
}
export function useBmsLargeImageSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BmsLargeImageSectionQuery,
    BmsLargeImageSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BmsLargeImageSectionQuery,
    BmsLargeImageSectionQueryVariables
  >(BmsLargeImageSectionDocument, options);
}
export function useBmsLargeImageSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BmsLargeImageSectionQuery,
        BmsLargeImageSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BmsLargeImageSectionQuery,
    BmsLargeImageSectionQueryVariables
  >(BmsLargeImageSectionDocument, options);
}
export type BmsLargeImageSectionQueryHookResult = ReturnType<
  typeof useBmsLargeImageSectionQuery
>;
export type BmsLargeImageSectionLazyQueryHookResult = ReturnType<
  typeof useBmsLargeImageSectionLazyQuery
>;
export type BmsLargeImageSectionSuspenseQueryHookResult = ReturnType<
  typeof useBmsLargeImageSectionSuspenseQuery
>;
export type BmsLargeImageSectionQueryResult = Apollo.QueryResult<
  BmsLargeImageSectionQuery,
  BmsLargeImageSectionQueryVariables
>;
export const BmsPartnersBlogSectionDocument = gql`
  query BmsPartnersBlogSection {
    bms {
      data {
        attributes {
          partnersBlogSection {
            ...partnersBlogSection
          }
        }
      }
    }
  }
  ${PartnersBlogSectionFragmentDoc}
`;

/**
 * __useBmsPartnersBlogSectionQuery__
 *
 * To run a query within a React component, call `useBmsPartnersBlogSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useBmsPartnersBlogSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBmsPartnersBlogSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useBmsPartnersBlogSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BmsPartnersBlogSectionQuery,
    BmsPartnersBlogSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BmsPartnersBlogSectionQuery,
    BmsPartnersBlogSectionQueryVariables
  >(BmsPartnersBlogSectionDocument, options);
}
export function useBmsPartnersBlogSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BmsPartnersBlogSectionQuery,
    BmsPartnersBlogSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BmsPartnersBlogSectionQuery,
    BmsPartnersBlogSectionQueryVariables
  >(BmsPartnersBlogSectionDocument, options);
}
export function useBmsPartnersBlogSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BmsPartnersBlogSectionQuery,
        BmsPartnersBlogSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BmsPartnersBlogSectionQuery,
    BmsPartnersBlogSectionQueryVariables
  >(BmsPartnersBlogSectionDocument, options);
}
export type BmsPartnersBlogSectionQueryHookResult = ReturnType<
  typeof useBmsPartnersBlogSectionQuery
>;
export type BmsPartnersBlogSectionLazyQueryHookResult = ReturnType<
  typeof useBmsPartnersBlogSectionLazyQuery
>;
export type BmsPartnersBlogSectionSuspenseQueryHookResult = ReturnType<
  typeof useBmsPartnersBlogSectionSuspenseQuery
>;
export type BmsPartnersBlogSectionQueryResult = Apollo.QueryResult<
  BmsPartnersBlogSectionQuery,
  BmsPartnersBlogSectionQueryVariables
>;
export const BmsSeoDocument = gql`
  query BmsSeo {
    bms {
      data {
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useBmsSeoQuery__
 *
 * To run a query within a React component, call `useBmsSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBmsSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBmsSeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useBmsSeoQuery(
  baseOptions?: Apollo.QueryHookOptions<BmsSeoQuery, BmsSeoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BmsSeoQuery, BmsSeoQueryVariables>(
    BmsSeoDocument,
    options,
  );
}
export function useBmsSeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BmsSeoQuery, BmsSeoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BmsSeoQuery, BmsSeoQueryVariables>(
    BmsSeoDocument,
    options,
  );
}
export function useBmsSeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BmsSeoQuery, BmsSeoQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BmsSeoQuery, BmsSeoQueryVariables>(
    BmsSeoDocument,
    options,
  );
}
export type BmsSeoQueryHookResult = ReturnType<typeof useBmsSeoQuery>;
export type BmsSeoLazyQueryHookResult = ReturnType<typeof useBmsSeoLazyQuery>;
export type BmsSeoSuspenseQueryHookResult = ReturnType<
  typeof useBmsSeoSuspenseQuery
>;
export type BmsSeoQueryResult = Apollo.QueryResult<
  BmsSeoQuery,
  BmsSeoQueryVariables
>;
export const CaseStudiesDocument = gql`
  query caseStudies($slug: String, $limit: Int) {
    caseStudies(
      filters: { slug: { eq: $slug } }
      pagination: { limit: $limit }
    ) {
      data {
        attributes {
          ...caseStudy
        }
      }
    }
  }
  ${CaseStudyFragmentDoc}
`;

/**
 * __useCaseStudiesQuery__
 *
 * To run a query within a React component, call `useCaseStudiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseStudiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseStudiesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCaseStudiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CaseStudiesQuery,
    CaseStudiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CaseStudiesQuery, CaseStudiesQueryVariables>(
    CaseStudiesDocument,
    options,
  );
}
export function useCaseStudiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CaseStudiesQuery,
    CaseStudiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CaseStudiesQuery, CaseStudiesQueryVariables>(
    CaseStudiesDocument,
    options,
  );
}
export function useCaseStudiesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CaseStudiesQuery,
        CaseStudiesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CaseStudiesQuery, CaseStudiesQueryVariables>(
    CaseStudiesDocument,
    options,
  );
}
export type CaseStudiesQueryHookResult = ReturnType<typeof useCaseStudiesQuery>;
export type CaseStudiesLazyQueryHookResult = ReturnType<
  typeof useCaseStudiesLazyQuery
>;
export type CaseStudiesSuspenseQueryHookResult = ReturnType<
  typeof useCaseStudiesSuspenseQuery
>;
export type CaseStudiesQueryResult = Apollo.QueryResult<
  CaseStudiesQuery,
  CaseStudiesQueryVariables
>;
export const ImpressumDocument = gql`
  query Impressum {
    impressum {
      data {
        attributes {
          title
          text
          lastUpdatedText
        }
      }
    }
  }
`;

/**
 * __useImpressumQuery__
 *
 * To run a query within a React component, call `useImpressumQuery` and pass it any options that fit your needs.
 * When your component renders, `useImpressumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImpressumQuery({
 *   variables: {
 *   },
 * });
 */
export function useImpressumQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ImpressumQuery,
    ImpressumQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ImpressumQuery, ImpressumQueryVariables>(
    ImpressumDocument,
    options,
  );
}
export function useImpressumLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImpressumQuery,
    ImpressumQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ImpressumQuery, ImpressumQueryVariables>(
    ImpressumDocument,
    options,
  );
}
export function useImpressumSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ImpressumQuery, ImpressumQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ImpressumQuery, ImpressumQueryVariables>(
    ImpressumDocument,
    options,
  );
}
export type ImpressumQueryHookResult = ReturnType<typeof useImpressumQuery>;
export type ImpressumLazyQueryHookResult = ReturnType<
  typeof useImpressumLazyQuery
>;
export type ImpressumSuspenseQueryHookResult = ReturnType<
  typeof useImpressumSuspenseQuery
>;
export type ImpressumQueryResult = Apollo.QueryResult<
  ImpressumQuery,
  ImpressumQueryVariables
>;
export const CompanyHeroDocument = gql`
  query CompanyHero {
    company {
      data {
        id
        attributes {
          heroVideoSection {
            ...heroVideoSection
          }
        }
      }
    }
  }
  ${HeroVideoSectionFragmentDoc}
`;

/**
 * __useCompanyHeroQuery__
 *
 * To run a query within a React component, call `useCompanyHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyHeroQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyHeroQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyHeroQuery,
    CompanyHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyHeroQuery, CompanyHeroQueryVariables>(
    CompanyHeroDocument,
    options,
  );
}
export function useCompanyHeroLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyHeroQuery,
    CompanyHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyHeroQuery, CompanyHeroQueryVariables>(
    CompanyHeroDocument,
    options,
  );
}
export function useCompanyHeroSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyHeroQuery,
        CompanyHeroQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CompanyHeroQuery, CompanyHeroQueryVariables>(
    CompanyHeroDocument,
    options,
  );
}
export type CompanyHeroQueryHookResult = ReturnType<typeof useCompanyHeroQuery>;
export type CompanyHeroLazyQueryHookResult = ReturnType<
  typeof useCompanyHeroLazyQuery
>;
export type CompanyHeroSuspenseQueryHookResult = ReturnType<
  typeof useCompanyHeroSuspenseQuery
>;
export type CompanyHeroQueryResult = Apollo.QueryResult<
  CompanyHeroQuery,
  CompanyHeroQueryVariables
>;
export const CompanySliderImageSectionDocument = gql`
  query CompanySliderImageSection {
    company {
      data {
        attributes {
          sliderImageSection {
            ...sliderImageSection
          }
        }
      }
    }
  }
  ${SliderImageSectionFragmentDoc}
`;

/**
 * __useCompanySliderImageSectionQuery__
 *
 * To run a query within a React component, call `useCompanySliderImageSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySliderImageSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySliderImageSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanySliderImageSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanySliderImageSectionQuery,
    CompanySliderImageSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanySliderImageSectionQuery,
    CompanySliderImageSectionQueryVariables
  >(CompanySliderImageSectionDocument, options);
}
export function useCompanySliderImageSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanySliderImageSectionQuery,
    CompanySliderImageSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanySliderImageSectionQuery,
    CompanySliderImageSectionQueryVariables
  >(CompanySliderImageSectionDocument, options);
}
export function useCompanySliderImageSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanySliderImageSectionQuery,
        CompanySliderImageSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CompanySliderImageSectionQuery,
    CompanySliderImageSectionQueryVariables
  >(CompanySliderImageSectionDocument, options);
}
export type CompanySliderImageSectionQueryHookResult = ReturnType<
  typeof useCompanySliderImageSectionQuery
>;
export type CompanySliderImageSectionLazyQueryHookResult = ReturnType<
  typeof useCompanySliderImageSectionLazyQuery
>;
export type CompanySliderImageSectionSuspenseQueryHookResult = ReturnType<
  typeof useCompanySliderImageSectionSuspenseQuery
>;
export type CompanySliderImageSectionQueryResult = Apollo.QueryResult<
  CompanySliderImageSectionQuery,
  CompanySliderImageSectionQueryVariables
>;
export const CompanyValuesDocument = gql`
  query CompanyValues {
    company {
      data {
        attributes {
          values {
            ...values
          }
        }
      }
    }
  }
  ${ValuesFragmentDoc}
`;

/**
 * __useCompanyValuesQuery__
 *
 * To run a query within a React component, call `useCompanyValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyValuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyValuesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyValuesQuery,
    CompanyValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyValuesQuery, CompanyValuesQueryVariables>(
    CompanyValuesDocument,
    options,
  );
}
export function useCompanyValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyValuesQuery,
    CompanyValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyValuesQuery, CompanyValuesQueryVariables>(
    CompanyValuesDocument,
    options,
  );
}
export function useCompanyValuesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyValuesQuery,
        CompanyValuesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CompanyValuesQuery,
    CompanyValuesQueryVariables
  >(CompanyValuesDocument, options);
}
export type CompanyValuesQueryHookResult = ReturnType<
  typeof useCompanyValuesQuery
>;
export type CompanyValuesLazyQueryHookResult = ReturnType<
  typeof useCompanyValuesLazyQuery
>;
export type CompanyValuesSuspenseQueryHookResult = ReturnType<
  typeof useCompanyValuesSuspenseQuery
>;
export type CompanyValuesQueryResult = Apollo.QueryResult<
  CompanyValuesQuery,
  CompanyValuesQueryVariables
>;
export const CompanyCampusDocument = gql`
  query CompanyCampus {
    company {
      data {
        attributes {
          Campus {
            id
            Text
            Title
            image {
              ...image
            }
            spec {
              ...spec
            }
          }
        }
      }
    }
    company {
      data {
        attributes {
          CampusAnchor
        }
      }
    }
  }
  ${ImageFragmentDoc}
  ${SpecFragmentDoc}
`;

/**
 * __useCompanyCampusQuery__
 *
 * To run a query within a React component, call `useCompanyCampusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyCampusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyCampusQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyCampusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyCampusQuery,
    CompanyCampusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyCampusQuery, CompanyCampusQueryVariables>(
    CompanyCampusDocument,
    options,
  );
}
export function useCompanyCampusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyCampusQuery,
    CompanyCampusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyCampusQuery, CompanyCampusQueryVariables>(
    CompanyCampusDocument,
    options,
  );
}
export function useCompanyCampusSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyCampusQuery,
        CompanyCampusQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CompanyCampusQuery,
    CompanyCampusQueryVariables
  >(CompanyCampusDocument, options);
}
export type CompanyCampusQueryHookResult = ReturnType<
  typeof useCompanyCampusQuery
>;
export type CompanyCampusLazyQueryHookResult = ReturnType<
  typeof useCompanyCampusLazyQuery
>;
export type CompanyCampusSuspenseQueryHookResult = ReturnType<
  typeof useCompanyCampusSuspenseQuery
>;
export type CompanyCampusQueryResult = Apollo.QueryResult<
  CompanyCampusQuery,
  CompanyCampusQueryVariables
>;
export const CompanyMapSectionDocument = gql`
  query CompanyMapSection {
    company {
      data {
        attributes {
          mapSection {
            ...mapSection
          }
        }
      }
    }
  }
  ${MapSectionFragmentDoc}
`;

/**
 * __useCompanyMapSectionQuery__
 *
 * To run a query within a React component, call `useCompanyMapSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyMapSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyMapSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyMapSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyMapSectionQuery,
    CompanyMapSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyMapSectionQuery,
    CompanyMapSectionQueryVariables
  >(CompanyMapSectionDocument, options);
}
export function useCompanyMapSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyMapSectionQuery,
    CompanyMapSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyMapSectionQuery,
    CompanyMapSectionQueryVariables
  >(CompanyMapSectionDocument, options);
}
export function useCompanyMapSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyMapSectionQuery,
        CompanyMapSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CompanyMapSectionQuery,
    CompanyMapSectionQueryVariables
  >(CompanyMapSectionDocument, options);
}
export type CompanyMapSectionQueryHookResult = ReturnType<
  typeof useCompanyMapSectionQuery
>;
export type CompanyMapSectionLazyQueryHookResult = ReturnType<
  typeof useCompanyMapSectionLazyQuery
>;
export type CompanyMapSectionSuspenseQueryHookResult = ReturnType<
  typeof useCompanyMapSectionSuspenseQuery
>;
export type CompanyMapSectionQueryResult = Apollo.QueryResult<
  CompanyMapSectionQuery,
  CompanyMapSectionQueryVariables
>;
export const CompanyTeamDocument = gql`
  query CompanyTeam {
    company {
      data {
        attributes {
          teamCards {
            ...teamCards
          }
        }
      }
    }
  }
  ${TeamCardsFragmentDoc}
`;

/**
 * __useCompanyTeamQuery__
 *
 * To run a query within a React component, call `useCompanyTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyTeamQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyTeamQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyTeamQuery,
    CompanyTeamQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyTeamQuery, CompanyTeamQueryVariables>(
    CompanyTeamDocument,
    options,
  );
}
export function useCompanyTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyTeamQuery,
    CompanyTeamQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyTeamQuery, CompanyTeamQueryVariables>(
    CompanyTeamDocument,
    options,
  );
}
export function useCompanyTeamSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyTeamQuery,
        CompanyTeamQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CompanyTeamQuery, CompanyTeamQueryVariables>(
    CompanyTeamDocument,
    options,
  );
}
export type CompanyTeamQueryHookResult = ReturnType<typeof useCompanyTeamQuery>;
export type CompanyTeamLazyQueryHookResult = ReturnType<
  typeof useCompanyTeamLazyQuery
>;
export type CompanyTeamSuspenseQueryHookResult = ReturnType<
  typeof useCompanyTeamSuspenseQuery
>;
export type CompanyTeamQueryResult = Apollo.QueryResult<
  CompanyTeamQuery,
  CompanyTeamQueryVariables
>;
export const CompanyLargeImageDocument = gql`
  query CompanyLargeImage {
    company {
      data {
        attributes {
          largeImageSection {
            ...largeImageSection
          }
        }
      }
    }
  }
  ${LargeImageSectionFragmentDoc}
`;

/**
 * __useCompanyLargeImageQuery__
 *
 * To run a query within a React component, call `useCompanyLargeImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyLargeImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyLargeImageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyLargeImageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyLargeImageQuery,
    CompanyLargeImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyLargeImageQuery,
    CompanyLargeImageQueryVariables
  >(CompanyLargeImageDocument, options);
}
export function useCompanyLargeImageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyLargeImageQuery,
    CompanyLargeImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyLargeImageQuery,
    CompanyLargeImageQueryVariables
  >(CompanyLargeImageDocument, options);
}
export function useCompanyLargeImageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyLargeImageQuery,
        CompanyLargeImageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CompanyLargeImageQuery,
    CompanyLargeImageQueryVariables
  >(CompanyLargeImageDocument, options);
}
export type CompanyLargeImageQueryHookResult = ReturnType<
  typeof useCompanyLargeImageQuery
>;
export type CompanyLargeImageLazyQueryHookResult = ReturnType<
  typeof useCompanyLargeImageLazyQuery
>;
export type CompanyLargeImageSuspenseQueryHookResult = ReturnType<
  typeof useCompanyLargeImageSuspenseQuery
>;
export type CompanyLargeImageQueryResult = Apollo.QueryResult<
  CompanyLargeImageQuery,
  CompanyLargeImageQueryVariables
>;
export const CompanyNewsroomDocument = gql`
  query CompanyNewsroom {
    company {
      data {
        attributes {
          newsroom {
            ...newsroom
          }
        }
      }
    }
  }
  ${NewsroomFragmentDoc}
`;

/**
 * __useCompanyNewsroomQuery__
 *
 * To run a query within a React component, call `useCompanyNewsroomQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyNewsroomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyNewsroomQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyNewsroomQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyNewsroomQuery,
    CompanyNewsroomQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyNewsroomQuery, CompanyNewsroomQueryVariables>(
    CompanyNewsroomDocument,
    options,
  );
}
export function useCompanyNewsroomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyNewsroomQuery,
    CompanyNewsroomQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyNewsroomQuery,
    CompanyNewsroomQueryVariables
  >(CompanyNewsroomDocument, options);
}
export function useCompanyNewsroomSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyNewsroomQuery,
        CompanyNewsroomQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CompanyNewsroomQuery,
    CompanyNewsroomQueryVariables
  >(CompanyNewsroomDocument, options);
}
export type CompanyNewsroomQueryHookResult = ReturnType<
  typeof useCompanyNewsroomQuery
>;
export type CompanyNewsroomLazyQueryHookResult = ReturnType<
  typeof useCompanyNewsroomLazyQuery
>;
export type CompanyNewsroomSuspenseQueryHookResult = ReturnType<
  typeof useCompanyNewsroomSuspenseQuery
>;
export type CompanyNewsroomQueryResult = Apollo.QueryResult<
  CompanyNewsroomQuery,
  CompanyNewsroomQueryVariables
>;
export const CompanySeoDocument = gql`
  query CompanySeo {
    company {
      data {
        id
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useCompanySeoQuery__
 *
 * To run a query within a React component, call `useCompanySeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanySeoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanySeoQuery,
    CompanySeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanySeoQuery, CompanySeoQueryVariables>(
    CompanySeoDocument,
    options,
  );
}
export function useCompanySeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanySeoQuery,
    CompanySeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanySeoQuery, CompanySeoQueryVariables>(
    CompanySeoDocument,
    options,
  );
}
export function useCompanySeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanySeoQuery,
        CompanySeoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CompanySeoQuery, CompanySeoQueryVariables>(
    CompanySeoDocument,
    options,
  );
}
export type CompanySeoQueryHookResult = ReturnType<typeof useCompanySeoQuery>;
export type CompanySeoLazyQueryHookResult = ReturnType<
  typeof useCompanySeoLazyQuery
>;
export type CompanySeoSuspenseQueryHookResult = ReturnType<
  typeof useCompanySeoSuspenseQuery
>;
export type CompanySeoQueryResult = Apollo.QueryResult<
  CompanySeoQuery,
  CompanySeoQueryVariables
>;
export const EcusHeroDocument = gql`
  query EcusHero {
    ecus {
      data {
        id
        attributes {
          heroVideoSection {
            ...heroVideoSection
          }
        }
      }
    }
  }
  ${HeroVideoSectionFragmentDoc}
`;

/**
 * __useEcusHeroQuery__
 *
 * To run a query within a React component, call `useEcusHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcusHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcusHeroQuery({
 *   variables: {
 *   },
 * });
 */
export function useEcusHeroQuery(
  baseOptions?: Apollo.QueryHookOptions<EcusHeroQuery, EcusHeroQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EcusHeroQuery, EcusHeroQueryVariables>(
    EcusHeroDocument,
    options,
  );
}
export function useEcusHeroLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EcusHeroQuery,
    EcusHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EcusHeroQuery, EcusHeroQueryVariables>(
    EcusHeroDocument,
    options,
  );
}
export function useEcusHeroSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<EcusHeroQuery, EcusHeroQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EcusHeroQuery, EcusHeroQueryVariables>(
    EcusHeroDocument,
    options,
  );
}
export type EcusHeroQueryHookResult = ReturnType<typeof useEcusHeroQuery>;
export type EcusHeroLazyQueryHookResult = ReturnType<
  typeof useEcusHeroLazyQuery
>;
export type EcusHeroSuspenseQueryHookResult = ReturnType<
  typeof useEcusHeroSuspenseQuery
>;
export type EcusHeroQueryResult = Apollo.QueryResult<
  EcusHeroQuery,
  EcusHeroQueryVariables
>;
export const EcusCardsSectionDocument = gql`
  query EcusCardsSection {
    ecus {
      data {
        id
        attributes {
          cardsSection {
            ...cardsSection
          }
        }
      }
    }
  }
  ${CardsSectionFragmentDoc}
`;

/**
 * __useEcusCardsSectionQuery__
 *
 * To run a query within a React component, call `useEcusCardsSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcusCardsSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcusCardsSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useEcusCardsSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EcusCardsSectionQuery,
    EcusCardsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EcusCardsSectionQuery, EcusCardsSectionQueryVariables>(
    EcusCardsSectionDocument,
    options,
  );
}
export function useEcusCardsSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EcusCardsSectionQuery,
    EcusCardsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EcusCardsSectionQuery,
    EcusCardsSectionQueryVariables
  >(EcusCardsSectionDocument, options);
}
export function useEcusCardsSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        EcusCardsSectionQuery,
        EcusCardsSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EcusCardsSectionQuery,
    EcusCardsSectionQueryVariables
  >(EcusCardsSectionDocument, options);
}
export type EcusCardsSectionQueryHookResult = ReturnType<
  typeof useEcusCardsSectionQuery
>;
export type EcusCardsSectionLazyQueryHookResult = ReturnType<
  typeof useEcusCardsSectionLazyQuery
>;
export type EcusCardsSectionSuspenseQueryHookResult = ReturnType<
  typeof useEcusCardsSectionSuspenseQuery
>;
export type EcusCardsSectionQueryResult = Apollo.QueryResult<
  EcusCardsSectionQuery,
  EcusCardsSectionQueryVariables
>;
export const EcusProductImageBlockDocument = gql`
  query EcusProductImageBlock {
    ecus {
      data {
        attributes {
          productImageBlock {
            ...productImageBlock
          }
        }
      }
    }
  }
  ${ProductImageBlockFragmentDoc}
`;

/**
 * __useEcusProductImageBlockQuery__
 *
 * To run a query within a React component, call `useEcusProductImageBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcusProductImageBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcusProductImageBlockQuery({
 *   variables: {
 *   },
 * });
 */
export function useEcusProductImageBlockQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EcusProductImageBlockQuery,
    EcusProductImageBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EcusProductImageBlockQuery,
    EcusProductImageBlockQueryVariables
  >(EcusProductImageBlockDocument, options);
}
export function useEcusProductImageBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EcusProductImageBlockQuery,
    EcusProductImageBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EcusProductImageBlockQuery,
    EcusProductImageBlockQueryVariables
  >(EcusProductImageBlockDocument, options);
}
export function useEcusProductImageBlockSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        EcusProductImageBlockQuery,
        EcusProductImageBlockQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EcusProductImageBlockQuery,
    EcusProductImageBlockQueryVariables
  >(EcusProductImageBlockDocument, options);
}
export type EcusProductImageBlockQueryHookResult = ReturnType<
  typeof useEcusProductImageBlockQuery
>;
export type EcusProductImageBlockLazyQueryHookResult = ReturnType<
  typeof useEcusProductImageBlockLazyQuery
>;
export type EcusProductImageBlockSuspenseQueryHookResult = ReturnType<
  typeof useEcusProductImageBlockSuspenseQuery
>;
export type EcusProductImageBlockQueryResult = Apollo.QueryResult<
  EcusProductImageBlockQuery,
  EcusProductImageBlockQueryVariables
>;
export const EcusTwoImagesGridDocument = gql`
  query EcusTwoImagesGrid {
    ecus {
      data {
        attributes {
          twoImagesGrid {
            ...twoImagesGrid
          }
        }
      }
    }
  }
  ${TwoImagesGridFragmentDoc}
`;

/**
 * __useEcusTwoImagesGridQuery__
 *
 * To run a query within a React component, call `useEcusTwoImagesGridQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcusTwoImagesGridQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcusTwoImagesGridQuery({
 *   variables: {
 *   },
 * });
 */
export function useEcusTwoImagesGridQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EcusTwoImagesGridQuery,
    EcusTwoImagesGridQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EcusTwoImagesGridQuery,
    EcusTwoImagesGridQueryVariables
  >(EcusTwoImagesGridDocument, options);
}
export function useEcusTwoImagesGridLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EcusTwoImagesGridQuery,
    EcusTwoImagesGridQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EcusTwoImagesGridQuery,
    EcusTwoImagesGridQueryVariables
  >(EcusTwoImagesGridDocument, options);
}
export function useEcusTwoImagesGridSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        EcusTwoImagesGridQuery,
        EcusTwoImagesGridQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EcusTwoImagesGridQuery,
    EcusTwoImagesGridQueryVariables
  >(EcusTwoImagesGridDocument, options);
}
export type EcusTwoImagesGridQueryHookResult = ReturnType<
  typeof useEcusTwoImagesGridQuery
>;
export type EcusTwoImagesGridLazyQueryHookResult = ReturnType<
  typeof useEcusTwoImagesGridLazyQuery
>;
export type EcusTwoImagesGridSuspenseQueryHookResult = ReturnType<
  typeof useEcusTwoImagesGridSuspenseQuery
>;
export type EcusTwoImagesGridQueryResult = Apollo.QueryResult<
  EcusTwoImagesGridQuery,
  EcusTwoImagesGridQueryVariables
>;
export const EcusSliderImageSectionDocument = gql`
  query EcusSliderImageSection {
    ecus {
      data {
        id
        attributes {
          sliderImageSection {
            ...sliderImageSection
          }
        }
      }
    }
  }
  ${SliderImageSectionFragmentDoc}
`;

/**
 * __useEcusSliderImageSectionQuery__
 *
 * To run a query within a React component, call `useEcusSliderImageSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcusSliderImageSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcusSliderImageSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useEcusSliderImageSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EcusSliderImageSectionQuery,
    EcusSliderImageSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EcusSliderImageSectionQuery,
    EcusSliderImageSectionQueryVariables
  >(EcusSliderImageSectionDocument, options);
}
export function useEcusSliderImageSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EcusSliderImageSectionQuery,
    EcusSliderImageSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EcusSliderImageSectionQuery,
    EcusSliderImageSectionQueryVariables
  >(EcusSliderImageSectionDocument, options);
}
export function useEcusSliderImageSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        EcusSliderImageSectionQuery,
        EcusSliderImageSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EcusSliderImageSectionQuery,
    EcusSliderImageSectionQueryVariables
  >(EcusSliderImageSectionDocument, options);
}
export type EcusSliderImageSectionQueryHookResult = ReturnType<
  typeof useEcusSliderImageSectionQuery
>;
export type EcusSliderImageSectionLazyQueryHookResult = ReturnType<
  typeof useEcusSliderImageSectionLazyQuery
>;
export type EcusSliderImageSectionSuspenseQueryHookResult = ReturnType<
  typeof useEcusSliderImageSectionSuspenseQuery
>;
export type EcusSliderImageSectionQueryResult = Apollo.QueryResult<
  EcusSliderImageSectionQuery,
  EcusSliderImageSectionQueryVariables
>;
export const EcusPartnersBlogSectionDocument = gql`
  query EcusPartnersBlogSection {
    ecus {
      data {
        attributes {
          partnersBlogSection {
            ...partnersBlogSection
          }
        }
      }
    }
  }
  ${PartnersBlogSectionFragmentDoc}
`;

/**
 * __useEcusPartnersBlogSectionQuery__
 *
 * To run a query within a React component, call `useEcusPartnersBlogSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcusPartnersBlogSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcusPartnersBlogSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useEcusPartnersBlogSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EcusPartnersBlogSectionQuery,
    EcusPartnersBlogSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EcusPartnersBlogSectionQuery,
    EcusPartnersBlogSectionQueryVariables
  >(EcusPartnersBlogSectionDocument, options);
}
export function useEcusPartnersBlogSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EcusPartnersBlogSectionQuery,
    EcusPartnersBlogSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EcusPartnersBlogSectionQuery,
    EcusPartnersBlogSectionQueryVariables
  >(EcusPartnersBlogSectionDocument, options);
}
export function useEcusPartnersBlogSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        EcusPartnersBlogSectionQuery,
        EcusPartnersBlogSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EcusPartnersBlogSectionQuery,
    EcusPartnersBlogSectionQueryVariables
  >(EcusPartnersBlogSectionDocument, options);
}
export type EcusPartnersBlogSectionQueryHookResult = ReturnType<
  typeof useEcusPartnersBlogSectionQuery
>;
export type EcusPartnersBlogSectionLazyQueryHookResult = ReturnType<
  typeof useEcusPartnersBlogSectionLazyQuery
>;
export type EcusPartnersBlogSectionSuspenseQueryHookResult = ReturnType<
  typeof useEcusPartnersBlogSectionSuspenseQuery
>;
export type EcusPartnersBlogSectionQueryResult = Apollo.QueryResult<
  EcusPartnersBlogSectionQuery,
  EcusPartnersBlogSectionQueryVariables
>;
export const EcusSeoDocument = gql`
  query EcusSeo {
    ecus {
      data {
        id
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useEcusSeoQuery__
 *
 * To run a query within a React component, call `useEcusSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcusSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcusSeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useEcusSeoQuery(
  baseOptions?: Apollo.QueryHookOptions<EcusSeoQuery, EcusSeoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EcusSeoQuery, EcusSeoQueryVariables>(
    EcusSeoDocument,
    options,
  );
}
export function useEcusSeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EcusSeoQuery,
    EcusSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EcusSeoQuery, EcusSeoQueryVariables>(
    EcusSeoDocument,
    options,
  );
}
export function useEcusSeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<EcusSeoQuery, EcusSeoQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EcusSeoQuery, EcusSeoQueryVariables>(
    EcusSeoDocument,
    options,
  );
}
export type EcusSeoQueryHookResult = ReturnType<typeof useEcusSeoQuery>;
export type EcusSeoLazyQueryHookResult = ReturnType<typeof useEcusSeoLazyQuery>;
export type EcusSeoSuspenseQueryHookResult = ReturnType<
  typeof useEcusSeoSuspenseQuery
>;
export type EcusSeoQueryResult = Apollo.QueryResult<
  EcusSeoQuery,
  EcusSeoQueryVariables
>;
export const SendContactFormDocument = gql`
  mutation SendContactForm($data: ContactFormDataInput!) {
    sendContactForm(data: $data) {
      success
    }
  }
`;
export type SendContactFormMutationFn = Apollo.MutationFunction<
  SendContactFormMutation,
  SendContactFormMutationVariables
>;

/**
 * __useSendContactFormMutation__
 *
 * To run a mutation, you first call `useSendContactFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendContactFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendContactFormMutation, { data, loading, error }] = useSendContactFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendContactFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendContactFormMutation,
    SendContactFormMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendContactFormMutation,
    SendContactFormMutationVariables
  >(SendContactFormDocument, options);
}
export type SendContactFormMutationHookResult = ReturnType<
  typeof useSendContactFormMutation
>;
export type SendContactFormMutationResult =
  Apollo.MutationResult<SendContactFormMutation>;
export type SendContactFormMutationOptions = Apollo.BaseMutationOptions<
  SendContactFormMutation,
  SendContactFormMutationVariables
>;
export const HomeHeroDocument = gql`
  query HomeHero {
    home {
      data {
        id
        attributes {
          heroVideoSection {
            ...heroVideoSection
          }
        }
      }
    }
  }
  ${HeroVideoSectionFragmentDoc}
`;

/**
 * __useHomeHeroQuery__
 *
 * To run a query within a React component, call `useHomeHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeHeroQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeHeroQuery(
  baseOptions?: Apollo.QueryHookOptions<HomeHeroQuery, HomeHeroQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeHeroQuery, HomeHeroQueryVariables>(
    HomeHeroDocument,
    options,
  );
}
export function useHomeHeroLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeHeroQuery,
    HomeHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeHeroQuery, HomeHeroQueryVariables>(
    HomeHeroDocument,
    options,
  );
}
export function useHomeHeroSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<HomeHeroQuery, HomeHeroQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HomeHeroQuery, HomeHeroQueryVariables>(
    HomeHeroDocument,
    options,
  );
}
export type HomeHeroQueryHookResult = ReturnType<typeof useHomeHeroQuery>;
export type HomeHeroLazyQueryHookResult = ReturnType<
  typeof useHomeHeroLazyQuery
>;
export type HomeHeroSuspenseQueryHookResult = ReturnType<
  typeof useHomeHeroSuspenseQuery
>;
export type HomeHeroQueryResult = Apollo.QueryResult<
  HomeHeroQuery,
  HomeHeroQueryVariables
>;
export const HomeCardsWithLinkDocument = gql`
  query HomeCardsWithLink {
    home {
      data {
        id
        attributes {
          imageCardsWithLink {
            ...imageCardsWithLink
          }
        }
      }
    }
  }
  ${ImageCardsWithLinkFragmentDoc}
`;

/**
 * __useHomeCardsWithLinkQuery__
 *
 * To run a query within a React component, call `useHomeCardsWithLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeCardsWithLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeCardsWithLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeCardsWithLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomeCardsWithLinkQuery,
    HomeCardsWithLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HomeCardsWithLinkQuery,
    HomeCardsWithLinkQueryVariables
  >(HomeCardsWithLinkDocument, options);
}
export function useHomeCardsWithLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeCardsWithLinkQuery,
    HomeCardsWithLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HomeCardsWithLinkQuery,
    HomeCardsWithLinkQueryVariables
  >(HomeCardsWithLinkDocument, options);
}
export function useHomeCardsWithLinkSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        HomeCardsWithLinkQuery,
        HomeCardsWithLinkQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HomeCardsWithLinkQuery,
    HomeCardsWithLinkQueryVariables
  >(HomeCardsWithLinkDocument, options);
}
export type HomeCardsWithLinkQueryHookResult = ReturnType<
  typeof useHomeCardsWithLinkQuery
>;
export type HomeCardsWithLinkLazyQueryHookResult = ReturnType<
  typeof useHomeCardsWithLinkLazyQuery
>;
export type HomeCardsWithLinkSuspenseQueryHookResult = ReturnType<
  typeof useHomeCardsWithLinkSuspenseQuery
>;
export type HomeCardsWithLinkQueryResult = Apollo.QueryResult<
  HomeCardsWithLinkQuery,
  HomeCardsWithLinkQueryVariables
>;
export const HomeLargeImageDocument = gql`
  query HomeLargeImage {
    home {
      data {
        attributes {
          largeImageSection {
            ...largeImageSection
          }
        }
      }
    }
  }
  ${LargeImageSectionFragmentDoc}
`;

/**
 * __useHomeLargeImageQuery__
 *
 * To run a query within a React component, call `useHomeLargeImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeLargeImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeLargeImageQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeLargeImageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomeLargeImageQuery,
    HomeLargeImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeLargeImageQuery, HomeLargeImageQueryVariables>(
    HomeLargeImageDocument,
    options,
  );
}
export function useHomeLargeImageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeLargeImageQuery,
    HomeLargeImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeLargeImageQuery, HomeLargeImageQueryVariables>(
    HomeLargeImageDocument,
    options,
  );
}
export function useHomeLargeImageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        HomeLargeImageQuery,
        HomeLargeImageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HomeLargeImageQuery,
    HomeLargeImageQueryVariables
  >(HomeLargeImageDocument, options);
}
export type HomeLargeImageQueryHookResult = ReturnType<
  typeof useHomeLargeImageQuery
>;
export type HomeLargeImageLazyQueryHookResult = ReturnType<
  typeof useHomeLargeImageLazyQuery
>;
export type HomeLargeImageSuspenseQueryHookResult = ReturnType<
  typeof useHomeLargeImageSuspenseQuery
>;
export type HomeLargeImageQueryResult = Apollo.QueryResult<
  HomeLargeImageQuery,
  HomeLargeImageQueryVariables
>;
export const HomePartnersBlogSectionDocument = gql`
  query HomePartnersBlogSection {
    home {
      data {
        attributes {
          partnersBlogSection {
            ...partnersBlogSection
          }
        }
      }
    }
  }
  ${PartnersBlogSectionFragmentDoc}
`;

/**
 * __useHomePartnersBlogSectionQuery__
 *
 * To run a query within a React component, call `useHomePartnersBlogSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePartnersBlogSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePartnersBlogSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomePartnersBlogSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomePartnersBlogSectionQuery,
    HomePartnersBlogSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HomePartnersBlogSectionQuery,
    HomePartnersBlogSectionQueryVariables
  >(HomePartnersBlogSectionDocument, options);
}
export function useHomePartnersBlogSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomePartnersBlogSectionQuery,
    HomePartnersBlogSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HomePartnersBlogSectionQuery,
    HomePartnersBlogSectionQueryVariables
  >(HomePartnersBlogSectionDocument, options);
}
export function useHomePartnersBlogSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        HomePartnersBlogSectionQuery,
        HomePartnersBlogSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HomePartnersBlogSectionQuery,
    HomePartnersBlogSectionQueryVariables
  >(HomePartnersBlogSectionDocument, options);
}
export type HomePartnersBlogSectionQueryHookResult = ReturnType<
  typeof useHomePartnersBlogSectionQuery
>;
export type HomePartnersBlogSectionLazyQueryHookResult = ReturnType<
  typeof useHomePartnersBlogSectionLazyQuery
>;
export type HomePartnersBlogSectionSuspenseQueryHookResult = ReturnType<
  typeof useHomePartnersBlogSectionSuspenseQuery
>;
export type HomePartnersBlogSectionQueryResult = Apollo.QueryResult<
  HomePartnersBlogSectionQuery,
  HomePartnersBlogSectionQueryVariables
>;
export const HomeSeoDocument = gql`
  query homeSeo {
    home {
      data {
        id
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useHomeSeoQuery__
 *
 * To run a query within a React component, call `useHomeSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeSeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeSeoQuery(
  baseOptions?: Apollo.QueryHookOptions<HomeSeoQuery, HomeSeoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeSeoQuery, HomeSeoQueryVariables>(
    HomeSeoDocument,
    options,
  );
}
export function useHomeSeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeSeoQuery,
    HomeSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeSeoQuery, HomeSeoQueryVariables>(
    HomeSeoDocument,
    options,
  );
}
export function useHomeSeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<HomeSeoQuery, HomeSeoQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HomeSeoQuery, HomeSeoQueryVariables>(
    HomeSeoDocument,
    options,
  );
}
export type HomeSeoQueryHookResult = ReturnType<typeof useHomeSeoQuery>;
export type HomeSeoLazyQueryHookResult = ReturnType<typeof useHomeSeoLazyQuery>;
export type HomeSeoSuspenseQueryHookResult = ReturnType<
  typeof useHomeSeoSuspenseQuery
>;
export type HomeSeoQueryResult = Apollo.QueryResult<
  HomeSeoQuery,
  HomeSeoQueryVariables
>;
export const FooterDocument = gql`
  query Footer {
    footer {
      data {
        id
        attributes {
          column {
            label
            link {
              ...footerLink
            }
          }
          policies {
            link {
              ...footerLink
            }
            label
          }
          socials {
            label
            instagram
            linkedin
            tiktok
            youtube
          }
          contact {
            title
            address {
              ...footerLink
            }
            email {
              ...footerLink
            }
          }
        }
      }
    }
  }
  ${FooterLinkFragmentDoc}
`;

/**
 * __useFooterQuery__
 *
 * To run a query within a React component, call `useFooterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFooterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFooterQuery({
 *   variables: {
 *   },
 * });
 */
export function useFooterQuery(
  baseOptions?: Apollo.QueryHookOptions<FooterQuery, FooterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FooterQuery, FooterQueryVariables>(
    FooterDocument,
    options,
  );
}
export function useFooterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FooterQuery, FooterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FooterQuery, FooterQueryVariables>(
    FooterDocument,
    options,
  );
}
export function useFooterSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FooterQuery, FooterQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FooterQuery, FooterQueryVariables>(
    FooterDocument,
    options,
  );
}
export type FooterQueryHookResult = ReturnType<typeof useFooterQuery>;
export type FooterLazyQueryHookResult = ReturnType<typeof useFooterLazyQuery>;
export type FooterSuspenseQueryHookResult = ReturnType<
  typeof useFooterSuspenseQuery
>;
export type FooterQueryResult = Apollo.QueryResult<
  FooterQuery,
  FooterQueryVariables
>;
export const NavigationDocument = gql`
  query Navigation {
    navigation {
      data {
        attributes {
          closedMenuLinks {
            ...navLink
          }
          openMenuColumns {
            ...openMenuColumns
          }
          companyContactInfo {
            name
            address
          }
        }
      }
    }
  }
  ${NavLinkFragmentDoc}
  ${OpenMenuColumnsFragmentDoc}
`;

/**
 * __useNavigationQuery__
 *
 * To run a query within a React component, call `useNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationQuery({
 *   variables: {
 *   },
 * });
 */
export function useNavigationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NavigationQuery,
    NavigationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NavigationQuery, NavigationQueryVariables>(
    NavigationDocument,
    options,
  );
}
export function useNavigationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NavigationQuery,
    NavigationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NavigationQuery, NavigationQueryVariables>(
    NavigationDocument,
    options,
  );
}
export function useNavigationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        NavigationQuery,
        NavigationQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NavigationQuery, NavigationQueryVariables>(
    NavigationDocument,
    options,
  );
}
export type NavigationQueryHookResult = ReturnType<typeof useNavigationQuery>;
export type NavigationLazyQueryHookResult = ReturnType<
  typeof useNavigationLazyQuery
>;
export type NavigationSuspenseQueryHookResult = ReturnType<
  typeof useNavigationSuspenseQuery
>;
export type NavigationQueryResult = Apollo.QueryResult<
  NavigationQuery,
  NavigationQueryVariables
>;
export const FormDocument = gql`
  query Form {
    getInTouch {
      data {
        id
        attributes {
          anchor
          title
          first_line
          second_line
          options {
            label
            value
          }
        }
      }
    }
  }
`;

/**
 * __useFormQuery__
 *
 * To run a query within a React component, call `useFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useFormQuery(
  baseOptions?: Apollo.QueryHookOptions<FormQuery, FormQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FormQuery, FormQueryVariables>(FormDocument, options);
}
export function useFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FormQuery, FormQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FormQuery, FormQueryVariables>(
    FormDocument,
    options,
  );
}
export function useFormSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FormQuery, FormQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FormQuery, FormQueryVariables>(
    FormDocument,
    options,
  );
}
export type FormQueryHookResult = ReturnType<typeof useFormQuery>;
export type FormLazyQueryHookResult = ReturnType<typeof useFormLazyQuery>;
export type FormSuspenseQueryHookResult = ReturnType<
  typeof useFormSuspenseQuery
>;
export type FormQueryResult = Apollo.QueryResult<FormQuery, FormQueryVariables>;
export const PrivacyPolicyDocument = gql`
  query PrivacyPolicy {
    privacyPolicy {
      data {
        attributes {
          policies {
            ...policyFields
          }
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${PolicyFieldsFragmentDoc}
  ${SeoFragmentDoc}
`;

/**
 * __usePrivacyPolicyQuery__
 *
 * To run a query within a React component, call `usePrivacyPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivacyPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivacyPolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrivacyPolicyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PrivacyPolicyQuery,
    PrivacyPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(
    PrivacyPolicyDocument,
    options,
  );
}
export function usePrivacyPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PrivacyPolicyQuery,
    PrivacyPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(
    PrivacyPolicyDocument,
    options,
  );
}
export function usePrivacyPolicySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PrivacyPolicyQuery,
        PrivacyPolicyQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PrivacyPolicyQuery,
    PrivacyPolicyQueryVariables
  >(PrivacyPolicyDocument, options);
}
export type PrivacyPolicyQueryHookResult = ReturnType<
  typeof usePrivacyPolicyQuery
>;
export type PrivacyPolicyLazyQueryHookResult = ReturnType<
  typeof usePrivacyPolicyLazyQuery
>;
export type PrivacyPolicySuspenseQueryHookResult = ReturnType<
  typeof usePrivacyPolicySuspenseQuery
>;
export type PrivacyPolicyQueryResult = Apollo.QueryResult<
  PrivacyPolicyQuery,
  PrivacyPolicyQueryVariables
>;
export const LegalNoticeDocument = gql`
  query LegalNotice {
    legalNotice {
      data {
        attributes {
          legalNotice {
            ...policyFields
          }
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${PolicyFieldsFragmentDoc}
  ${SeoFragmentDoc}
`;

/**
 * __useLegalNoticeQuery__
 *
 * To run a query within a React component, call `useLegalNoticeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegalNoticeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegalNoticeQuery({
 *   variables: {
 *   },
 * });
 */
export function useLegalNoticeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LegalNoticeQuery,
    LegalNoticeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LegalNoticeQuery, LegalNoticeQueryVariables>(
    LegalNoticeDocument,
    options,
  );
}
export function useLegalNoticeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LegalNoticeQuery,
    LegalNoticeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LegalNoticeQuery, LegalNoticeQueryVariables>(
    LegalNoticeDocument,
    options,
  );
}
export function useLegalNoticeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LegalNoticeQuery,
        LegalNoticeQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LegalNoticeQuery, LegalNoticeQueryVariables>(
    LegalNoticeDocument,
    options,
  );
}
export type LegalNoticeQueryHookResult = ReturnType<typeof useLegalNoticeQuery>;
export type LegalNoticeLazyQueryHookResult = ReturnType<
  typeof useLegalNoticeLazyQuery
>;
export type LegalNoticeSuspenseQueryHookResult = ReturnType<
  typeof useLegalNoticeSuspenseQuery
>;
export type LegalNoticeQueryResult = Apollo.QueryResult<
  LegalNoticeQuery,
  LegalNoticeQueryVariables
>;
export const MlBattProtEuProjectDocument = gql`
  query MlBattProtEuProject {
    mlBattProtEuProject {
      data {
        attributes {
          contents {
            ...policyFields
          }
          logos {
            ...image
          }
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${PolicyFieldsFragmentDoc}
  ${ImageFragmentDoc}
  ${SeoFragmentDoc}
`;

/**
 * __useMlBattProtEuProjectQuery__
 *
 * To run a query within a React component, call `useMlBattProtEuProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useMlBattProtEuProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMlBattProtEuProjectQuery({
 *   variables: {
 *   },
 * });
 */
export function useMlBattProtEuProjectQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MlBattProtEuProjectQuery,
    MlBattProtEuProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MlBattProtEuProjectQuery,
    MlBattProtEuProjectQueryVariables
  >(MlBattProtEuProjectDocument, options);
}
export function useMlBattProtEuProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MlBattProtEuProjectQuery,
    MlBattProtEuProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MlBattProtEuProjectQuery,
    MlBattProtEuProjectQueryVariables
  >(MlBattProtEuProjectDocument, options);
}
export function useMlBattProtEuProjectSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MlBattProtEuProjectQuery,
        MlBattProtEuProjectQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MlBattProtEuProjectQuery,
    MlBattProtEuProjectQueryVariables
  >(MlBattProtEuProjectDocument, options);
}
export type MlBattProtEuProjectQueryHookResult = ReturnType<
  typeof useMlBattProtEuProjectQuery
>;
export type MlBattProtEuProjectLazyQueryHookResult = ReturnType<
  typeof useMlBattProtEuProjectLazyQuery
>;
export type MlBattProtEuProjectSuspenseQueryHookResult = ReturnType<
  typeof useMlBattProtEuProjectSuspenseQuery
>;
export type MlBattProtEuProjectQueryResult = Apollo.QueryResult<
  MlBattProtEuProjectQuery,
  MlBattProtEuProjectQueryVariables
>;
export const CookiePolicyDocument = gql`
  query CookiePolicy {
    cookiePolicy {
      data {
        attributes {
          cookiePolicy {
            ... on CookiePolicyCookiePolicyDynamicZone {
              ...policyFields
            }
          }
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${PolicyFieldsFragmentDoc}
  ${SeoFragmentDoc}
`;

/**
 * __useCookiePolicyQuery__
 *
 * To run a query within a React component, call `useCookiePolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCookiePolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCookiePolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function useCookiePolicyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CookiePolicyQuery,
    CookiePolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CookiePolicyQuery, CookiePolicyQueryVariables>(
    CookiePolicyDocument,
    options,
  );
}
export function useCookiePolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CookiePolicyQuery,
    CookiePolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CookiePolicyQuery, CookiePolicyQueryVariables>(
    CookiePolicyDocument,
    options,
  );
}
export function useCookiePolicySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CookiePolicyQuery,
        CookiePolicyQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CookiePolicyQuery, CookiePolicyQueryVariables>(
    CookiePolicyDocument,
    options,
  );
}
export type CookiePolicyQueryHookResult = ReturnType<
  typeof useCookiePolicyQuery
>;
export type CookiePolicyLazyQueryHookResult = ReturnType<
  typeof useCookiePolicyLazyQuery
>;
export type CookiePolicySuspenseQueryHookResult = ReturnType<
  typeof useCookiePolicySuspenseQuery
>;
export type CookiePolicyQueryResult = Apollo.QueryResult<
  CookiePolicyQuery,
  CookiePolicyQueryVariables
>;
export const SupplierPortalDocument = gql`
  query SupplierPortal {
    supplierPortal {
      data {
        attributes {
          policies {
            ... on SupplierPortalPoliciesDynamicZone {
              ...policyFields
            }
          }
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${PolicyFieldsFragmentDoc}
  ${SeoFragmentDoc}
`;

/**
 * __useSupplierPortalQuery__
 *
 * To run a query within a React component, call `useSupplierPortalQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierPortalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierPortalQuery({
 *   variables: {
 *   },
 * });
 */
export function useSupplierPortalQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SupplierPortalQuery,
    SupplierPortalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SupplierPortalQuery, SupplierPortalQueryVariables>(
    SupplierPortalDocument,
    options,
  );
}
export function useSupplierPortalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SupplierPortalQuery,
    SupplierPortalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SupplierPortalQuery, SupplierPortalQueryVariables>(
    SupplierPortalDocument,
    options,
  );
}
export function useSupplierPortalSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SupplierPortalQuery,
        SupplierPortalQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SupplierPortalQuery,
    SupplierPortalQueryVariables
  >(SupplierPortalDocument, options);
}
export type SupplierPortalQueryHookResult = ReturnType<
  typeof useSupplierPortalQuery
>;
export type SupplierPortalLazyQueryHookResult = ReturnType<
  typeof useSupplierPortalLazyQuery
>;
export type SupplierPortalSuspenseQueryHookResult = ReturnType<
  typeof useSupplierPortalSuspenseQuery
>;
export type SupplierPortalQueryResult = Apollo.QueryResult<
  SupplierPortalQuery,
  SupplierPortalQueryVariables
>;
export const WhistleblowingPolicyDocument = gql`
  query WhistleblowingPolicy {
    whistleblowingPolicy {
      data {
        attributes {
          policies {
            ... on WhistleblowingPolicyPoliciesDynamicZone {
              ...policyFields
            }
          }
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${PolicyFieldsFragmentDoc}
  ${SeoFragmentDoc}
`;

/**
 * __useWhistleblowingPolicyQuery__
 *
 * To run a query within a React component, call `useWhistleblowingPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhistleblowingPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhistleblowingPolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhistleblowingPolicyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WhistleblowingPolicyQuery,
    WhistleblowingPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WhistleblowingPolicyQuery,
    WhistleblowingPolicyQueryVariables
  >(WhistleblowingPolicyDocument, options);
}
export function useWhistleblowingPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WhistleblowingPolicyQuery,
    WhistleblowingPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WhistleblowingPolicyQuery,
    WhistleblowingPolicyQueryVariables
  >(WhistleblowingPolicyDocument, options);
}
export function useWhistleblowingPolicySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WhistleblowingPolicyQuery,
        WhistleblowingPolicyQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WhistleblowingPolicyQuery,
    WhistleblowingPolicyQueryVariables
  >(WhistleblowingPolicyDocument, options);
}
export type WhistleblowingPolicyQueryHookResult = ReturnType<
  typeof useWhistleblowingPolicyQuery
>;
export type WhistleblowingPolicyLazyQueryHookResult = ReturnType<
  typeof useWhistleblowingPolicyLazyQuery
>;
export type WhistleblowingPolicySuspenseQueryHookResult = ReturnType<
  typeof useWhistleblowingPolicySuspenseQuery
>;
export type WhistleblowingPolicyQueryResult = Apollo.QueryResult<
  WhistleblowingPolicyQuery,
  WhistleblowingPolicyQueryVariables
>;
export const CookiesSettingsDocument = gql`
  query CookiesSettings {
    cookieSetting {
      data {
        id
        attributes {
          actionButtonLabel
          necessaryCookiesHeading
          necessaryCookiesTable {
            richText
            tableHead {
              headingItem
            }
            tableRow {
              table_rows {
                data {
                  attributes {
                    rows {
                      item
                    }
                  }
                }
              }
            }
          }
          optionalCookiesHeading
          optionalCookiesTale {
            richText
            tableHead {
              headingItem
            }
            tableRow {
              table_rows {
                data {
                  attributes {
                    rows {
                      item
                    }
                  }
                }
              }
            }
          }
          title
          redirectButton {
            label
            path
          }
        }
      }
    }
  }
`;

/**
 * __useCookiesSettingsQuery__
 *
 * To run a query within a React component, call `useCookiesSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCookiesSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCookiesSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCookiesSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CookiesSettingsQuery,
    CookiesSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CookiesSettingsQuery, CookiesSettingsQueryVariables>(
    CookiesSettingsDocument,
    options,
  );
}
export function useCookiesSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CookiesSettingsQuery,
    CookiesSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CookiesSettingsQuery,
    CookiesSettingsQueryVariables
  >(CookiesSettingsDocument, options);
}
export function useCookiesSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CookiesSettingsQuery,
        CookiesSettingsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CookiesSettingsQuery,
    CookiesSettingsQueryVariables
  >(CookiesSettingsDocument, options);
}
export type CookiesSettingsQueryHookResult = ReturnType<
  typeof useCookiesSettingsQuery
>;
export type CookiesSettingsLazyQueryHookResult = ReturnType<
  typeof useCookiesSettingsLazyQuery
>;
export type CookiesSettingsSuspenseQueryHookResult = ReturnType<
  typeof useCookiesSettingsSuspenseQuery
>;
export type CookiesSettingsQueryResult = Apollo.QueryResult<
  CookiesSettingsQuery,
  CookiesSettingsQueryVariables
>;
export const CookiesBarDocument = gql`
  query CookiesBar {
    cookiesBanner {
      data {
        id
        attributes {
          heading
          editButtonLabel
          declineButtonLabel
          acceptButtonLabel
          text
          extraLinks {
            ...button
          }
        }
      }
    }
  }
  ${ButtonFragmentDoc}
`;

/**
 * __useCookiesBarQuery__
 *
 * To run a query within a React component, call `useCookiesBarQuery` and pass it any options that fit your needs.
 * When your component renders, `useCookiesBarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCookiesBarQuery({
 *   variables: {
 *   },
 * });
 */
export function useCookiesBarQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CookiesBarQuery,
    CookiesBarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CookiesBarQuery, CookiesBarQueryVariables>(
    CookiesBarDocument,
    options,
  );
}
export function useCookiesBarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CookiesBarQuery,
    CookiesBarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CookiesBarQuery, CookiesBarQueryVariables>(
    CookiesBarDocument,
    options,
  );
}
export function useCookiesBarSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CookiesBarQuery,
        CookiesBarQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CookiesBarQuery, CookiesBarQueryVariables>(
    CookiesBarDocument,
    options,
  );
}
export type CookiesBarQueryHookResult = ReturnType<typeof useCookiesBarQuery>;
export type CookiesBarLazyQueryHookResult = ReturnType<
  typeof useCookiesBarLazyQuery
>;
export type CookiesBarSuspenseQueryHookResult = ReturnType<
  typeof useCookiesBarSuspenseQuery
>;
export type CookiesBarQueryResult = Apollo.QueryResult<
  CookiesBarQuery,
  CookiesBarQueryVariables
>;
export const PowertrainHeroDocument = gql`
  query PowertrainHero {
    powertrain {
      data {
        id
        attributes {
          heroVideoSection {
            ...heroVideoSection
          }
        }
      }
    }
  }
  ${HeroVideoSectionFragmentDoc}
`;

/**
 * __usePowertrainHeroQuery__
 *
 * To run a query within a React component, call `usePowertrainHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `usePowertrainHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowertrainHeroQuery({
 *   variables: {
 *   },
 * });
 */
export function usePowertrainHeroQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PowertrainHeroQuery,
    PowertrainHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PowertrainHeroQuery, PowertrainHeroQueryVariables>(
    PowertrainHeroDocument,
    options,
  );
}
export function usePowertrainHeroLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PowertrainHeroQuery,
    PowertrainHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PowertrainHeroQuery, PowertrainHeroQueryVariables>(
    PowertrainHeroDocument,
    options,
  );
}
export function usePowertrainHeroSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PowertrainHeroQuery,
        PowertrainHeroQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PowertrainHeroQuery,
    PowertrainHeroQueryVariables
  >(PowertrainHeroDocument, options);
}
export type PowertrainHeroQueryHookResult = ReturnType<
  typeof usePowertrainHeroQuery
>;
export type PowertrainHeroLazyQueryHookResult = ReturnType<
  typeof usePowertrainHeroLazyQuery
>;
export type PowertrainHeroSuspenseQueryHookResult = ReturnType<
  typeof usePowertrainHeroSuspenseQuery
>;
export type PowertrainHeroQueryResult = Apollo.QueryResult<
  PowertrainHeroQuery,
  PowertrainHeroQueryVariables
>;
export const PowertrainCardsSectionDocument = gql`
  query PowertrainCardsSection {
    powertrain {
      data {
        id
        attributes {
          cardsSection {
            ...cardsSection
          }
        }
      }
    }
  }
  ${CardsSectionFragmentDoc}
`;

/**
 * __usePowertrainCardsSectionQuery__
 *
 * To run a query within a React component, call `usePowertrainCardsSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePowertrainCardsSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowertrainCardsSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function usePowertrainCardsSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PowertrainCardsSectionQuery,
    PowertrainCardsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PowertrainCardsSectionQuery,
    PowertrainCardsSectionQueryVariables
  >(PowertrainCardsSectionDocument, options);
}
export function usePowertrainCardsSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PowertrainCardsSectionQuery,
    PowertrainCardsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PowertrainCardsSectionQuery,
    PowertrainCardsSectionQueryVariables
  >(PowertrainCardsSectionDocument, options);
}
export function usePowertrainCardsSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PowertrainCardsSectionQuery,
        PowertrainCardsSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PowertrainCardsSectionQuery,
    PowertrainCardsSectionQueryVariables
  >(PowertrainCardsSectionDocument, options);
}
export type PowertrainCardsSectionQueryHookResult = ReturnType<
  typeof usePowertrainCardsSectionQuery
>;
export type PowertrainCardsSectionLazyQueryHookResult = ReturnType<
  typeof usePowertrainCardsSectionLazyQuery
>;
export type PowertrainCardsSectionSuspenseQueryHookResult = ReturnType<
  typeof usePowertrainCardsSectionSuspenseQuery
>;
export type PowertrainCardsSectionQueryResult = Apollo.QueryResult<
  PowertrainCardsSectionQuery,
  PowertrainCardsSectionQueryVariables
>;
export const PowertrainSpecsDocument = gql`
  query PowertrainSpecs {
    powertrain {
      data {
        id
        attributes {
          technologies {
            ...powertrainSpecs
          }
        }
      }
    }
  }
  ${PowertrainSpecsFragmentDoc}
`;

/**
 * __usePowertrainSpecsQuery__
 *
 * To run a query within a React component, call `usePowertrainSpecsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePowertrainSpecsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowertrainSpecsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePowertrainSpecsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PowertrainSpecsQuery,
    PowertrainSpecsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PowertrainSpecsQuery, PowertrainSpecsQueryVariables>(
    PowertrainSpecsDocument,
    options,
  );
}
export function usePowertrainSpecsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PowertrainSpecsQuery,
    PowertrainSpecsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PowertrainSpecsQuery,
    PowertrainSpecsQueryVariables
  >(PowertrainSpecsDocument, options);
}
export function usePowertrainSpecsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PowertrainSpecsQuery,
        PowertrainSpecsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PowertrainSpecsQuery,
    PowertrainSpecsQueryVariables
  >(PowertrainSpecsDocument, options);
}
export type PowertrainSpecsQueryHookResult = ReturnType<
  typeof usePowertrainSpecsQuery
>;
export type PowertrainSpecsLazyQueryHookResult = ReturnType<
  typeof usePowertrainSpecsLazyQuery
>;
export type PowertrainSpecsSuspenseQueryHookResult = ReturnType<
  typeof usePowertrainSpecsSuspenseQuery
>;
export type PowertrainSpecsQueryResult = Apollo.QueryResult<
  PowertrainSpecsQuery,
  PowertrainSpecsQueryVariables
>;
export const PowertrainProductSpecsBlocksDocument = gql`
  query PowertrainProductSpecsBlocks {
    powertrain {
      data {
        attributes {
          productSpecsBlocks {
            ...productSpecsBlock
          }
        }
      }
    }
  }
  ${ProductSpecsBlockFragmentDoc}
`;

/**
 * __usePowertrainProductSpecsBlocksQuery__
 *
 * To run a query within a React component, call `usePowertrainProductSpecsBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `usePowertrainProductSpecsBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowertrainProductSpecsBlocksQuery({
 *   variables: {
 *   },
 * });
 */
export function usePowertrainProductSpecsBlocksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PowertrainProductSpecsBlocksQuery,
    PowertrainProductSpecsBlocksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PowertrainProductSpecsBlocksQuery,
    PowertrainProductSpecsBlocksQueryVariables
  >(PowertrainProductSpecsBlocksDocument, options);
}
export function usePowertrainProductSpecsBlocksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PowertrainProductSpecsBlocksQuery,
    PowertrainProductSpecsBlocksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PowertrainProductSpecsBlocksQuery,
    PowertrainProductSpecsBlocksQueryVariables
  >(PowertrainProductSpecsBlocksDocument, options);
}
export function usePowertrainProductSpecsBlocksSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PowertrainProductSpecsBlocksQuery,
        PowertrainProductSpecsBlocksQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PowertrainProductSpecsBlocksQuery,
    PowertrainProductSpecsBlocksQueryVariables
  >(PowertrainProductSpecsBlocksDocument, options);
}
export type PowertrainProductSpecsBlocksQueryHookResult = ReturnType<
  typeof usePowertrainProductSpecsBlocksQuery
>;
export type PowertrainProductSpecsBlocksLazyQueryHookResult = ReturnType<
  typeof usePowertrainProductSpecsBlocksLazyQuery
>;
export type PowertrainProductSpecsBlocksSuspenseQueryHookResult = ReturnType<
  typeof usePowertrainProductSpecsBlocksSuspenseQuery
>;
export type PowertrainProductSpecsBlocksQueryResult = Apollo.QueryResult<
  PowertrainProductSpecsBlocksQuery,
  PowertrainProductSpecsBlocksQueryVariables
>;
export const PowertrainPartnersBlogSectionDocument = gql`
  query PowertrainPartnersBlogSection {
    powertrain {
      data {
        attributes {
          partnersBlogSection {
            ...partnersBlogSection
          }
        }
      }
    }
  }
  ${PartnersBlogSectionFragmentDoc}
`;

/**
 * __usePowertrainPartnersBlogSectionQuery__
 *
 * To run a query within a React component, call `usePowertrainPartnersBlogSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePowertrainPartnersBlogSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowertrainPartnersBlogSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function usePowertrainPartnersBlogSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PowertrainPartnersBlogSectionQuery,
    PowertrainPartnersBlogSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PowertrainPartnersBlogSectionQuery,
    PowertrainPartnersBlogSectionQueryVariables
  >(PowertrainPartnersBlogSectionDocument, options);
}
export function usePowertrainPartnersBlogSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PowertrainPartnersBlogSectionQuery,
    PowertrainPartnersBlogSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PowertrainPartnersBlogSectionQuery,
    PowertrainPartnersBlogSectionQueryVariables
  >(PowertrainPartnersBlogSectionDocument, options);
}
export function usePowertrainPartnersBlogSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PowertrainPartnersBlogSectionQuery,
        PowertrainPartnersBlogSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PowertrainPartnersBlogSectionQuery,
    PowertrainPartnersBlogSectionQueryVariables
  >(PowertrainPartnersBlogSectionDocument, options);
}
export type PowertrainPartnersBlogSectionQueryHookResult = ReturnType<
  typeof usePowertrainPartnersBlogSectionQuery
>;
export type PowertrainPartnersBlogSectionLazyQueryHookResult = ReturnType<
  typeof usePowertrainPartnersBlogSectionLazyQuery
>;
export type PowertrainPartnersBlogSectionSuspenseQueryHookResult = ReturnType<
  typeof usePowertrainPartnersBlogSectionSuspenseQuery
>;
export type PowertrainPartnersBlogSectionQueryResult = Apollo.QueryResult<
  PowertrainPartnersBlogSectionQuery,
  PowertrainPartnersBlogSectionQueryVariables
>;
export const PowertrainSeoDocument = gql`
  query PowertrainSeo {
    powertrain {
      data {
        id
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __usePowertrainSeoQuery__
 *
 * To run a query within a React component, call `usePowertrainSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePowertrainSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowertrainSeoQuery({
 *   variables: {
 *   },
 * });
 */
export function usePowertrainSeoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PowertrainSeoQuery,
    PowertrainSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PowertrainSeoQuery, PowertrainSeoQueryVariables>(
    PowertrainSeoDocument,
    options,
  );
}
export function usePowertrainSeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PowertrainSeoQuery,
    PowertrainSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PowertrainSeoQuery, PowertrainSeoQueryVariables>(
    PowertrainSeoDocument,
    options,
  );
}
export function usePowertrainSeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PowertrainSeoQuery,
        PowertrainSeoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PowertrainSeoQuery,
    PowertrainSeoQueryVariables
  >(PowertrainSeoDocument, options);
}
export type PowertrainSeoQueryHookResult = ReturnType<
  typeof usePowertrainSeoQuery
>;
export type PowertrainSeoLazyQueryHookResult = ReturnType<
  typeof usePowertrainSeoLazyQuery
>;
export type PowertrainSeoSuspenseQueryHookResult = ReturnType<
  typeof usePowertrainSeoSuspenseQuery
>;
export type PowertrainSeoQueryResult = Apollo.QueryResult<
  PowertrainSeoQuery,
  PowertrainSeoQueryVariables
>;
export const SoftwareHeroDocument = gql`
  query SoftwareHero {
    software {
      data {
        id
        attributes {
          heroVideoSection {
            ...heroVideoSection
          }
        }
      }
    }
  }
  ${HeroVideoSectionFragmentDoc}
`;

/**
 * __useSoftwareHeroQuery__
 *
 * To run a query within a React component, call `useSoftwareHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoftwareHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoftwareHeroQuery({
 *   variables: {
 *   },
 * });
 */
export function useSoftwareHeroQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SoftwareHeroQuery,
    SoftwareHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SoftwareHeroQuery, SoftwareHeroQueryVariables>(
    SoftwareHeroDocument,
    options,
  );
}
export function useSoftwareHeroLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SoftwareHeroQuery,
    SoftwareHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SoftwareHeroQuery, SoftwareHeroQueryVariables>(
    SoftwareHeroDocument,
    options,
  );
}
export function useSoftwareHeroSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SoftwareHeroQuery,
        SoftwareHeroQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SoftwareHeroQuery, SoftwareHeroQueryVariables>(
    SoftwareHeroDocument,
    options,
  );
}
export type SoftwareHeroQueryHookResult = ReturnType<
  typeof useSoftwareHeroQuery
>;
export type SoftwareHeroLazyQueryHookResult = ReturnType<
  typeof useSoftwareHeroLazyQuery
>;
export type SoftwareHeroSuspenseQueryHookResult = ReturnType<
  typeof useSoftwareHeroSuspenseQuery
>;
export type SoftwareHeroQueryResult = Apollo.QueryResult<
  SoftwareHeroQuery,
  SoftwareHeroQueryVariables
>;
export const SoftwareProductImageBlockDocument = gql`
  query SoftwareProductImageBlock {
    software {
      data {
        id
        attributes {
          productImageBlock {
            ...productImageBlock
          }
        }
      }
    }
  }
  ${ProductImageBlockFragmentDoc}
`;

/**
 * __useSoftwareProductImageBlockQuery__
 *
 * To run a query within a React component, call `useSoftwareProductImageBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoftwareProductImageBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoftwareProductImageBlockQuery({
 *   variables: {
 *   },
 * });
 */
export function useSoftwareProductImageBlockQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SoftwareProductImageBlockQuery,
    SoftwareProductImageBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SoftwareProductImageBlockQuery,
    SoftwareProductImageBlockQueryVariables
  >(SoftwareProductImageBlockDocument, options);
}
export function useSoftwareProductImageBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SoftwareProductImageBlockQuery,
    SoftwareProductImageBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SoftwareProductImageBlockQuery,
    SoftwareProductImageBlockQueryVariables
  >(SoftwareProductImageBlockDocument, options);
}
export function useSoftwareProductImageBlockSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SoftwareProductImageBlockQuery,
        SoftwareProductImageBlockQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SoftwareProductImageBlockQuery,
    SoftwareProductImageBlockQueryVariables
  >(SoftwareProductImageBlockDocument, options);
}
export type SoftwareProductImageBlockQueryHookResult = ReturnType<
  typeof useSoftwareProductImageBlockQuery
>;
export type SoftwareProductImageBlockLazyQueryHookResult = ReturnType<
  typeof useSoftwareProductImageBlockLazyQuery
>;
export type SoftwareProductImageBlockSuspenseQueryHookResult = ReturnType<
  typeof useSoftwareProductImageBlockSuspenseQuery
>;
export type SoftwareProductImageBlockQueryResult = Apollo.QueryResult<
  SoftwareProductImageBlockQuery,
  SoftwareProductImageBlockQueryVariables
>;
export const SoftwarePartnersBlogSectionDocument = gql`
  query SoftwarePartnersBlogSection {
    software {
      data {
        attributes {
          partnersBlogSection {
            ...partnersBlogSection
          }
        }
      }
    }
  }
  ${PartnersBlogSectionFragmentDoc}
`;

/**
 * __useSoftwarePartnersBlogSectionQuery__
 *
 * To run a query within a React component, call `useSoftwarePartnersBlogSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoftwarePartnersBlogSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoftwarePartnersBlogSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSoftwarePartnersBlogSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SoftwarePartnersBlogSectionQuery,
    SoftwarePartnersBlogSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SoftwarePartnersBlogSectionQuery,
    SoftwarePartnersBlogSectionQueryVariables
  >(SoftwarePartnersBlogSectionDocument, options);
}
export function useSoftwarePartnersBlogSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SoftwarePartnersBlogSectionQuery,
    SoftwarePartnersBlogSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SoftwarePartnersBlogSectionQuery,
    SoftwarePartnersBlogSectionQueryVariables
  >(SoftwarePartnersBlogSectionDocument, options);
}
export function useSoftwarePartnersBlogSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SoftwarePartnersBlogSectionQuery,
        SoftwarePartnersBlogSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SoftwarePartnersBlogSectionQuery,
    SoftwarePartnersBlogSectionQueryVariables
  >(SoftwarePartnersBlogSectionDocument, options);
}
export type SoftwarePartnersBlogSectionQueryHookResult = ReturnType<
  typeof useSoftwarePartnersBlogSectionQuery
>;
export type SoftwarePartnersBlogSectionLazyQueryHookResult = ReturnType<
  typeof useSoftwarePartnersBlogSectionLazyQuery
>;
export type SoftwarePartnersBlogSectionSuspenseQueryHookResult = ReturnType<
  typeof useSoftwarePartnersBlogSectionSuspenseQuery
>;
export type SoftwarePartnersBlogSectionQueryResult = Apollo.QueryResult<
  SoftwarePartnersBlogSectionQuery,
  SoftwarePartnersBlogSectionQueryVariables
>;
export const SoftwareSeoDocument = gql`
  query SoftwareSeo {
    software {
      data {
        id
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useSoftwareSeoQuery__
 *
 * To run a query within a React component, call `useSoftwareSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoftwareSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoftwareSeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useSoftwareSeoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SoftwareSeoQuery,
    SoftwareSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SoftwareSeoQuery, SoftwareSeoQueryVariables>(
    SoftwareSeoDocument,
    options,
  );
}
export function useSoftwareSeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SoftwareSeoQuery,
    SoftwareSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SoftwareSeoQuery, SoftwareSeoQueryVariables>(
    SoftwareSeoDocument,
    options,
  );
}
export function useSoftwareSeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SoftwareSeoQuery,
        SoftwareSeoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SoftwareSeoQuery, SoftwareSeoQueryVariables>(
    SoftwareSeoDocument,
    options,
  );
}
export type SoftwareSeoQueryHookResult = ReturnType<typeof useSoftwareSeoQuery>;
export type SoftwareSeoLazyQueryHookResult = ReturnType<
  typeof useSoftwareSeoLazyQuery
>;
export type SoftwareSeoSuspenseQueryHookResult = ReturnType<
  typeof useSoftwareSeoSuspenseQuery
>;
export type SoftwareSeoQueryResult = Apollo.QueryResult<
  SoftwareSeoQuery,
  SoftwareSeoQueryVariables
>;
